/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { getLoginUser } from './loginService';
import { isCallcenterLoggedIn, getCallcenterUser } from './callcenterService';

const baseUrl = `${process.env.BACKEND_API_BASE_URL}/accounts.php`;

const getToken = () => (isCallcenterLoggedIn() ? getCallcenterUser() : getLoginUser()).token;

export const accountsService = {
  get_accounts_list: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'get_accounts_list',
        token: getToken(),
        ...data, // { dbname }
      },
    }),
  admin_set_account_fare_params: async (id, fare_params) =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_set_account_fare_params',
        id,
        fare_params,
        token: getLoginUser().token,
      },
    }),
  admin_get_accounts_table: async (filter, order) =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_get_accounts_table',
        ...filter,
        order,
        token: getLoginUser().token,
      },
    }),
  admin_get_account_detail: async id =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_get_account_detail',
        id,
        token: getLoginUser().token,
      },
    }),
  admin_get_account_passengers: async (id, order, page, page_size) =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_get_account_passengers',
        id,
        order,
        page,
        page_size,
        token: getLoginUser().token,
      },
    }),
  admin_get_account_invoices: async (id, order, page, page_size) =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_get_account_invoices',
        id,
        order,
        page,
        page_size,
        token: getLoginUser().token,
      },
    }),
  admin_get_account_invoice_detail: async id =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_get_account_invoice_detail',
        invoice_id: id,
        token: getLoginUser().token,
      },
    }),
  admin_get_account_jobs: async (data) =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_get_account_jobs',
        ...data,
        token: getLoginUser().token,
      },
    }),
  admin_get_balance_detail: async id =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_get_balance_detail',
        id,
        token: getLoginUser().token,
      },
    }),
  admin_get_transaction_history: async (id, order, page, page_size) =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_get_transaction_history',
        id,
        order,
        page,
        page_size,
        token: getLoginUser().token,
      },
    }),
  admin_edit_transaction_history: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_edit_transaction_history',
        ...data,
        token: getLoginUser().token,
      },
    }),
  admin_del_transaction_history: async (id, account_payment_id) =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_del_transaction_history',
        id,
        account_payment_id,
        token: getLoginUser().token,
      },
    }),
  admin_edit: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_edit',
        ...data,
        token: getLoginUser().token,
      },
    }),
  admin_del: async id =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_del',
        id,
        token: getLoginUser().token,
      },
    }),
  admin_add_funds: async values =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_add_funds',
        ...values,
        token: getLoginUser().token,
      },
    }),
  admin_refund_funds: async id =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_refund_funds',
        account_payment_id: id,
        token: getLoginUser().token,
      },
    }),
  admin_transaction_receipt: async (id, payment_id) =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_transaction_receipt',
        account_id: id,
        payment_id,
        token: getLoginUser().token,
      },
    }),
  admin_pay_invoice_by_balance: async values =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_pay_invoice_by_balance',
        ...values,
        token: getLoginUser().token,
      },
    }),
  admin_close_invoice: async (id, invoice_id) =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_close_invoice',
        id,
        invoice_id,
        token: getLoginUser().token,
      },
    }),
  admin_send_invoice: async (account_id, invoice_id) =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_send_invoice',
        account_id,
        invoice_id,
        token: getLoginUser().token,
      },
    }),
  admin_export_jobs_table: async id =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_export_jobs_table',
        id,
        token: getLoginUser().token,
      },
      responseType: 'blob',
    }),
  admin_export_audit_report: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_export_audit_report',
        ...data,
        token: getLoginUser().token,
      },
    }),
};
