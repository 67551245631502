import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import {
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stepper,
  Step,
  StepLabel,
} from '@material-ui/core';

import scrollTo from 'gatsby-plugin-smoothscroll';
import 'date-fns';

import PhoneTwoToneIcon from '@material-ui/icons/PhoneTwoTone';
import PaymentTwoToneIcon from '@material-ui/icons/PaymentTwoTone';
import CloseIcon from '@material-ui/icons/Close';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { MapContext } from 'context/mapContext/mapContextContainer';
import { JobContext } from 'context/jobContextContainer';
import { CallContext } from 'context/callContextContainer';
import { CircularProgressContext } from 'components/CircularProgressOverlay';

import { cleanPhone } from 'utils/text';
import { jobsService } from 'services/jobsService';
import { passengersService } from 'services/passengersService';
import { fareService } from 'services/fareService';
import { PergoTitle } from '../PergoTitle';

import Step0 from './step0';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import AddPaymentModal from './AddPaymentModal';
import PayNowModal from './PayNowModal';

const moment = require('moment-timezone');

const JobSection = ({ mapType, jobTableRefresh, isCallCenter }) => {
  const [activeStep, setActiveStep] = useState(-1);
  const [isDispatch, setIsDispatch] = useState(false);
  const [jobId, setJobId] = useState(0);
  const [addPaymentOpen, setAddPaymentOpen] = useState(null);
  const [payNowOpen, setPayNowOpen] = useState(null);
  const [refreshPayments, setRefreshPayments] = useState(false);
  const [finishMsg, setFinishMsg] = useState('');
  const { showCircularProgress, hideCircularProgress } = useContext(CircularProgressContext);
  const { mapData, setMapData } = useContext(MapContext);
  const { editJobId, setEditJobId, setOutgoingCall, exitJobEditing } = useContext(JobContext);
  const { callcenterActiveDb } = useContext(CallContext);

  const initValues = {
    job_id: 0,
    phone: '',
    first_name: '',
    last_name: '',
    email: '',
    passenger_id: 0,
    pickup_place_name: '',
    pickup_address: '',
    pickup_suite: '',
    pickup_city: '',
    pickup_state: '',
    pickup_zip: '',
    pickup_lat: 0,
    pickup_lng: 0,
    pickup_zone: false,
    dropoff_place_name: '',
    dropoff_address: '',
    dropoff_suite: '',
    dropoff_city: '',
    dropoff_state: '',
    dropoff_zip: '',
    dropoff_lat: 0,
    dropoff_lng: 0,
    dropoff_zone: false,
    pickup_flight_information: null,
    dropoff_flight_information: null,
    passenger_count: 1,
    bags: 0,
    distance: 0,
    duration: 0,
    vehicle_type: -1,
    fare_calc_type: 0,
    fare_duration: 30,
    fare: 0,
    tip: 0,
    tip_percent: 0,
    tolls: 0,
    instruction: '',
    send_text_update: true,
    tax_included: 1,
    is_recurring: false,
    pickup_date_time: null,
    reserve_ahead: 30,
    is_return: false,
    is_return_willcall: false,
    return_date_time: null,
    driver_id: -1,
    vehicle_id: -1,
    account_id: -1,
    prior_auth_number: '',
    tender_type: 0,
    pickup_time: null,
    reserve_ahead_1: 30,
    start_date: null,
    end_date: null,
    is_weekly: false,
    week_days: '',
    finished: -1,
    payments: [],
  };
  const [values, setValues] = useState(initValues);
  const [showFinishDialog, setShowFinishDialog] = useState(false);
  const [forceInit, setForceInit] = useState(false);

  let slider;

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
  };

  const onClickPrev = () => {
    if ((values.job_id > 0 && activeStep > 0) || (values.job_id == 0 && activeStep >= 0)) {
      let newStep = activeStep - 1;
      if (activeStep > 1 && !isDispatch) {
        setIsDispatch(false);
        newStep--;
      }
      setActiveStep(newStep);
    }
  };

  const onClickNext = async (stepValues = null, dispatch = false) => {
    if (activeStep <= 3) {
      if (activeStep <= 1) {
        let newStep = activeStep + 1;
        if (activeStep === 1 && !dispatch) {
          newStep++;
        }

        if (stepValues.step_changed || activeStep === -1) {
          showCircularProgress('Saving');
          if (activeStep === 0) {
            stepValues = { ...stepValues, passenger_id: values.passenger_id, ...mapData };

            const fareData = await fareService.calc_fare({
              distance: stepValues.distance,
              deadhead_distance: stepValues.deadhead_distance,
              passenger_count: 1,
              vehicle_type: -1,
              baggage_count: 0,
              trip_duration: 0,
              ...(stepValues.pickup_zone && { pickup_zone: stepValues.pickup_zone }),
              ...(stepValues.dropoff_zone && { dropoff_zone: stepValues.dropoff_zone }),
              ...(isCallCenter && { dbname: callcenterActiveDb }),
            });

            if (fareData && fareData.data.status === 'SUCCESS' && fareData.data.data.num_rows >= 1) {
              stepValues = { ...stepValues, fare: fareData.data.data.data.fare * 100 };
            }
          }

          const data = await jobsService.save_job_step({
            job_id: jobId,
            step: activeStep + 1,
            job_info: stepValues,
            ...(isCallCenter && { dbname: callcenterActiveDb }),
          });
          if (data.data?.status === 'SUCCESS') {
            if (activeStep === -1) {
              if (jobId == 0) {
                setJobId(parseInt(data.data.data.job_id));
                stepValues = { ...stepValues, job_id: data.data.data.job_id };
              }
            }
          }
          hideCircularProgress();
        }
        if(values?.instruction?.length>0 && !values?.instruction?.includes(`: ${values.phone}`)){
          values.instruction=values.instruction+": "+ values.phone;
        }

        setValues({ ...values, ...stepValues });
        setIsDispatch(dispatch);
        setActiveStep(newStep);
      } else if (activeStep === 2) {
        setValues({ ...values, ...stepValues, finished: 1 });
      } else if (activeStep === 3) {
        setValues({ ...values, ...stepValues, finished: 0 });
      }
    }
  };

  useEffect(() => {
    async function saveJob() {
      showCircularProgress('Saving');
      const data = await jobsService.save_job({
        job_id: jobId,
        is_dispatch: values.finished === 1,
        job_info: values,
        ...(isCallCenter && { dbname: callcenterActiveDb }),
      });

      if (data && data?.data?.status === 'SUCCESS') {
        if (data.data.data.num_rows >= 1) {
          const jobIds = data.data.data.data.job_id;
          const bookingDepositStatus = data.data.data.data.booking_deposit_status;
          const successBookingDepositIds = Object.keys(bookingDepositStatus).filter(
            key => bookingDepositStatus[key].status === 'SUCCESS' && bookingDepositStatus[key].data.code !== 'NONE_BOOKING_DEPOSIT'
          );
          const failedBookingDepositIds = Object.keys(bookingDepositStatus).filter(
            key => bookingDepositStatus[key].status === 'FAIL'
          );
          setFinishMsg(
            <>
              <p>
                {`${jobIds.length} job${jobIds.length > 1 ? 's' : ''}(#${jobIds.join(', #')}${
                  jobIds.length === 1 ? ') is' : ') are'
                } successfully saved.`}
              </p>
              {successBookingDepositIds.length > 0 && (
                <p>
                  {`${successBookingDepositIds.length} job${
                    successBookingDepositIds.length > 1 ? 's' : ''
                  }(#${successBookingDepositIds.join(', #')}${
                    successBookingDepositIds.length === 1 ? ') has' : ') have'
                  } successfully charged the booking deposit.`}
                </p>
              )}
              {failedBookingDepositIds.length > 0 && (
                <p>
                  {`${failedBookingDepositIds.length} job${
                    failedBookingDepositIds.length > 1 ? 's' : ''
                  }(#${failedBookingDepositIds.join(', #')}) failed to charge the booking deposit.`}
                </p>
              )}
            </>
          );
          setShowFinishDialog(true);
        }
      }
      hideCircularProgress();
    }

    if (values.finished === 0 || values.finished === 1) {
      saveJob();
    }
  }, [values.finished]);

  useEffect(() => {
    slider.slickGoTo(activeStep + 1);
  }, [activeStep]);

  useEffect(() => {
    async function readJobPayments() {
      showCircularProgress('Loading');
      const data = await jobsService.get_job_payments({
        job_id: values.job_id,
        ...(isCallCenter && { dbname: callcenterActiveDb }),
      });
      hideCircularProgress();
      if (data && data?.data?.status === 'SUCCESS') {
        setValues({
          ...values,
          payments: data?.data?.data,
        });
      }
    }

    if (values.job_id > 0) {
      readJobPayments();
    }
  }, [refreshPayments]);

  useEffect(() => {
    if (editJobId > 0 && jobId !== editJobId) {
      showCircularProgress('Loading');
      jobsService
        .get_job({
          job_id: editJobId,
          ...(isCallCenter && { dbname: callcenterActiveDb }),
        })
        .then(({ data }) => {
          if (data.status === 'SUCCESS') {
            const getValues = data.data.data;

            const tmpValues = { ...initValues };
            tmpValues.job_id = getValues.id;
            tmpValues.passenger_id = getValues.passenger_id;
            tmpValues.first_name = getValues.passenger.fname || '';
            tmpValues.last_name = getValues.passenger.lname || '';
            tmpValues.email = getValues.passenger.email || '';
            tmpValues.phone = getValues.passenger.phone || '';
            tmpValues.card_number = getValues.passenger.token?.card_number || null;
            tmpValues.pickup_place_name = (getValues.pickup && getValues.pickup.location_name) || '';
            tmpValues.pickup_address = (getValues.pickup && getValues.pickup.addr1) || '';
            tmpValues.pickup_city = (getValues.pickup && getValues.pickup.city) || '';
            tmpValues.pickup_state = (getValues.pickup && getValues.pickup.state) || '';
            tmpValues.pickup_zip = (getValues.pickup && getValues.pickup.zip) || '';
            tmpValues.pickup_lat = (getValues.pickup && parseFloat(getValues.start_lat / 1000000)) || 0;
            tmpValues.pickup_lng = (getValues.pickup && parseFloat(getValues.start_lon / 1000000)) || 0;
            tmpValues.dropoff_place_name = (getValues.dropoff && getValues.dropoff.location_name) || '';
            tmpValues.dropoff_address = (getValues.dropoff && getValues.dropoff.addr1) || '';
            tmpValues.dropoff_city = (getValues.dropoff && getValues.dropoff.city) || '';
            tmpValues.dropoff_state = (getValues.dropoff && getValues.dropoff.state) || '';
            tmpValues.dropoff_zip = (getValues.dropoff && getValues.dropoff.zip) || '';
            tmpValues.dropoff_lat = (getValues.dropoff && parseFloat(getValues.end_lat / 1000000)) || 0;
            tmpValues.dropoff_lng = (getValues.dropoff && parseFloat(getValues.end_lon / 1000000)) || 0;
            tmpValues.pickup_zone = false;
            tmpValues.dropoff_zone = false;
            tmpValues.pickup_flight_information = getValues.pickup_flight_information?.replace(/\\"/g, '"');
            tmpValues.dropoff_flight_information = getValues.dropoff_flight_information?.replace(/\\"/g, '"');
            tmpValues.passenger_count = getValues.passenger_count;
            tmpValues.bags = getValues.bags;
            tmpValues.distance = getValues.distance;
            tmpValues.duration = getValues.duration;
            tmpValues.vehicle_type = getValues.vehicle_type;
            tmpValues.fare_calc_type = getValues.fare_calc_type;
            tmpValues.fare_duration = getValues.fare_duration;
            tmpValues.fare = getValues.fare * 100;
            tmpValues.tip = getValues.tip * 100;
            tmpValues.tolls = getValues.tolls * 100;
            tmpValues.booking_deposit_percent = getValues.booking_deposit_percent;
            tmpValues.instruction = getValues.comment;
            tmpValues.is_recurring = typeof getValues.recurring !== 'undefined';
            tmpValues.driver_id = getValues.driver_id;
            tmpValues.vehicle_id = getValues.vehicle_id;
            tmpValues.account_id = getValues.account_id;
            tmpValues.prior_auth_number = getValues.prior_auth_number || '';
            tmpValues.tender_type = getValues.tender_type;
            tmpValues.pickup_date_time = getValues.reservation_time;
            tmpValues.dispatch_time = getValues.dispatch_time;
            tmpValues.reservation_time = getValues.reservation_time;
            tmpValues.reserve_ahead_time = getValues.reserve_ahead_time;
            tmpValues.reserve_ahead = getValues.reserve_ahead;
            tmpValues.reservation_confirmed = getValues.reservation_confirmed;
            tmpValues.recurring_job_id = getValues.recurring_job_id;
            tmpValues.job_type = getValues.job_type;
            tmpValues.willcall_status = getValues.willcall_status;
            tmpValues.status = getValues.status;
            tmpValues.send_text_update = true;
            tmpValues.tax_included = getValues.tax_included;
            tmpValues.payments = getValues.payments;

            if (tmpValues.is_recurring) {
              tmpValues.pickup_date_time = getValues.reservation_time;
              tmpValues.pickup_time = tmpValues.pickup_date_time;
              tmpValues.reserve_ahead_1 = getValues.recurring.params.reserve_ahead;
              tmpValues.start_date = getValues.recurring.start_date;
              tmpValues.end_date = getValues.recurring.end_date;
              tmpValues.week_days = getValues.recurring.params.days;
              tmpValues.is_weekly = tmpValues.week_days.length > 0;
            } else {
              tmpValues.reserve_ahead_1 = 30;
            }
            setValues(tmpValues);
            setJobId(getValues.id);
            setActiveStep(0);
            setForceInit(!forceInit);
            scrollTo('#main-wrapper');
          }
        })
        .finally(() => hideCircularProgress());
    }
  }, [editJobId]);

  const closeFinishDialog = () => {
    setShowFinishDialog(false);
    setValues(initValues);
    setJobId(0);
    setEditJobId(0);
    setMapData({
      ...mapData,
      pickup_lat: 0,
      pickup_lng: 0,
      dropoff_lat: 0,
      dropoff_lng: 0,
      driver_lat: 0,
      driver_lng: 0,
      distance: 0,
      duration: 0,
      deadhead_distance: 0,
    });
    setActiveStep(-1);
  };

  const handleResetJob = () => {
    if (jobId !== 0) {
      setValues(initValues);
      setJobId(0);
      setEditJobId(0);
      setMapData({
        ...mapData,
        pickup_lat: 0,
        pickup_lng: 0,
        dropoff_lat: 0,
        dropoff_lng: 0,
        driver_lat: 0,
        driver_lng: 0,
        distance: 0,
        duration: 0,
        deadhead_distance: 0,
      });
      setActiveStep(-1);
    }
  };

  useEffect(() => {
    handleResetJob();
  }, [exitJobEditing]);

  const handleRefreshPassengerDetail = () => {
    showCircularProgress('Searching...');
    passengersService
      .get_passenger({
        phone: cleanPhone(values.phone),
        ...(isCallCenter && { dbname: callcenterActiveDb }),
      })
      .then(({ data }) => {
        hideCircularProgress();
        if (data.status === 'SUCCESS' && data.data.num_rows == 1) {
          setValues({
            ...values,
            card_number: data.data.data.token?.card_number,
          });
        }
      });
  };

  const handleOutGoingCall = () => {
    if (values.phone) {
      setOutgoingCall({ name: `${values.first_name} ${values.last_name}`, phone: values.phone });
    }
  };

  return (
    <JobSectionWrapper className="main--job-section-wrapper">
      <Paper className="main--job-section" elevation={3} square>
        <div className="main--job-title">
          <div className="form-control-inline">
            <PergoTitle className="main--job-title-jobid" onClick={handleResetJob}>
              {jobId != 0 && <CloseIcon style={{ fontSize: '16px' }} />}Job{' '}
              {activeStep === -1 ? ' Start' : jobId == 0 ? ' #New' : ` #${jobId}`}
            </PergoTitle>
            <div className="main--job-title-info form-control-inline">
              <div>
                <PergoTitle
                  className="main--job-title-phone"
                  title="Call Passenger"
                  onClick={() => handleOutGoingCall()}
                >
                  {values.phone && <PhoneTwoToneIcon style={{ fontSize: 16, marginRight: 5 }} />}
                  {values.phone}
                </PergoTitle>
                <PergoTitle
                  className={`main--job-title-name${
                    values.first_name?.length + values.last_name?.length === 0 ? ' empty-value' : ''
                  }`}
                >
                  {values.first_name?.length + values.last_name?.length === 0 && activeStep >= 0
                    ? '(No Name)'
                    : `${values.first_name} ${values.last_name}`}
                </PergoTitle>
              </div>
              <div>
                <PergoTitle className={`main--job-title-email${values.email?.length === 0 ? ' empty-value' : ''}`}>
                  {values.email?.length === 0 && activeStep >= 0 ? '(No Email)' : values?.email}
                </PergoTitle>
                <PergoTitle
                  className="main--job-title-card"
                  title="Click here to update Payment"
                  onClick={() => setAddPaymentOpen(values.card_number ? 'Update' : 'Add')}
                >
                  {activeStep >= 0 ? (
                    <>
                      {values.card_number || 'No Card On File'}
                      <PaymentTwoToneIcon className="main--job-title-card-icon" style={{ fontSize: '15px' }} />
                    </>
                  ) : (
                    ''
                  )}
                </PergoTitle>
              </div>
            </div>
          </div>
        </div>

        <Stepper activeStep={activeStep > 2 ? 2 : activeStep}>
          <Step key={1}>
            <StepLabel />
          </Step>
          <Step key={2}>
            <StepLabel />
          </Step>
          <Step key={3}>
            <StepLabel />
          </Step>
        </Stepper>

        <Slider ref={c => (slider = c)} {...settings}>
          <Step0
            globalValues={values}
            setValues={setValues}
            onClickNext={onClickNext}
            isCurrentStep={activeStep === -1}
            isCallCenter={isCallCenter}
          />
          <Step1
            globalValues={values}
            onClickPrev={onClickPrev}
            onClickNext={onClickNext}
            mapType={mapType}
            forceInit={forceInit}
            isCurrentStep={activeStep === 0}
            isCallCenter={isCallCenter}
          />
          <Step2
            globalValues={values}
            onClickPrev={onClickPrev}
            onClickNext={onClickNext}
            onClickMiddle={(stepValues, dispatch) => onClickNext(stepValues, true)}
            showPayNow={setPayNowOpen}
            isCurrentStep={activeStep === 1}
            isCallCenter={isCallCenter}
          />
          <Step3
            globalValues={values}
            onClickPrev={onClickPrev}
            onClickNext={onClickNext}
            isCurrentStep={activeStep === 2}
            isCallCenter={isCallCenter}
          />
          <Step4
            globalValues={values}
            onClickPrev={onClickPrev}
            onClickNext={onClickNext}
            isCurrentStep={activeStep === 3}
            isCallCenter={isCallCenter}
          />
        </Slider>
      </Paper>

      <AddPaymentModal
        passengerDetail={{
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          phone: values.phone,
        }}
        addPaymentOpen={addPaymentOpen}
        handleAddPaymentClose={() => setAddPaymentOpen(null)}
        handleRefreshDetail={handleRefreshPassengerDetail}
      />

      <PayNowModal
        jobDetail={{ ...values, ...(payNowOpen ? { ...payNowOpen } : {}) }}
        payNowOpen={payNowOpen}
        handlePayNowClose={() => setPayNowOpen(null)}
        handleAddCard={setAddPaymentOpen}
        handleRefreshDetail={() => setRefreshPayments(!refreshPayments)}
      />

      <Dialog open={showFinishDialog} onClose={closeFinishDialog}>
        <DialogTitle id="alert-dialog-title">Pergo Job</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{finishMsg}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeFinishDialog} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </JobSectionWrapper>
  );
};

export default JobSection;

const JobSectionWrapper = styled.div`
  flex: 1;
  max-width: 500px;
  min-width: 445px;
  margin-right: 10px;

  .main--job-section {
    border: 2px solid var(--blue-main-color);
    background: var(--job-section-background);

    .main--job-title {
      background: var(--blue-main-color);
      padding: 6px 10px;
      overflow: hidden;
      white-space: nowrap;

      .main--job-title-jobid {
        font-size: 14px;
        align-self: center;
        margin-right: 10px;
        display: flex;
        cursor: pointer;
      }

      .main--job-title-phone,
      .main--job-title-name,
      .main--job-title-email,
      .main--job-title-card {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        min-height: 15px;

        &.empty-value {
          font-style: italic;
        }
      }

      .main--job-title-phone {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          svg {
            color: #00ff00;
          }
          color: #00ff00;
        }
      }

      .main--job-title-name,
      .main--job-title-card {
        margin-top: 5px;
      }

      .main--job-title-card {
        cursor: pointer;

        .main--job-title-card-icon {
          margin-left: 5px;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .MuiStepper-root {
      background: var(--job-section-background);
      padding: 15px 10px;

      .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
        margin-top: 5px;
      }

      .MuiStepIcon-root {
        &.MuiStepIcon-completed {
          color: #ff8424;
        }
        &.MuiStepIcon-active {
          color: #ff8424;
        }
      }
    }

    .main--job-body {
      height: 100%;
      position: relative;

      .main--job-step-title {
        color: var(--black-text-color);
        text-align: center;
      }

      .main--job-step-body {
        font-size: 14px;
        padding: 20px 15px 57px;

        .MuiFormControl-root {
          width: 100%;
          margin-bottom: 10px;
        }

        .MuiOutlinedInput-root {
          border-radius: 0;
        }

        .MuiOutlinedInput-adornedEnd {
          padding-right: 0;
        }

        .MuiInputAdornment-positionEnd {
          margin-left: 0;
        }

        .MuiOutlinedInput-multiline {
          padding: 0;
        }

        .pac-target-input,
        .MuiInputLabel-outlined {
          font-size: 14px;
          transform: translate(14px, 12px) scale(1);
          background: var(--job-section-background);
          padding: 0 3px;

          &.MuiInputLabel-shrink {
            transform: translate(14px, -5px) scale(0.75);
          }
        }

        .MuiOutlinedInput-input {
          padding: 10px;
          font-size: 14px;
          height: auto;
        }

        .MuiOutlinedInput-notchedOutline > legend {
          max-width: 0.01px;
        }

        .MuiCheckbox-root {
          padding: 0 5px 0 9px;
        }

        .MuiCheckbox-colorSecondary.Mui-checked {
          color: var(--blue-main-color);
        }

        .MuiRadio-colorSecondary.Mui-checked {
          color: var(--blue-main-color);
        }

        .MuiFormControlLabel-root .MuiTypography-body1 {
          font-size: 14px;
        }

        .MuiFormControlLabel-root {
          min-width: 70px;

          &.error {
            .MuiRadio-colorSecondary.Mui-checked {
              color: red;
            }
            .MuiFormControlLabel-label {
              color: red;
            }
          }
        }

        .MuiTabs-root {
        }

        .MuiTabs-fixed {
          background: var(--blue-main-color);
        }

        .MuiTabs-indicator {
          display: none;
        }

        .form-tab-body {
          border: 2px solid var(--blue-main-color);
          border-top: none;
          padding: 25px 15px 10px;
        }

        .MuiTab-root {
          color: white;
          min-width: 120px;
          border: 2px solid var(--blue-main-color);
          border-bottom: none;

          &.Mui-selected {
            color: var(--blue-main-color);
            background: var(--job-section-background);
          }
        }
      }
    }

    .main--job-step-footer {
      padding: 0 15px 20px;
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 0;
      width: 100%;

      .MuiButton-outlined {
        border-radius: 0;
        min-width: 130px;
      }

      .main--job-step-btn-next {
        background: var(--blue-main-color);
        color: white;

        &:hover {
          background-color: var(--blue-main-hover-color);
        }
      }

      .main--job-step-btn-next-other {
        background: var(--green-main-color);
        color: white;

        &:hover {
          background-color: var(--green-main-hover-color);
        }
      }
    }
  }

  .job-section--location {
    .main--job-dropon,
    .main--job-dropoff {
      flex: 1;
      padding: 0;
    }
  }

  .form-control-section {
    margin: 10px 0;
    .google-places-autocomplete {
      .google-places-autocomplete__suggestions-container {
        margin-top: -10px;
        background: var(--job-section-background);
        width: calc(100% - 2px);
        border: 1px solid var(--blue-main-color);
        cursor: pointer;
        .google-places-autocomplete__suggestion {
          font-size: 14px;
          font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
          border-bottom: 1px solid var(--blue-main-color);
        }
      }
    }
    .mapbox_autocomplete {
      position: relative;
      input {
        padding: 10px;
        font-size: 14px;
        height: auto;
        background: var(--job-section-background);
        margin-bottom: 10px;
        width: 100%;
        border: 1px solid lightgray;
        max-width: -webkit-fill-available;
      }
      input:focus {
        outline: 2px solid #3f51b5;
        box-sizing: inherit;
      }
      .react-geocoder-results {
        border: 1px solid var(--blue-main-color);
        margin-top: -10px;
        position: absolute;
        z-index: 3;
        background: var(--job-section-background);
        width: 100%;
        max-width: -webkit-fill-available;
        cursor: pointer;
        .react-geocoder-item {
          padding: 10px;
          font-size: 14px;
          font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
          border-bottom: 1px solid var(--blue-main-color);
          background: var(--job-section-background);
          width: 100%;
          max-width: -webkit-fill-available;
        }
      }
    }
  }

  .form-control-inline {
    display: flex;

    & > div {
      padding-left: 5px;
      padding-right: 5px;
      flex: 1;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    & > button {
      margin-left: 5px;
      margin-right: 5px;
      flex: 1;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .slick-track {
    display: flex;

    .slick-slide {
      flex: 1;
      float: none;
      height: auto;

      & > div {
        height: 100%;
      }
    }
  }

  @media (max-width: 830px) {
    max-width: 100%;
    min-width: 0;
    margin-right: 0;
    margin-bottom: 10px;
  }

  @media (max-width: 485px) {
    .main--job-section .main--job-body .main--job-step-body {
      padding-bottom: 103px;
    }

    .main--job-step-footer {
      flex-direction: column-reverse;

      .form-control-inline {
        margin-bottom: 10px;
      }
    }
  }
`;
