/* eslint-disable guard-for-in */
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { Button } from '@material-ui/core';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';

import { cleanPhone } from 'utils/text';

import ConfirmModal from 'components/ConfirmModal';
import { PergoTextField } from 'components/PergoTextField/PergoTextField';
import { CircularProgressContext } from 'components/CircularProgressOverlay';
import { JobContext } from 'context/jobContextContainer';
import { CallContext } from 'context/callContextContainer';
import { passengersService } from 'services/passengersService';
import JobSectionContent from './JobSectionContent';
import PassengersEditModal from './EditModal';

const awsConfig = {
  Auth: {
    region: process.env.AWS_CONFIG_REGION,
    userPoolId: process.env.AWS_CONFIG_USER_POOL_ID,
    userPoolWebClientId: process.env.AWS_CONFIG_USER_POOL_WEBCLIENT_ID,
    identityPoolId: process.env.AWS_CONFIG_IDENTITY_POOL_ID,
  },
};

Amplify.configure(awsConfig);

const Step0 = ({ globalValues, onClickNext, onClickPrev, isCurrentStep, isCallCenter }) => {
  const [isUpdate, setUpdate] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [isExistPassenger, setExistPassenger] = useState(false);
  const [getDetail, setGetDetail] = useState(false);
  const [values, setValues] = useState({});
  const [showBlockedConfirm, setShowBlockedConfirm] = useState(false);
  const { showCircularProgress, hideCircularProgress } = useContext(CircularProgressContext);
  const { incomingNumber } = useContext(JobContext);
  const { callcenterActiveDb } = useContext(CallContext);

  const nameType = {
    phone: 'Phone Number',
    first_name: 'First Name',
    last_name: 'Last Name',
    email: 'Email',
  };

  const [formValid, setFormValid] = useState({
    phone: 0,
    first_name: 0,
    last_name: 0,
    email: 0,
  });

  const [formErrors, setFormErrors] = useState({
    phone: '',
    first_name: '',
    last_name: '',
    email: '',
  });

  const onHandleChange = (id, newValue) => {
    setValues({
      ...values,
      [id]: newValue
    });
  };

  useEffect(() => {
    if (isCurrentStep) {
      setValues({
        phone: globalValues.phone,
        mobile: globalValues.mobile,
        first_name: globalValues.first_name,
        last_name: globalValues.last_name,
        email: globalValues.email,
        passenger_id: globalValues.passenger_id,
      });
      setExistPassenger(globalValues.passenger_id > 0);
    }
  }, [isCurrentStep]);

  useEffect(() => {
    const newValue = cleanPhone(values.phone);
    if (newValue?.length === 10) {
      showCircularProgress('Searching...');
      passengersService.get_passenger({
        phone: newValue,
        ...(isCallCenter && { dbname: callcenterActiveDb }),
      }).then(({ data }) => {
        hideCircularProgress();
        if (data.status === 'SUCCESS' && data.data.num_rows == 1) {
          const info = data.data.data;
          const updatedValue = {
            ...values,
            passenger_id: info.id,
            first_name: info.fname,
            last_name: info.lname,
            email: info.email,
            mobile: info.mobile,
            card_number: info.token?.card_number,
          };

          if (info.status == 1) {
            setValues(updatedValue);
            setExistPassenger(true);
          } else {
            setShowBlockedConfirm(updatedValue);
          }
        } else {
          setValues({
            ...values,
            passenger_id: 0
          });
          setExistPassenger(false);
        }
      });
    } else {
      if (isExistPassenger) {
        setValues({
          ...values,
          passenger_id: 0,
          first_name: '',
          last_name: '',
          email: '',
        });
      }
      setExistPassenger(false);
    }
  }, [values.phone, getDetail]);

  useEffect(() => {
    if (incomingNumber) {
      setValues({ ...values, phone: incomingNumber });
    }
  }, [incomingNumber]);

  const checkFieldValidate = (id, value) => {
    const fieldValidation = formValid;
    const fieldValidationErrors = formErrors;

    fieldValidation[id] = 1;
    if (id === 'phone') {
      value = cleanPhone(value);
      fieldValidation[id] = value.length === 10 ? 1 : -1;
      fieldValidationErrors[id] = fieldValidation[id] === 1 ? '' : `${nameType[id]  } is not valid`;
    } else if (id === 'email') {
      fieldValidation[id] =
        !value || value === '' ? 1 : (value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? 1 : -1);
      fieldValidationErrors[id] = fieldValidation[id] === 1 ? '' : `${nameType[id]  } is invalid`;
    } else {
      fieldValidation[id] = value !== '' ? 1 : -1;
      fieldValidationErrors[id] = fieldValidation[id] === 1 ? '' : `${nameType[id]  } is required`;
    }

    setFormValid(fieldValidation);
    setFormErrors(fieldValidationErrors);
    setUpdate(!isUpdate);

    return fieldValidation[id];
  };

  const checkStepValidate = () => {
    let stepValid = 0;
    const stepFields = ['phone', 'email'];

    stepFields.forEach(element => {
      stepValid += checkFieldValidate(element, values[element]);
    });

    return stepValid === stepFields.length;
  };

  const awsSignup = async infos => {
    const phoneNumber = infos.phone.replace(/\D/g, '');
    try {  
      const user = await Auth.signUp({
        username: `+1${phoneNumber}`,
        password: Date.now().toString(),
        attributes: {
          phone_number: `+1${phoneNumber}`,
          email: infos.email,
          given_name: infos.first_name,
          family_name: infos.last_name,
          'custom:pergo_confirmed': '0',
        },
      });
      console.log('AWS SIGNUP SUCCESS : ', user);
    } catch (err) {
      console.log('AWS SIGNUP ERROR : ', err);
    }
  };

  const onNextStep = async () => {
    if (checkStepValidate()) {
      if (!isExistPassenger) {
        showCircularProgress('Creating New Passenger...');
        const data = await passengersService.create_passenger({
          ...values,
          phone: cleanPhone(values.phone),
          ...(isCallCenter && { dbname: callcenterActiveDb }),
        });
        
        if (data && data?.data?.status === 'SUCCESS') {
          setExistPassenger(true);
          // await awsSignup({
          //   ...values,
          //   phone: cleanPhone(values.phone)
          // });
          onClickNext({
            ...values,
            passenger_id: data.data.data.id,
            card_number: null
          });
        };
        hideCircularProgress();
      } else {
        onClickNext(values);
      }
    }
  };

  const handleEnableBlockPassenger = () => {
    if (showBlockedConfirm) {
      showCircularProgress('Please wait...');
      passengersService.enable_passenger({
        phone: cleanPhone(showBlockedConfirm.phone),
        ...(isCallCenter && { dbname: callcenterActiveDb }),
      }).then(({ data }) => {
        hideCircularProgress();
        if (data.status === 'SUCCESS') {
          setValues(showBlockedConfirm);
          setExistPassenger(true);
        }
        setShowBlockedConfirm(false);
      });
    }
  }

  const handleCancelBlockPassenger = () => {
    setValues({
      ...values,
      phone: '',
    });
    setShowBlockedConfirm(false);
  }

  return (
    <StepContent
      title="Start Job"
      className="job-section--start"
      nextButton={{
        type: 'next',
        onClick: onNextStep,
        label: 'START JOB',
      }}
      isCallCenter={isCallCenter}
    >
      <PergoTextField
        id="phone"
        label="Phone Number"
        values={values}
        handleChange={onHandleChange}
        formValid={formValid}
        formErrors={formErrors}
        type="number"
        format="(###) ###-####"
        disabled={isCallCenter && !callcenterActiveDb}
      />
      <div className="form-control-inline">
        <PergoTextField
          id="first_name"
          label="First Name"
          values={values}
          handleChange={onHandleChange}
          formValid={formValid}
          formErrors={formErrors}
          readonly={isExistPassenger}
        />
        <PergoTextField
          id="last_name"
          label="Last Name"
          values={values}
          handleChange={onHandleChange}
          formValid={formValid}
          formErrors={formErrors}
          readonly={isExistPassenger}
        />
      </div>
      <PergoTextField
        id="email"
        label="Email"
        values={values}
        handleChange={onHandleChange}
        formValid={formValid}
        formErrors={formErrors}
        readonly={isExistPassenger}
      />
      {isExistPassenger && (
        <Button color="primary" variant="contained" style={{ width: '100%' }} onClick={() => setEditOpen(true)}>
          <span className="btn-wrapper--icon">
            <EditTwoToneIcon fontSize="small" />
          </span>
          <span className="btn-wrapper--label">Edit</span>
        </Button>
      )}

      <PassengersEditModal 
        passengerDetail={values}
        updatePassengerDetail={() => setGetDetail(!getDetail)}
        editOpen={editOpen}
        handleEditClose={() => setEditOpen(false)}
      />

      <ConfirmModal
        title="Blocked Passenger"
        body="This Passenger is blocked. Do you want to unblock this passenger and continue to create ride?"
        open={!!showBlockedConfirm}
        handleYes={() => handleEnableBlockPassenger()}
        handleNo={() => handleCancelBlockPassenger()}
        yesLabel="Enable Passenger and Continue"
        noLabel="Cancel"
      />
    </StepContent>
  );
};

export default Step0;

const StepContent = styled(JobSectionContent)``;
