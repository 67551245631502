import React, { useState } from 'react';

const MapContext = React.createContext(null);
export { MapContext };

const MapContextContainer = props => {
  const initValues = {
    zoom: 16,
    pickup_lat: 0,
    pickup_lng: 0,
    dropoff_lat: 0,
    dropoff_lng: 0,
    driver_lat: 0,
    driver_lng: 0,
    distance: 0,
    duration: 0,
  };
  const [mapData, setMapData] = useState(initValues);

  return (
    <MapContext.Provider
      value={{
        mapData,
        setMapData,
      }}
    >
      {props.children}
    </MapContext.Provider>
  );
};

export default MapContextContainer;
