import React, { useState, useEffect, useContext } from 'react';
import { navigate } from 'gatsby';
import {
  Button,
  Dialog,
} from '@material-ui/core';

import SaveTwoToneIcon from '@material-ui/icons/SaveTwoTone';
import ReplayTwoToneIcon from '@material-ui/icons/ReplayTwoTone';

import { MyCardRow, MyCardSub } from 'components/Elements/MyCard';
import MyInput from 'components/Elements/MyInput';

import { CircularProgressContext } from 'components/CircularProgressOverlay';
import { NotificationContext } from 'components/NotificationOverlay';

import Config from 'config/index';
import { notNull, cleanPhone } from 'utils/text';
import { logout } from 'services/loginService';
import { passengersService } from 'services/passengersService';
import { DialogTitleContainer, DialogContentContainer, DialogActionsContainer } from './style';

const EditModal = ({
  passengerDetail,
  updatePassengerDetail,
  editOpen,
  handleEditClose,
}) => {
  const [values, setValues] = useState({
    phone: passengerDetail.phone,
    mobile: passengerDetail.mobile,
    first_name: passengerDetail.first_name,
    last_name: passengerDetail.last_name,
    email: passengerDetail.email,
    passenger_id: passengerDetail.passenger_id,
  });
  const [fieldErrors, setFieldErrors] = useState(null);
  const { showCircularProgress, hideCircularProgress } = useContext(CircularProgressContext);
  const { showNotification } = useContext(NotificationContext);

  useEffect(() => {
    setValues({
      ...passengerDetail
    });
  }, [passengerDetail]);

  const checkFieldValidate = (id, value) => {
    let valid = notNull(value) && (value.length > 0 || value >= 0);
    let error = null;

    if (!valid) {
      error = 'Please input value';
    } else if (id === 'phone' || id === 'mobile') {
      valid = cleanPhone(value).length === 10;
      error = valid ? null : 'Please input valid number';
    }

    return error;
  };

  const checkDetailValidate = () => {
    const keys = ['phone'];
    const errors = {};
    keys.forEach(item => {
      const error = checkFieldValidate(item, notNull(passengerDetail) ? passengerDetail[item] : null);
      errors[item] = error;
    })
    setFieldErrors(errors);

    const stepValid = Object.keys(errors).filter(item => errors[item] !== null).length;
    return stepValid === 0;
  };

  const handleDetailChange = (id, newValue) => {
    setValues({
      ...values,
      [id]: newValue
    });
  }

  const handleSave = () => {
    if (!checkDetailValidate()) {
      return;
    }

    showCircularProgress('Saving...');
    passengersService.admin_edit({
      id: values.passenger_id,
      fname: values.first_name,
      lname: values.last_name,
      email: values.email,
      phone: cleanPhone(values.phone),
      mobile: cleanPhone(values.mobile),
    }).then(({ data }) => {
      hideCircularProgress();
      if (data.status === 'SUCCESS') {
        updatePassengerDetail(values);
        handleEditClose();
      } else {
        showNotification('Sorry, Failed to save', 'error');
        if (data.data.code === 'NOT_LOGGED_IN') {
          logout();
          navigate('/login');
        }
      }
    });
  }

  return (
    <Dialog
      classes={{ paper: 'modal-content' }}
      fullWidth
      maxWidth="sm"
      open={editOpen}
      onClose={handleEditClose}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitleContainer className="bg-secondary">
        <span>
          Edit Passenger
        </span>
      </DialogTitleContainer>
      <DialogContentContainer className="p-0" dividers>
        <div className="dialog-content--wrapper bg-secondary border-0">
          <div className="card-body px-lg-4 py-lg-3">
            <div className="card-body--column w-100">
              <MyCardSub subTitle="Personal Information" noBorder>
                <MyCardRow>
                  <MyInput
                    label="First Name"
                    id="first_name"
                    values={values}
                    onChange={handleDetailChange}
                    error={notNull(fieldErrors?.first_name)}
                    helperText={fieldErrors?.first_name}
                  />
                  <MyInput
                    label="Last Name"
                    id="last_name"
                    values={values}
                    onChange={handleDetailChange}
                    error={notNull(fieldErrors?.last_name)}
                    helperText={fieldErrors?.last_name}
                  />
                </MyCardRow>
                <MyCardRow>
                  <MyInput
                    label="Mobile Phone *"
                    id="phone"
                    values={values}
                    maskProps={{ mask: Config.PHONE_MASK }}
                    disabled
                  />
                  <MyInput
                    label="Home Phone"
                    id="mobile"
                    values={values}
                    onChange={handleDetailChange}
                    error={notNull(fieldErrors?.mobile)}
                    helperText={fieldErrors?.mobile}
                    maskProps={{ mask: Config.PHONE_MASK }}
                  />
                </MyCardRow>
                <MyCardRow>
                  <MyInput
                    label="Email"
                    id="email"
                    values={values}
                    onChange={handleDetailChange}
                    error={notNull(fieldErrors?.email)}
                    helperText={fieldErrors?.email}
                  />
                </MyCardRow>
              </MyCardSub>
            </div>
          </div>
        </div>
      </DialogContentContainer>
      <DialogActionsContainer className="bg-secondary p-3">
        <Button
          size="small"
          className="btn-primary mxr-4 px-4 py-2 btn-transition-none"
          onClick={handleSave}
        >
          <span className="btn-wrapper--icon">
            <SaveTwoToneIcon fontSize="small" />
          </span>
          <span className="btn-wrapper--label">Save</span>
        </Button>
        <Button
          size="small"
          className="btn-warning px-4 py-2 btn-transition-none"
          onClick={handleEditClose}
        >
          <span className="btn-wrapper--icon">
            <ReplayTwoToneIcon fontSize="small" />
          </span>
          <span className="btn-wrapper--label">Cancel</span>
        </Button>
      </DialogActionsContainer>
    </Dialog>
  );
};

export default EditModal;
