import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import 'date-fns';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';

import { JobContext } from 'context/jobContextContainer';
import { CallContext } from 'context/callContextContainer';

import { PergoTextField } from 'components/PergoTextField/PergoTextField';
import { driversService } from 'services/driversService';

import JobSectionContent from './JobSectionContent';

const moment = require('moment-timezone');

const Step4 = ({ globalValues, onClickNext, onClickPrev, isCurrentStep, isCallCenter }) => {
  const [isUpdate, setUpdate] = useState(false);
  const [values, setValues] = useState();
  const [selTab, setSelTab] = useState(0);
  const [localDriverList, setLocalDriverList] = useState([]);
  const { driverList } = useContext(JobContext);
  const { siteSetting, callcenterActiveDb } = useContext(CallContext);

  const [formValid, setFormValid] = useState({
    driver_id: 0,
    week_days: 0,
  });

  const [formErrors, setFormErrors] = useState({
    driver_id: '',
    week_days: '',
  });

  useEffect(() => {
    async function initializeStep() {
      const data = await driversService.get_drivers({ dbname: callcenterActiveDb });
      if (data && data.data.status === 'SUCCESS' && data.data.data.num_rows) {
        const drivers = data.data.data.data?.map(item => ({
          id: parseInt(item.driver_id),
          label: `${item.driver_no} - ${
            item?.fname?.length + item?.lname?.length === 0 ? '(No Name)' : `${item?.fname} ${item?.lname}`
          }`,
        }));
        setLocalDriverList(drivers);
      }
    }

    if (isCurrentStep) {
      if (isCallCenter) {
        initializeStep();
      } else {
        setLocalDriverList([...driverList]);
      }
    }
  }, [isCurrentStep]);

  const nowTime = moment()
    .utc()
    .format('YYYY-MM-DD HH:mm');
  const checkValidDate = dt => (moment(dt).isValid() ? dt : nowTime);

  useEffect(() => {
    async function initializeStep() {
      setValues({
        distance: globalValues.distance,
        fare: globalValues.fare,
        is_recurring: globalValues.is_recurring,
        pickup_date_time: checkValidDate(globalValues.pickup_date_time),
        reserve_ahead: globalValues.reserve_ahead,
        is_return: globalValues.is_return,
        is_return_willcall: globalValues.is_return_willcall,
        auto_dispatch: false,
        return_date_time: checkValidDate(globalValues.return_date_time),
        driver_id: globalValues.driver_id,
        pickup_time: checkValidDate(globalValues.pickup_time),
        reserve_ahead_1: globalValues.reserve_ahead_1,
        start_date: checkValidDate(globalValues.start_date),
        end_date: checkValidDate(globalValues.end_date),
        is_weekly: globalValues.is_weekly,
        willcall_status: globalValues.willcall_status,
        is_willcall: globalValues.willcall_status == '1',
        week_days: globalValues.week_days,
      });

      setSelTab(globalValues.willcall_status == 1 ? 2 : globalValues.is_recurring ? 1 : 0);
    }

    if (isCurrentStep) {
      initializeStep();
    }
  }, [isCurrentStep]);

  const setIsReturn = value => {
    setValues({
      ...values,
      is_return: value,
    });
  };

  const setIsReturnWillCall = value => {
    setValues({
      ...values,
      is_return_willcall: value,
    });
  };

  const setAutoDispatch = value => {
    setValues({
      ...values,
      auto_dispatch: value,
    });
  };

  const setJobTab = value => {
    setValues({
      ...values,
      is_recurring: value === 1,
      is_willcall: value === 2,
    });

    setSelTab(value);
  };

  const radioHandleChange = event => {
    const tmpValues = { ...values };
    if (event.target.value === 'true') tmpValues.is_weekly = event.target.value === 'true';
    else {
      tmpValues.is_weekly = false;
      tmpValues.week_days = '';
    }
    setValues(tmpValues);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography component="div" role="tabpanel" hidden={value !== index} {...other}>
        {value === index && <div className="form-tab-body">{children}</div>}
      </Typography>
    );
  }

  const onHandleChange = (id, newValue) => {
    let tmpValue = newValue;
    if (id === 'pickup_date_time' || id === 'return_date_time' || id === 'pickup_time') {
      tmpValue = moment
        .tz(moment(newValue).format('YYYY-MM-DD HH:mm:ss'), siteSetting().timezone)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss');
    }
    setValues({
      ...values,
      [id]: tmpValue,
    });
  };

  const onHandleDayChange = (id, value) => {
    const tmpValues = { ...values };
    if (value) {
      let contain = false;
      for (let i = 0; i < tmpValues.week_days.length; i++) {
        if (parseInt(tmpValues.week_days.charAt(i)) > parseInt(id)) {
          tmpValues.week_days = tmpValues.week_days.replace(
            tmpValues.week_days.charAt(i),
            id + tmpValues.week_days.charAt(i)
          );
          contain = true;
          break;
        }
      }
      if (tmpValues.week_days.length === 0 || !contain) {
        tmpValues.week_days += id;
      }
    } else {
      tmpValues.week_days = tmpValues.week_days.replace(id, '');
    }
    setValues(tmpValues);
  };

  const checkFieldValidate = (id, value) => {
    const fieldValidation = formValid;
    const fieldValidationErrors = formErrors;

    fieldValidation[id] = 1;
    if (id === 'driver_id' && !values.is_recurring) {
      fieldValidation[id] = value > 0 ? 1 : -1;
      fieldValidationErrors[id] = fieldValidation[id] === 1 ? '' : 'Please select driver';
    } else if (id === 'week_days' && values.is_weekly) {
      fieldValidation[id] = value.length > 0 ? 1 : -1;
      fieldValidationErrors[id] = '';
    }

    setFormValid(fieldValidation);
    setFormErrors(fieldValidationErrors);
    setUpdate(!isUpdate);

    return fieldValidation[id];
  };

  const checkStepValidate = () => {
    let stepValid = 0;
    let stepFields = [];

    stepFields = ['week_days'];

    for (const c in stepFields) {
      const element = stepFields[c];
      stepValid += checkFieldValidate(element, values[element]);
    }
    const formValidCount = stepValid === stepFields.length;
    return formValidCount;
  };

  const onNextStep = () => {
    if (checkStepValidate()) onClickNext(values);
  };

  return (
    <StepContent
      title="Reservation"
      prevButton={{
        onClick: onClickPrev,
        label: 'Back',
      }}
      nextButton={{
        onClick: onNextStep,
        label: 'Create Reservation',
      }}
    >
      <PergoTextField id="distance" label="Distance (Miles)" value={`${globalValues.distance} miles`} readonly />
      <div className="form-control-section">
        <Tabs
          textColor="primary"
          value={selTab}
          onChange={(event, newValue) => {
            setJobTab(newValue);
          }}
        >
          <Tab label="One Time" value={0} />
          <Tab label="Recurring" value={1} />
          <Tab label="Will Call" value={2} />
        </Tabs>
        <TabPanel value={selTab} index={0}>
          <div className="form-control-inline ">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                inputVariant="outlined"
                format="yyyy/MM/dd HH:mm"
                ampm
                label="Pickup Time"
                value={
                  values?.pickup_date_time
                    ? moment
                        .utc(values.pickup_date_time)
                        .tz(siteSetting().timezone)
                        .format('MM/DD/YYYY HH:mm')
                    : null
                }
                onChange={e => onHandleChange('pickup_date_time', e)}
              />
            </MuiPickersUtilsProvider>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">Reserve Ahead</InputLabel>
              <Select
                value={parseInt(values?.reserve_ahead) || 0}
                onChange={e => onHandleChange('reserve_ahead', e.target.value)}
              >
                {[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 90, 120].map((item, index) => (
                  <MenuItem value={item} key={index}>
                    {`${String(parseInt(item / 60)).padStart(2, '0')}:${String(parseInt(item % 60)).padStart(2, '0')}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="form-control-inline ">
            <div style={{ display: 'flex', marginBottom: 10 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values?.is_return}
                    onChange={val => {
                      setIsReturn(val.target.checked);
                    }}
                  />
                }
                label="Return"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values?.is_return_willcall}
                    onChange={val => {
                      setIsReturnWillCall(val.target.checked);
                    }}
                    disabled={!values?.is_return}
                  />
                }
                label="Will Call"
              />
            </div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {!values?.is_return_willcall ? (
                <KeyboardDateTimePicker
                  inputVariant="outlined"
                  format="yyyy/MM/dd HH:mm"
                  ampm
                  label="Return"
                  value={
                    values?.return_date_time
                      ? moment
                          .utc(values.return_date_time)
                          .tz(siteSetting().timezone)
                          .format('MM/DD/YYYY HH:mm')
                      : null
                  }
                  onChange={e => onHandleChange('return_date_time', e)}
                  disabled={!values?.is_return}
                />
              ) : (
                <KeyboardDatePicker
                  inputVariant="outlined"
                  format="yyyy/MM/dd"
                  label="Return"
                  value={
                    values?.return_date_time
                      ? moment
                          .utc(values.return_date_time)
                          .tz(siteSetting().timezone)
                          .format('MM/DD/YYYY HH:mm')
                      : null
                  }
                  onChange={e => onHandleChange('return_date_time', e)}
                  disabled={!values?.is_return}
                />
              )}
            </MuiPickersUtilsProvider>
          </div>

          <div className="form-control-inline ">
            <FormControl
              variant="outlined"
              {...(formValid && { error: formValid.driver_id === -1 })}
              style={{ flex: 1 }}
            >
              <InputLabel>Assign Driver</InputLabel>
              <Select value={parseInt(values?.driver_id)} onChange={e => onHandleChange('driver_id', e.target.value)}>
                <MenuItem value="-1/-1">Any</MenuItem>
                {localDriverList?.length > 0 &&
                  localDriverList.map((item, index) => (
                    <MenuItem value={item.id} key={index}>
                      {item.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <div style={{ display: 'flex', flex: 1, marginBottom: 10 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values?.auto_dispatch}
                    onChange={val => {
                      setAutoDispatch(val.target.checked);
                    }}
                  />
                }
                label="Auto Dispatch"
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={selTab} index={1}>
          <div className="form-control-inline ">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardTimePicker
                inputVariant="outlined"
                format="HH:mm"
                ampm
                label="Pickup Time"
                value={
                  values?.pickup_time
                    ? moment
                        .utc(values.pickup_time)
                        .tz(siteSetting().timezone)
                        .format('MM/DD/YYYY HH:mm')
                    : null
                }
                onChange={e => onHandleChange('pickup_time', e)}
                style={{ flex: 2 }}
              />
            </MuiPickersUtilsProvider>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">Reserve Ahead</InputLabel>
              <Select
                value={parseInt(values?.reserve_ahead_1) || 0}
                onChange={e => onHandleChange('reserve_ahead_1', e.target.value)}
              >
                {[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 90, 120].map((item, index) => (
                  <MenuItem value={item} key={index}>
                    {`${String(parseInt(item / 60)).padStart(2, '0')}:${String(parseInt(item % 60)).padStart(2, '0')}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="form-control-inline ">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                inputVariant="outlined"
                format="yyyy/MM/dd"
                ampm
                label="Starting"
                value={
                  values?.start_date
                    ? moment
                        .utc(values.start_date)
                        .tz(siteSetting().timezone)
                        .format('MM/DD/YYYY HH:mm')
                    : null
                }
                onChange={e => onHandleChange('start_date', e)}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                inputVariant="outlined"
                format="yyyy/MM/dd"
                ampm
                label="Until"
                value={
                  values?.end_date
                    ? moment
                        .utc(values.end_date)
                        .tz(siteSetting().timezone)
                        .format('MM/DD/YYYY HH:mm')
                    : null
                }
                onChange={e => onHandleChange('end_date', e)}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div className="form-control-inline ">
            <RadioGroup row name="Repeat" value={values?.is_weekly} onChange={radioHandleChange}>
              <FormControlLabel control={<Radio />} value={false} label="Every Day" />
              <FormControlLabel
                control={<Radio />}
                value
                label="Weekly"
                {...(formValid && {
                  className: formValid.week_days === -1 && values?.is_weekly ? 'error' : '',
                })}
              />
            </RadioGroup>
          </div>

          {values?.is_weekly && (
            <FormGroup aria-label="position" row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values?.week_days.indexOf('1') >= 0}
                    onChange={e => onHandleDayChange('1', e.target.checked)}
                  />
                }
                label="Mon"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values?.week_days.indexOf('2') >= 0}
                    onChange={e => onHandleDayChange('2', e.target.checked)}
                  />
                }
                label="Tue"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values?.week_days.indexOf('3') >= 0}
                    onChange={e => onHandleDayChange('3', e.target.checked)}
                  />
                }
                label="Wed"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values?.week_days.indexOf('4') >= 0}
                    onChange={e => onHandleDayChange('4', e.target.checked)}
                  />
                }
                label="Thu"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values?.week_days.indexOf('5') >= 0}
                    onChange={e => onHandleDayChange('5', e.target.checked)}
                  />
                }
                label="Fri"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values?.week_days.indexOf('6') >= 0}
                    onChange={e => onHandleDayChange('6', e.target.checked)}
                  />
                }
                label="Sat"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values?.week_days.indexOf('7') >= 0}
                    onChange={e => onHandleDayChange('7', e.target.checked)}
                  />
                }
                label="Sun"
              />
            </FormGroup>
          )}
        </TabPanel>
        <TabPanel value={selTab} index={2}>
          <div className="form-control-inline ">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                inputVariant="outlined"
                format="yyyy/MM/dd"
                ampm
                label="Pickup Date"
                value={
                  values?.pickup_date_time
                    ? moment
                        .utc(values.pickup_date_time)
                        .tz(siteSetting().timezone)
                        .format('MM/DD/YYYY HH:mm')
                    : null
                }
                onChange={e => onHandleChange('pickup_date_time', e)}
              />
            </MuiPickersUtilsProvider>
          </div>
        </TabPanel>
      </div>
    </StepContent>
  );
};

export default Step4;

const StepContent = styled(JobSectionContent)``;
