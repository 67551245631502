/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
import React, { useState, useContext, useEffect, useRef } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import MapGL from 'react-map-gl';
import Geocoder from 'react-map-gl-geocoder';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';

import AirplanemodeActiveTwoToneIcon from '@material-ui/icons/AirplanemodeActiveTwoTone';

import { CircularProgressContext } from 'components/CircularProgressOverlay';
import { NotificationContext } from 'components/NotificationOverlay';
import { MapContext } from 'context/mapContext/mapContextContainer';
import { CallContext } from 'context/callContextContainer';
import { locationsService } from 'services/locationsService';
import { notNull } from 'utils/text';
import { getZone } from 'utils/zoneCalc';
import JobSectionContent from './JobSectionContent';
import FlightModal from './FlightModal';


const Step1 = ({ globalValues, onClickNext, onClickPrev, mapType, isCurrentStep, forceInit, isCallCenter }) => {
  const [isUpdate, setUpdate] = useState(false);
  const [values, setValues] = useState({});
  const [blockDialog, setBlockDialog] = useState(false);
  const [selFlightInfo, setSelFlightInfo] = useState(null);
  const pickupRef = useRef(null);
  const dropoffRef = useRef(null);
  const mapRef = useRef();
  const pickupContainerRef = useRef();
  const dropoffContainerRef = useRef();
  const { siteSetting, siteZones, callcenterActiveDb } = useContext(CallContext);

  const [formErrors, setFormErrors] = useState({
    pickup_address: '',
    dropoff_address: '',
  });

  const [prevPickup, setPrevPickup] = useState([]);
  const [prevDropoff, setPrevDropoff] = useState([]);

  const { mapData, setMapData } = useContext(MapContext);
  const { showCircularProgress, hideCircularProgress } = useContext(CircularProgressContext);
  const { showNotification } = useContext(NotificationContext);

  useEffect(() => {
    async function initializeStep() {
      let pickup_flight_number = null;
      let dropoff_flight_number = null;

      if (globalValues.pickup_flight_information) {
        try {
          const data = JSON.parse(globalValues.pickup_flight_information);
          if (data && typeof data === 'object' && data.flight_number && data.iata_code) {
            pickup_flight_number = `${data.iata_code}${data.flight_number}`;
          }
        } catch (e) { console.log('JSON Error', globalValues.pickup_flight_information) }
      }

      if (globalValues.dropoff_flight_information) {
        try {
          const data = JSON.parse(globalValues.dropoff_flight_information);
          if (data && typeof data === 'object' && data.flight_number && data.iata_code) {
            dropoff_flight_number = `${data.iata_code}${data.flight_number}`;
          }
        } catch (e) { console.log('JSON Error', globalValues.dropoff_flight_information) }
      }

      setValues(prevValues => ({
        ...prevValues,
        pickup_place_name: globalValues.pickup_place_name,
        pickup_address: globalValues.pickup_address,
        pickup_suite: globalValues.pickup_suite,
        pickup_city: globalValues.pickup_city,
        pickup_state: globalValues.pickup_state,
        pickup_zip: globalValues.pickup_zip,
        pickup_flight_information: globalValues.pickup_flight_information,
        pickup_flight_number,
        dropoff_place_name: globalValues.dropoff_place_name,
        dropoff_address: globalValues.dropoff_address,
        dropoff_suite: globalValues.dropoff_suite,
        dropoff_city: globalValues.dropoff_city,
        dropoff_state: globalValues.dropoff_state,
        dropoff_zip: globalValues.dropoff_zip,
        dropoff_flight_information: globalValues.dropoff_flight_information,
        dropoff_flight_number,
        step_changed: false,
      }));

      setMapData({
        ...mapData,
        pickup_lat: globalValues.pickup_lat,
        pickup_lng: globalValues.pickup_lng,
        dropoff_lat: globalValues.dropoff_lat,
        dropoff_lng: globalValues.dropoff_lng,
      });

      if (globalValues.passenger_id) {
        showCircularProgress('Loading');
        const data = await locationsService.get_prev_locations({
          passenger_id: globalValues.passenger_id,
          location_type: 1,
          ...(isCallCenter && { dbname: callcenterActiveDb }),
        });
        if (data && data.data.status === 'SUCCESS') {
          if (data.data?.data?.num_rows >= 1) {
            setPrevPickup(data.data.data.data);
          }
        }

        const data1 = await locationsService.get_prev_locations({
          passenger_id: globalValues.passenger_id,
          location_type: 2,
          ...(isCallCenter && { dbname: callcenterActiveDb }),
        });
        if (data1 && data1.data.status === 'SUCCESS') {
          if (data1.data?.data?.num_rows >= 1) {
            setPrevDropoff(data1.data.data.data);
          }
        }
        hideCircularProgress();
      } else {
        setPrevPickup([]);
        setPrevDropoff([]);
      }
    }

    if (isCurrentStep) {
      initializeStep();
    }
  }, [isCurrentStep, forceInit]);

  useEffect(() => {
    const zones = siteZones();
    if (zones && (mapData.pickup_lat !== 0 && mapData.pickup_lng !== 0)) {
      const zoneName = getZone([mapData.pickup_lng, mapData.pickup_lat], zones);
      setValues(prevValues => ({
        ...prevValues,
        pickup_zone: zoneName
      }));
    } else {
      setValues(prevValues => ({
        ...prevValues,
        pickup_zone: false
      }));
    }
  }, [mapData.pickup_lat, mapData.pickup_lng]);

  useEffect(() => {
    const zones = siteZones();
    if (zones && (mapData.dropoff_lat !== 0 && mapData.dropoff_lng !== 0)) {
      const zoneName = getZone([mapData.dropoff_lng, mapData.dropoff_lat], zones);
      setValues(prevValues => ({
        ...prevValues,
        dropoff_zone: zoneName
      }));
    } else {
      setValues(prevValues => ({
        ...prevValues,
        dropoff_zone: false
      }));
    }
  }, [mapData.dropoff_lat, mapData.dropoff_lng]);

  const onGoogleMapSelected = async ({ label, value }, type) => {
    showCircularProgress('Calculating');
    const results = await geocodeByAddress(value.description);
    if (!results) {
      showNotification('Sorry, Error occurred while getting Geo data', 'error');
      hideCircularProgress();
      return;
    }

    const latlng = await getLatLng(results[0]);
    if (!results) {
      showNotification('Sorry, Error occurred while getting Geo data', 'error');
      hideCircularProgress();
      return;
    }
    hideCircularProgress();

    let city = '';
    let state = '';
    let address = ''; 
    let place_name = '';
    let zip = '';

    for (let i = 0; i < results[0].address_components.length; i++) {
      if (results[0].address_components[i].types[0]) {
        if (
          results[0].address_components[i].types.includes('locality') ||
          results[0].address_components[i].types.includes('sublocality')
        )
          city = results[0].address_components[i].long_name;
        else if (results[0].address_components[i].types.includes('street_number'))
          address = results[0].address_components[i].long_name;
        else if (results[0].address_components[i].types.includes('route'))
          address += ' results[0].address_components[i].long_name';
        else if (results[0].address_components[i].types[0] === 'administrative_area_level_1')
          state = results[0].address_components[i].short_name;
        else if (results[0].address_components[i].types[0] === 'postal_code')
          zip = results[0].address_components[i].long_name;
      }
    }
    place_name =
      value.terms.length >= 5 || results[0].formatted_address.indexOf(value.terms[0].value) < 0
        ? value.terms[0].value
        : '';
    
    setValues(prevValues => ({
      ...prevValues,
      [`${type}_city`]: city,
      [`${type}_address`]: address,
      [`${type}_place_name`]: place_name,
      [`${type}_state`]: state,
      [`${type}_zip`]: zip,
      step_changed: true,
    }));

    setMapData({
      ...mapData,
      [`${type}_lat`]: latlng.lat,
      [`${type}_lng`]: latlng.lng,
    });
  };

  const onGeocodeResult = async (type, { result }) => {
    const placeType = result.place_type?.length > 0 ? result.place_type[0] : 'place';
    const parsed = result.place_name.split(', ').reverse();

    let placeName = '';
    let address = '';
    let city = '';
    let state = '';
    let zip = '';

    const postCodeItem = result.context.filter(item => item.id.includes('postcode'));
    if (postCodeItem?.length > 0) {
      zip = postCodeItem[0].text;
    }

    if (parsed[1]) {
      state = parsed[1].split(` ${zip}`)[0];
    }

    if (parsed[2]) {
      city = parsed[2];
    }

    if (parsed[3]) {
      address = parsed[3];
    }

    if (placeType === 'poi') {
      placeName = parsed[parsed.length - 1];
    }

    showCircularProgress('Checking');
    const data = await locationsService.check_block({
      place_name: placeName,
      address,
      city,
      zip,
      state,
      ...(isCallCenter && { dbname: callcenterActiveDb }),
    });
    hideCircularProgress();
    if (data && data.data.status === 'SUCCESS') {
      if (data.data.data >= 0) {
        setBlockDialog(true)
        return;
      }
    }

    setValues(prevValues => ({
      ...prevValues,
      [`${type}_city`]: city,
      [`${type}_address`]: address,
      [`${type}_place_name`]: placeName,
      [`${type}_state`]: state,
      [`${type}_zip`]: zip,
      step_changed: true,
    }));

    setFormErrors({
      ...formErrors,
      [`${type}_address`]: false,
    });

    setMapData({
      ...mapData,
      [`${type}_lat`]: result.geometry.coordinates[1],
      [`${type}_lng`]: result.geometry.coordinates[0],
    });
  }

  const onSelectChange = (type, item) => {
    setValues(prevValues => ({
      ...prevValues,
      [`${type}_city`]: item.city,
      [`${type}_address`]: item.addr1,
      [`${type}_place_name`]: item.location_name,
      [`${type}_state`]: item.state,
      [`${type}_zip`]: item.zip,
      step_changed: true,
    }));

    setFormErrors({
      ...formErrors,
      [`${type}_address`]: false,
    });

    setMapData({
      ...mapData,
      [`${type}_lat`]: parseFloat(item.lat / 1000000.0),
      [`${type}_lng`]: parseFloat(item.lon / 1000000.0)
    });
  };

  const checkValidate = value => value && value.length > 0;

  const checkStepValidate = () => {
    const tmp = {};

    if (!checkValidate(values.pickup_address) || !checkValidate(values.pickup_city) || !checkValidate(values.pickup_state)) {
      tmp.pickup_address = 'Not valid address';
    }

    if (!checkValidate(values.dropoff_address) || !checkValidate(values.dropoff_city) || !checkValidate(values.dropoff_state)) {
      tmp.dropoff_address = 'Not valid address';
    }

    setFormErrors(tmp);
    return !tmp.pickup_address && !tmp.dropoff_address;
  };

  const onNextStep = () => {
    if (checkStepValidate()) {
      onClickNext(values);
    }
  };

  const handleUpdateFlightInfo = info => {
    setSelFlightInfo(null);
    
    if (info.flight_number && info.iata_code) {
      setValues(prevValues => ({
        ...prevValues,
        [`${info.type}_flight_information`]: JSON.stringify({
          airline_name: info.airline_name,
          flight_number: info.flight_number,
          iata_code: info.iata_code,
          flight_date: info.flight_date,
        }),
        [`${info.type}_flight_number`]: `${info.iata_code}${info.flight_number}`,
        step_changed: true,
      }));
    } else {
      setValues(prevValues => ({
        ...prevValues,
        [`${info.type}_flight_information`]: null,
        [`${info.type}_flight_number`]: null,
        step_changed: true,
      }));
    }
  }

  const handleFlightShow = type => {
    let tmp = { type };

    if (values[`${type}_flight_information`]) {
      try {
        const data = JSON.parse(values[`${type}_flight_information`]);
        if (data && typeof data === 'object' && data.airline_name && data.flight_number && data.iata_code) {
          tmp = {
            ...tmp,
            airline_name: data.airline_name,
            flight_number: data.flight_number,
            iata_code: data.iata_code,
          };
        }
      } catch (e) {}
    }

    setSelFlightInfo({ ...tmp });
  }

  const pickupAddrArr = [values.pickup_place_name, values.pickup_address, values.pickup_city, values.pickup_state, values.pickup_zip].filter(str => notNull(str) && str.length > 0);
  const dropoffAddrArr = [values.dropoff_place_name, values.dropoff_address, values.dropoff_city, values.dropoff_state, values.dropoff_zip].filter(str => notNull(str) && str.length > 0);

  return (
    <StepContent
      title="Location"
      className="job-section--location"
      prevButton={{
        onClick: onClickPrev,
        label: 'Back',
      }}
      nextButton={{
        onClick: onNextStep,
        label: 'Next',
      }}
    >
      <div className="form-control-inline">
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">Previous Pickup</InputLabel>
          <Select value="" onChange={e => onSelectChange('pickup', e.target.value)}>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {prevPickup.map((item, index) => (
              <MenuItem value={item} key={index}>{`${item.location_name ? `${item.location_name}, ` : ''}${item.addr1}${item.city ? `, ${item.city}` : ''}`}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">Previous Dropoff</InputLabel>
          <Select value="" onChange={e => onSelectChange('dropoff', e.target.value)}>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {prevDropoff.map((item, index) => (
              <MenuItem value={item} key={index}>{`${item.location_name ? `${item.location_name}, ` : ''}${item.addr1}${item.city ? `, ${item.city}` : ''}`}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {mapType === 'MAPBOX' && (
      <div>
        <MapGL
          ref={mapRef}
          width="100%"
          height="100%"
          mapboxApiAccessToken={process.env.MAPBOX_ACCESS_TOKEN}
        >
          <Geocoder
            ref={pickupRef}
            mapRef={mapRef}
            containerRef={pickupContainerRef}
            className="mapbox_autocomplete"
            inputValue=""
            mapboxApiAccessToken={process.env.MAPBOX_ACCESS_TOKEN}
            onResult={result => onGeocodeResult('pickup', result)}
            proximity={siteSetting() ? { latitude: parseFloat(siteSetting().lat) / 1000000, longitude: parseFloat(siteSetting().lon) / 1000000 } : null}
            placeholder="Pickup location name or address here"
          />
          <Geocoder
            ref={dropoffRef}
            mapRef={mapRef}
            containerRef={dropoffContainerRef}
            className="mapbox_autocomplete"
            inputValue=""
            mapboxApiAccessToken={process.env.MAPBOX_ACCESS_TOKEN}
            onResult={result => onGeocodeResult('dropoff', result)}
            proximity={siteSetting() ? { latitude: parseFloat(siteSetting().lat) / 1000000, longitude: parseFloat(siteSetting().lon) / 1000000 } : null}
            placeholder="Dropoff location name or address here"
          />
        </MapGL>
      </div>
      )}

      <div className="form-control-section">
        {mapType === 'GoogleAPI' && (
          <GooglePlacesAutocomplete
            selectProps={{
              value: null,
              onChange: onGoogleMapSelected,
            }}
          />
        )}
        {mapType === 'MAPBOX' && (
          <div
            className="job-section--geocoder-container job-section--pickup-container"
            ref={pickupContainerRef}
          />
        )}

        <div className="form-control-inline">
          <p style={{ minHeight: 45, marginBottom: 10 }}>
            <b>Pickup Address: </b>
            <span style={formErrors?.pickup_address ? { color: 'red' } : (pickupAddrArr?.length > 0 ? { color: 'var(--success)', fontSize: 15, fontWeight: 600 } : {}) }>
              {formErrors?.pickup_address ? formErrors?.pickup_address : (pickupAddrArr?.length > 0 ? pickupAddrArr.join(', ') : 'Not selected')}
              {values.pickup_zone && (<b style={{ color: 'var(--orange)', textTransform: 'uppercase' }}> - {values.pickup_zone}</b>)}
            </span>
          </p>
        </div>
        <div className="form-control-inline" style={{ marginBottom: 20 }}>
          <Button className={`${values.pickup_flight_number ? 'btn-info' : 'btn-default'} btn-transition-none`} variant="outlined" onClick={() => handleFlightShow('pickup')} style={{ boxShadow: 'none' }}><AirplanemodeActiveTwoToneIcon />&nbsp;{values.pickup_flight_number || 'No Flight Information'}</Button>
        </div>
      </div>

      <div className="form-control-section">
        {mapType === 'GoogleAPI' && (
          <GooglePlacesAutocomplete
            selectProps={{
              value: null,
              onChange: onGoogleMapSelected,
            }}
          />
        )}
        {mapType === 'MAPBOX' && (
          <div
            className="job-section--geocoder-container job-section--dropoff-container"
            ref={dropoffContainerRef}
          />
        )}
        <div className="form-control-inline">
          <p style={{ minHeight: 45 }}>
            <b>Dropoff Address: </b>
            <span style={formErrors?.dropoff_address ? { color: 'red' } : (dropoffAddrArr?.length > 0 ? { color: 'var(--success)', fontSize: 15, fontWeight: 600 } : {}) }>
              {formErrors?.dropoff_address ? formErrors?.dropoff_address : (dropoffAddrArr?.length > 0 ? dropoffAddrArr.join(', ') : 'Not selected')}
              {values.dropoff_zone && (<b style={{ color: 'var(--orange)', textTransform: 'uppercase' }}> - {values.dropoff_zone}</b>)}
            </span>
          </p>
        </div>
        <div className="form-control-inline">
          <Button className={`${values.dropoff_flight_number ? 'btn-info' : 'btn-default'} btn-transition-none`} variant="outlined" onClick={() => handleFlightShow('dropoff')} style={{ boxShadow: 'none' }}><AirplanemodeActiveTwoToneIcon />&nbsp;{values.dropoff_flight_number || 'No Flight Information'}</Button>
        </div>
      </div>

      <FlightModal
        flightEditOpen={selFlightInfo}
        updateFlightInfo={handleUpdateFlightInfo}
        handleFlightEditClose={() => setSelFlightInfo(null)}
      />

      <Dialog
        open={blockDialog}
        onClose={() => setBlockDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Blocked Locations
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This location is blocked. If you want to enable it, Go to Settings.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { window.open('/manage/blocked-locations', '_blank'); setBlockDialog(false); } }>Go To Setting</Button>
          <Button onClick={() => setBlockDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </StepContent>
  );
};

export default Step1;

const StepContent = styled(JobSectionContent)`
  .form-control-section {
    position: relative;

    .job-section--geocoder-container {
      margin-bottom: 10px;
      position: relative;

      .mapboxgl-ctrl-geocoder {
        width: 100%;
        max-width: 100%;
        background: transparent;
        box-shadow: none;
        border: 1px solid lightgray;
        border-radius: 0;

        .suggestions-wrapper {
          position: relative;
        }
      }

      .mapboxgl-ctrl-geocoder--input {
        font-size: 14px;
        font-family: 'Roboto';
      }

      .mapboxgl-ctrl-geocoder--button {
        background: transparent;
      }

      &.job-section--pickup-container {
        z-index: 2000;
      }
      
      &.job-section--dropoff-container {
        z-index: 1000;
      }
    }
  }
`;
