import React, { useState } from 'react';

const JobContext = React.createContext(null);
export { JobContext };

const JobContextContainer = props => {
  const [editJobId, setEditJobId] = useState(0);
  const [driverList, setDriverList] = useState([]);
  const [activeDriverList, setActiveDriverList] = useState([]);
  const [incomingNumber, setIncomingNumber] = useState(null);
  const [outgoingCall, setOutgoingCall] = useState(null);
  const [noShowDialogInfo, setNoShowDialogInfo] = useState(null);
  const [cancelReasonInfo, setCancelReasonInfo] = useState(null);
  const [cancelJobInfo, setCancelJobInfo] = useState(null);
  const [exitJobEditing, setExitJobEditing] = useState(null);

  return (
    <JobContext.Provider
      value={{
        editJobId,
        setEditJobId,
        driverList,
        setDriverList,
        activeDriverList,
        setActiveDriverList,
        incomingNumber,
        setIncomingNumber,
        outgoingCall,
        setOutgoingCall,
        noShowDialogInfo,
        setNoShowDialogInfo,
        cancelJobInfo,
        setCancelJobInfo,
        cancelReasonInfo,
        setCancelReasonInfo,
        exitJobEditing,
        setExitJobEditing,
      }}
    >
      {props.children}
    </JobContext.Provider>
  );
};

export default JobContextContainer;
