import styled from 'styled-components';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer
} from '@material-ui/core';

export const MyTableContainer = styled(TableContainer)`
  overflow: visible;
`;

export const DialogTitleContainer = styled(DialogTitle)`
  padding: 10px 24px;

  h2.MuiTypography-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const CancelJobContentContainer = styled(DialogContent)`
  .dialog-content--wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;

    .MuiFormControl-root {
      width: 100%;
    }
  }
`;

export const NoShowDialogContentContainer = styled(DialogContent)`
  .dialog-content--wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;

    .dialog-content--information-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px 0;
      padding: 0 0 20px;

      p {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .dialog-content--map-section {
      width: 100%;
      height: 300px;
      position: relative;
      border: 2px solid var(--primary);
      box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);

      .map_legend {
        width: 100px;
        height: 80px;
        background-color: white;
        position: absolute;
        bottom: 20px;
        right: 20px;
        border: 1px solid black;
        display: flex;
        flex-direction: column;

        .map_icon_container {
          display: flex;
          align-items: center;
          flex: 1;

          .map_icon {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 8px;
          }

          p {
            font-size: 14px;
            font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
            margin: 0;
            padding: 0;
          }
        }
      }

    }
  }
`;

export const DialogActionsContainer = styled(DialogActions)`
  button {
    min-width: 150px;

    @media (max-width: 576px) {
      min-width: 0;
      width: 100%;
    }
  }
`;