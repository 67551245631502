/* eslint-disable arrow-body-style */
import React, { useState, useContext, useEffect } from 'react';
import {
  Dialog,
  IconButton,
} from '@material-ui/core';
import MyButton from 'components/Elements/MyButton';
import { PergoTextField } from 'components/PergoTextField/PergoTextField';
import MyCheckbox from 'components/Elements/MyCheckbox';
import MyRadioGroup, { MyRadio } from 'components/Elements/MyRadio';

import CloseIcon from '@material-ui/icons/Close';

import { JobContext } from 'context/jobContextContainer';
import { CallContext } from 'context/callContextContainer';
import { CircularProgressContext } from 'components/CircularProgressOverlay';

import { notNull } from 'utils/text';
import { jobsService } from 'services/jobsService';

import { DialogTitleContainer, CancelJobContentContainer, DialogActionsContainer } from './style';

const CancelRejectReasonModal = ({ isCallCenter, handleConfirm }) => {
  const { cancelReasonInfo, setCancelReasonInfo } = useContext(JobContext);
  const [reasonList, setReasonList] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [formErrors, setFormErrors] = useState(null);
  const { showCircularProgress, hideCircularProgress } = useContext(CircularProgressContext);
  const { siteSetting, callcenterActiveDb } = useContext(CallContext);

  const getReasons = async () => {
    if (cancelReasonInfo?.jobInfo.kinetik_trip_id) {
      showCircularProgress('Processing');
      const data = await jobsService.get_cancel_reason({ job_id: cancelReasonInfo?.jobInfo.id });
      hideCircularProgress();
      const list = cancelReasonInfo?.action === 'cancel' ? data?.data?.data?.response?.cancelReasonCodes : data?.data?.data?.response?.rejectReasonCodes;
      if (list) {
        setReasonList({ ...list });
      } else {
        handleEditClose();
        openCancelModal();
      }
    } else if (cancelReasonInfo?.jobInfo.mas_invoice_number) {
      setReasonList({
        1: 'Zip Code',
        2: 'Capacity',
        3: 'Trans Type',
        4: 'Time of Day',
      });
    } else {
      setReasonList({
        1: 'No Show',
        2: 'Passenger Called to Cancel',
        3: 'Passenger Behavior - Base Cancelled',
        4: 'Other'
      });
    }
  }

  useEffect(() => {
    if (cancelReasonInfo?.jobInfo) {
      getReasons();
    }
  }, [cancelReasonInfo?.jobInfo]);

  const openCancelModal = () => {
    handleConfirm({
      jobIds: [cancelReasonInfo?.jobInfo.id],
      action: cancelReasonInfo?.action,
      reason: formValues?.reason
    });
  }

  const handleEditClose = () => {
    setCancelReasonInfo(null);
    setReasonList(null);
    setFormValues(null);
  }

  const handleActionYes = () => {
    if (!formValues?.reason) {
      setFormErrors('Please select reason');
      return true;
    }
    setFormErrors(null);
    handleEditClose();
    openCancelModal();
  }

  const handleActionNo = () => {
    handleEditClose();
  }

  const handleValueChange = (id, value) => {
    setFormValues({
      ...formValues,
      [id]: value,
    });
  };

  if (!cancelReasonInfo || !reasonList) {
    return <></>
  }

  return (
    <Dialog
      classes={{ paper: 'modal-content' }}
      fullWidth
      maxWidth="sm"
      open={!!cancelReasonInfo && !!reasonList}
      onClose={handleEditClose}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitleContainer className="bg-secondary">
        <span>{`Select ${cancelReasonInfo.action === 'cancel' ? 'Cancel' : 'Decline'} Reason`}</span>
        <IconButton
          aria-label="close"
          onClick={handleEditClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitleContainer>
      <CancelJobContentContainer className="p-0" dividers>
        <div className="dialog-content--wrapper bg-secondary border-0">
          {formErrors && <p style={{ color: 'red' }}>{formErrors}</p>}
          <MyRadioGroup
            id="reason"
            values={formValues}
            onChange={handleValueChange}
            column
          >
            {Object.keys(reasonList).map((key, index) => (
              <MyRadio label={reasonList[key]} value={`${key}:${reasonList[key]}`} key={index} />
            ))}
          </MyRadioGroup>
        </div>
      </CancelJobContentContainer>
      <DialogActionsContainer>
        <div />
        <MyButton onClick={handleActionYes} color="primary">
          Yes
        </MyButton>
        <MyButton onClick={handleActionNo} color="secondary" autoFocus>
          No
        </MyButton>
      </DialogActionsContainer>
    </Dialog>
  );
};

export default CancelRejectReasonModal;
