import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';

const ConfirmModal = ({ title, body, open, handleYes, handleNo, yesLabel = 'Yes', noLabel = 'No' }) => (
  <Dialog
    open={open}
    onClose={handleNo}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
    
    {body && (
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{body}</DialogContentText>
      </DialogContent>
    )}

    <DialogActions>
      <Button onClick={handleYes} color="primary">
        {yesLabel}
      </Button>
      <Button onClick={handleNo} color="primary" autoFocus>
        {noLabel}
      </Button>
    </DialogActions>
  </Dialog>
)

export default ConfirmModal;