import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { PergoTextField } from 'components/PergoTextField/PergoTextField';
import { CircularProgressContext } from 'components/CircularProgressOverlay';
import { MapContext } from 'context/mapContext/mapContextContainer';
import { CallContext } from 'context/callContextContainer';
import Config from 'config/index';
import { clearCurrency } from 'utils/text';
import { fareService } from 'services/fareService';
import { accountsService } from 'services/accountsService';
import { vehiclesService } from 'services/vehiclesService';
import JobSectionContent from './JobSectionContent';

const Step2 = ({ globalValues, onClickNext, onClickPrev, onClickMiddle, showPayNow, isCurrentStep, isCallCenter }) => {
  const [values, setValues] = useState({ });
  const [accounts, setAccounts] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [reservationError, setReservationError] = useState(false);
  const { showCircularProgress, hideCircularProgress } = useContext(CircularProgressContext);
  const { mapData } = useContext(MapContext);
  const { siteSetting, callcenterActiveDb } = useContext(CallContext);

  useEffect(() => {
    async function initializeStep() {
      setValues({
        ...values,
        distance: globalValues.distance,
        duration: globalValues.duration || mapData.duration || 0,
        deadhead_distance: globalValues.deadhead_distance || mapData.deadhead_distance || 0,
        passenger_count: globalValues.passenger_count,
        bags: globalValues.bags,
        vehicle_type: globalValues.vehicle_type,
        fare: globalValues.fare,
        tip: globalValues.tip,
        tolls: globalValues.tolls,
        tip_percent: globalValues.tip_percent,
        instruction: globalValues.instruction,
        send_text_update: globalValues.send_text_update,
        tax_included: globalValues.tax_included,
        account_id: globalValues.account_id != 0 ? globalValues.account_id : -1,
        prior_auth_number: globalValues.prior_auth_number,
        fare_calc_type: globalValues.fare_calc_type,
        fare_duration: globalValues.fare_duration,
        booking_deposit_percent: globalValues.booking_deposit_percent || 0,
        step_changed: false,
      });

      showCircularProgress('Loading');
      const data = await accountsService.get_accounts_list({
        ...(isCallCenter && { dbname: callcenterActiveDb }),
      });
      if (data?.data && data.data.status === 'SUCCESS') {
        setAccounts(data.data.data);
      }
      hideCircularProgress();

      showCircularProgress('Loading');
      const data1 = await vehiclesService.get_vehicle_types();
      if (data1?.data && data1.data.status === 'SUCCESS') {
        setVehicleTypes(data1.data.data);
      }
      hideCircularProgress();
      
      setReservationError(false);
    }

    if (isCurrentStep) {
      initializeStep();
    }
  }, [isCurrentStep]);

  const calcFare = (calcValues) => {
    if (calcValues?.fare_calc_type == 1 || parseFloat(calcValues.distance) > 0) {
      showCircularProgress('Calculating');
      fareService.calc_fare({
        distance: calcValues.distance,
        deadhead_distance: calcValues.deadhead_distance,
        passenger_count: calcValues.passenger_count,
        vehicle_type: calcValues.vehicle_type,
        baggage_count: calcValues.bags,
        fare_calc_type: calcValues.fare_calc_type,
        fare_duration: calcValues.fare_duration,
        tax_included: calcValues.tax_included,
        trip_duration: 0,
        ...(calcValues.pickup_zone && { pickup_zone: calcValues.pickup_zone }),
        ...(calcValues.dropoff_zone && { dropoff_zone: calcValues.dropoff_zone }),
        ...(isCallCenter && { dbname: callcenterActiveDb }),
        account_id: calcValues.account_id,
      }).then(({ data }) => {
        if (data.status === 'SUCCESS') {
          if (data.data.num_rows >= 1) {
            setValues({
              ...calcValues,
              fare: data.data.data.fare * 100
            });
          }
        }
      }).finally(() => hideCircularProgress());
    }
  };

  const onHandleChange = (id, newValue) => {
    const tmpValues = { ...values };
    tmpValues[id] = newValue;
    if (id === 'fare' || id === 'tip' || id === 'tolls') {
      tmpValues[id] = parseFloat(newValue.slice(2).replace(/\D/g, ''));
    } else if (id === 'tip_percent') {
      tmpValues.tip = (tmpValues.fare * tmpValues.tip_percent) / 100;
    } else if (id === 'account_id') {
      tmpValues.prior_auth_number = '';
    }
    setValues({
      ...tmpValues,
      step_changed: true,
    });

    if (id === 'passenger_count' || id === 'bags' || id === 'vehicle_type' || id === 'fare_calc_type' || id === 'fare_duration' || id === 'account_id' || id === 'tax_included') {
      calcFare(tmpValues);
    }
  };

  const onNextReservationStep = () => {
    if (globalValues.job_type == 1 && globalValues.status >= 1) {
      setReservationError(true);
      return;
    }

    setReservationError(false);
    onClickNext(values);
  };

  const onNextDispatchStep = () => {
    onClickMiddle(values);
  };

  const site_setting = siteSetting();
  const fare_params = site_setting && JSON.parse(site_setting.fare_params);
  const totalFare = values.fare + values.tip + values.tolls;
  const paidAmount = globalValues?.payments?.reduce((acc, cur) => acc + cur.amount * 100, 0) || 0;
  const bookingDeposit = parseFloat(values.booking_deposit_percent || 0) * totalFare;
  const bdBalance = parseFloat(parseFloat(bookingDeposit / 100).toFixed(2)) - parseFloat(parseFloat(paidAmount / 100).toFixed(2));
  let selAccount = accounts?.length > 0 && parseInt(values.account_id) > 0 ? accounts.filter(acc => acc.id == values.account_id) : false;
  selAccount = selAccount?.[0] ? JSON.parse(selAccount[0].kinetik_account_data) : false;

  return (
    <StepContent
      title="Job Detail"
      prevButton={{
        onClick: onClickPrev,
        label: 'Back',
      }}
      middleButton={{
        onClick: onNextDispatchStep,
        label: 'Dispatch',
      }}
      nextButton={{
        onClick: onNextReservationStep,
        label: 'Reservation',
      }}
    >
      <div className="form-control-inline">
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">Accounts</InputLabel>
          <Select
            value={Number.isNaN(parseInt(values.account_id)) ? -1 : parseInt(values.account_id)}
            onChange={e => onHandleChange('account_id', e.target.value)}
          >
            <MenuItem value={-1}>No Account</MenuItem>
            {accounts?.map((item, index) => <MenuItem value={parseInt(item.id)} key={index}>{item.account_name}</MenuItem>)}
          </Select>
        </FormControl>
        {selAccount ? (
          <PergoTextField
            label="Account Type"
            value={selAccount.type}
            InputProps={{ disabled: true }}
          />
        ) : (
          <PergoTextField
            id="prior_auth_number"
            label="Prior Auth Number"
            values={values}
            handleChange={onHandleChange}
            InputProps={{ disabled: !values?.account_id || values?.account_id == -1 }}
          />
        )}
      </div>
      <div className="form-control-inline form-control-section">
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">Passengers</InputLabel>
          <Select
            value={parseInt(values.passenger_count) || 1}
            onChange={e => onHandleChange('passenger_count', e.target.value)}
          >
            {[...Array(20).keys()].map((item, index) => (
              <MenuItem value={index + 1} key={index}>{`${index + 1} passenger${
                index > 0 ? 's' : ''
              }`}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">Bags</InputLabel>
          <Select value={parseInt(values.bags || 0)} onChange={e => onHandleChange('bags', e.target.value)}>
            <MenuItem value={0}>No Luggage</MenuItem>
            {[...Array(6).keys()].map((item, index) => (
              <MenuItem value={index + 1} key={index}>{`${index + 1} bag${
                index > 0 ? 's' : ''
              }`}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">Vehicle Type</InputLabel>
          <Select
            value={parseInt(values.vehicle_type) || -1}
            onChange={e => onHandleChange('vehicle_type', e.target.value)}
          >
            <MenuItem value={-1}>Any</MenuItem>
            {vehicleTypes.map((item, index) => <MenuItem value={item} key={index}>{Config.VEHICLE_TYPE_LIST[item]}</MenuItem>)}
          </Select>
        </FormControl>
      </div>

      <div className="form-control-inline form-control-section">
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">Fare Calc Type</InputLabel>
          <Select
            value={parseInt(values.fare_calc_type) || 0}
            onChange={e => onHandleChange('fare_calc_type', e.target.value)}
          >
            <MenuItem value={0}>Point To Point</MenuItem>
            <MenuItem value={1}>By Time</MenuItem>
          </Select>
        </FormControl>
        {values.fare_calc_type == 0 ? (
          <PergoTextField
            label="Distance"
            value={`${values?.distance ? `${values.distance} miles` : ''}${Number.isNaN(parseInt(values.duration)) ? '' : ` (Est: ${parseInt(values.duration)} mins)`}`}
            InputProps={{ readOnly: true }}
          />
        ) : (
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Fare Duration</InputLabel>
            <Select
              value={parseInt(values.fare_duration) || 30}
              onChange={e => onHandleChange('fare_duration', e.target.value)}
            >
              {[...Array(20).keys()].map((item, index) => (
                <MenuItem value={(index + 1) * 30} key={index}>{`${(index + 1) * 30} minutes`}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>

      <div className="form-control-inline form-control-section mb-0">
        <PergoTextField
          id="fare"
          label="Fare $"
          type="currency"
          values={values}
          handleChange={onHandleChange}
        />
        <PergoTextField
          id="tolls"
          label="Tolls $"
          type="currency"
          values={values}
          handleChange={onHandleChange}
        />
        <PergoTextField
          id="tip"
          label="Tip $"
          type="currency"
          values={values}
          handleChange={onHandleChange}
        />
        <FormControl variant="outlined" style={{ flex: 0.5 }}>
          <InputLabel htmlFor="outlined-age-native-simple">Tip %</InputLabel>
          <Select
            value={values.tip_percent || 0}
            onChange={e => onHandleChange('tip_percent', e.target.value)}
          >
            <MenuItem value={0}>0%</MenuItem>
            <MenuItem value={5}>5%</MenuItem>
            <MenuItem value={10}>10%</MenuItem>
            <MenuItem value={15}>15%</MenuItem>
            <MenuItem value={20}>20%</MenuItem>
            <MenuItem value={25}>25%</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="form-control-section mt-0">
        <FormControlLabel
          control={
            <Checkbox
              checked={values.tax_included == 1}
              onChange={e => onHandleChange('tax_included', e.target.checked ? 1 : 0)}
            />
          }
          label="Charge Tax"
        />
      </div>
      <div className="form-control-inline ">
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">Booking Deposit</InputLabel>
          <Select
            value={parseFloat(values.booking_deposit_percent || 0)}
            onChange={e => onHandleChange('booking_deposit_percent', e.target.value)}
          >
            {[...Array(21).keys()].map((item, index) => (<MenuItem value={item / 20} key={index}>{item * 5}% - {clearCurrency(totalFare / 20 * item, true)}</MenuItem>))}
          </Select>
        </FormControl>
        <PergoTextField
          label="BD Paid"
          value={clearCurrency(paidAmount, true)}
          readonly
        />
        <PergoTextField
          label="BD Balance"
          value={clearCurrency(bdBalance)}
          readonly
        />
      </div>

      <div className="">
        <PergoTextField
          id="instruction"
          label="Driver Instructions"
          values={values}
          multiline
          rows="1"
          handleChange={onHandleChange}
        />
      </div>

      <div className="form-control-section">
        <FormControlLabel
          control={
            <Checkbox
              checked={values.send_text_update}
              onChange={e => onHandleChange('send_text_update', e.target.checked)}
            />
          }
          label="Send Text Updates"
        />
        {reservationError && <label style={{ float: 'right', lineHeight: '24px', color: 'red' }}>This job is already dispatched</label>}
      </div>
    </StepContent>
  );
};

export default Step2;

const StepContent = styled(JobSectionContent)``;
