/* eslint-disable arrow-body-style */
import React, { useContext, useMemo } from 'react';
import {
  Dialog,
  IconButton,
} from '@material-ui/core';
import ReactMapboxGl, { Marker } from 'react-mapbox-gl';

import CloseIcon from '@material-ui/icons/Close';

import { JobContext } from 'context/jobContextContainer';

import Config from 'config/index';
import { CallContext } from 'context/callContextContainer';

import RedIcon from '../../images/marker_red.png';
import GreenIcon from '../../images/marker_green.png';
import { DialogTitleContainer, NoShowDialogContentContainer } from './style';

const moment = require('moment-timezone');

const Map = typeof window !== 'undefined' &&
  ReactMapboxGl({
    accessToken: process.env.MAPBOX_ACCESS_TOKEN,
  });

const NoShowModal = () => {
  const { noShowDialogInfo, setNoShowDialogInfo } = useContext(JobContext);
  const { siteSetting } = useContext(CallContext);

  const handleEditClose = () => {
    setNoShowDialogInfo(null);
  }

  if (!noShowDialogInfo) {
    return null;
  }

  const { pickupInfo, noShowInfo } = noShowDialogInfo;

  const pickupLoc = [parseFloat(pickupInfo.lon / 1000000.0), parseFloat(pickupInfo.lat / 1000000.0)];
  const noShowLoc = [parseFloat(noShowInfo.lon / 1000000.0), parseFloat(noShowInfo.lat / 1000000.0)];
  const pickupTime = moment(pickupInfo.time).isValid()
      ? moment.utc(pickupInfo.time).tz(siteSetting().timezone).format('MM/DD/YYYY HH:mm')
      : '';
  const noShowTime = moment(noShowInfo.time).isValid()
      ? moment.utc(noShowInfo.time).tz(siteSetting().timezone).format('MM/DD/YYYY HH:mm')
      : '';

  const mapContent = useMemo(() => {
    return (
      <div className="dialog-content--map-section">
        <Map
          style="mapbox://styles/mapbox/streets-v9"
          fitBoundsOptions={{
            padding: { top: 80, bottom: 80, left: 80, right: 80 },
          }}
          containerStyle={{
            height: '100%',
            width: '100%',
          }}
          fitBounds={[pickupLoc, noShowLoc]}
        >
          <Marker coordinates={pickupLoc} anchor="bottom">
            <img src={RedIcon} alt='map-pickup' />
          </Marker>
          <Marker coordinates={noShowLoc} anchor="bottom">
            <img src={GreenIcon} alt='map-vehicle' />
          </Marker>
        </Map>
        <div className="map_legend">
          <div className="map_icon_container">
            <img
              alt=""
              className="map_icon"
              src="https://maps.google.com/mapfiles/ms/icons/red.png"
            />
            <p>Pickup</p>
          </div>
          <div className="map_icon_container">
            <img
              alt=""
              className="map_icon"
              src="https://maps.google.com/mapfiles/ms/icons/green.png"
            />
            <p>Vehicle</p>
          </div>
        </div>
      </div>
    )
  }, [
    pickupLoc[0],
    pickupLoc[1],
    noShowLoc[0],
    noShowLoc[1],
  ]);

  return (
    <Dialog
      classes={{ paper: 'modal-content' }}
      fullWidth
      maxWidth="sm"
      open={noShowDialogInfo}
      onClose={handleEditClose}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitleContainer className="bg-secondary">
        <span>{`Job #${noShowDialogInfo.id} : No Show`}</span>
        <IconButton
          aria-label="close"
          onClick={handleEditClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitleContainer>
      <NoShowDialogContentContainer className="p-0" dividers>
        <div className="dialog-content--wrapper bg-secondary border-0">
          <div className="dialog-content--information-section">
            <p><b>Pickup Address:</b> <span>{pickupInfo.addr}</span></p>
            <p><b>Reservation Time:</b> <span>{pickupTime}</span></p>
            <p><b>No Show Time:</b> <span>{noShowTime}</span></p>
          </div>
          {mapContent}
        </div>
      </NoShowDialogContentContainer>
    </Dialog>
  );
};

export default NoShowModal;
