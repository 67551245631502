import React, { useState, useEffect, useContext } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgressContext } from 'components/CircularProgressOverlay';
import { NotificationContext } from 'components/NotificationOverlay';
import 'date-fns';

import { JobContext } from 'context/jobContextContainer';
import { CallContext } from 'context/callContextContainer';

import { logout } from 'services/loginService';
import { jobsService } from 'services/jobsService';
import TabPanel from '../TabPanel';
import JobTable from './JobTable';
import ReservationTable from './ReservationTable';
import RouteViewTable from './RouteViewTable';
import DeclinedTable from './DeclinedTable';
import NoShowModal from './NoShowModal';
import CancelJobModal from './CancelJobModal';
import CancelRejectReasonModal from './CancelRejectReasonModal';

const moment = require('moment-timezone');

const reservationCallTime = [[0, 0]];

const JobTableSection = ({ jobTableRefresh, reservationTableRefresh, isCallCenter }) => {
  const [jobTab, setJobTab] = useState(0);
  const [jobTableData, setJobTableData] = useState([]);
  const [showActionConfirm, setShowActionConfirm] = useState(false);
  const [showActionFinish, setShowActionFinish] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const { showCircularProgress, hideCircularProgress } = useContext(CircularProgressContext);
  const { showNotification } = useContext(NotificationContext);
  const { setEditJobId, setCancelReasonInfo, cancelJobInfo, setCancelJobInfo } = useContext(JobContext);
  const { callcenterActiveDb } = useContext(CallContext);

  const [reservationTableData, setReservationTableData] = useState([]);
  const [reservationTableInfo, setReservationTableInfo] = useState({
    order: 'asc',
    orderBy: 'time',
    page: 0,
    rowsPerPage: 20,
    search: [],
  });
  const [reservationTableSize, setReservationTableSize] = useState(0);

  const getJobs = () => {
    if (!isCallCenter || callcenterActiveDb) {
      jobsService.get_jobs({ ...(isCallCenter && { dbname: callcenterActiveDb }) }).then(({ data }) => {
        if (data.status === 'SUCCESS') {
          setJobTableData(data.data.data);
        } else if (data.status === 'FAIL' && data.data.code === 'NOT_LOGGED_IN') {
          logout();
          navigate('/login');
        }
      });
    }
  };

  const getReservationJobs = () => {
    if (!isCallCenter || callcenterActiveDb) {
      const startTime = moment().unix();
      jobsService
        .get_reservation_jobs({
          order_by: reservationTableInfo.orderBy,
          order: reservationTableInfo.order,
          search: JSON.stringify(reservationTableInfo.search),
          page_size: reservationTableInfo.rowsPerPage,
          page_num: reservationTableInfo.page,
          ...(isCallCenter && { dbname: callcenterActiveDb }),
        })
        .then(({ data }) => {
          const endTime = moment().unix();
          const lastCallTime = reservationCallTime.pop();
          reservationCallTime.push([startTime, endTime]);
          if (lastCallTime[0] > startTime && lastCallTime[1] <= endTime) {
            // console.log("Reservation : Ignore old call");
            return;
          }
          if (data.status === 'SUCCESS') {
            setReservationTableData(data.data.data);
            setReservationTableSize(parseInt(data.data.cnt) || 0);
          }
        });
    }
  };

  useEffect(() => {
    getJobs();
  }, [jobTableRefresh, isUpdate]);

  useEffect(() => {
    getReservationJobs();
  }, [reservationTableRefresh, isUpdate]);

  const intervalFunc = (runImmediately = false) => {
    const seconds = moment().seconds();
    const milliseconds = moment().milliseconds();
    if (runImmediately || (seconds % 7 === 0 && milliseconds > 900)) {
      if (jobTab === 0) {
        getJobs();
      } else if (jobTab === 1) {
        getReservationJobs();
      }
    }
  };

  useEffect(() => {
    if (intervalId > 0) {
      clearInterval(intervalId);
    }
    intervalFunc(true);
    const id = setInterval(() => intervalFunc(), 100);
    setIntervalId(id);
  }, [
    jobTab,
    reservationTableInfo.order,
    reservationTableInfo.orderBy,
    reservationTableInfo.page,
    reservationTableInfo.rowsPerPage,
    reservationTableInfo.search,
    callcenterActiveDb,
  ]);

  useEffect(() => {
    if (intervalId > 0) {
      clearInterval(intervalId);
    }
    setJobTableData([]);
    setReservationTableData([]);
    setReservationTableSize(0);
    intervalFunc(true);
  }, [callcenterActiveDb]);

  useEffect(() => {
    if (!cancelJobInfo) {
      setUpdate(!isUpdate);
    }
  }, [cancelJobInfo?.id]);

  const handleEdit = job_id => {
    setEditJobId(job_id);
  };

  const handleConfirmAfterGettingReason = ({ jobIds, action, reason }) => {
    // action: 'confirm', 'decline', 'cancel'
    if (action === 'cancel') {
      setCancelJobInfo({ id: jobIds, reason });
    } else {
      setShowActionConfirm({
        action,
        data: { job_id: jobIds[0], confirm: action, reason },
      });
    }
  };

  const handleConfirm = (jobInfo, action) => {
    // action: 'confirm', 'decline', 'cancel'
    if (action !== 'confirm' && jobInfo?.length === 1) {
      setCancelReasonInfo({ jobInfo: jobInfo[0], action });
    } else {
      handleConfirmAfterGettingReason({
        jobIds: jobInfo?.map(info => info.id),
        action,
      });
    }
  };

  const handleActionYes = () => {
    showCircularProgress('Processing');
    jobsService
      .confirm_reservation({
        job_id: showActionConfirm.data.job_id,
        confirm: showActionConfirm.data.confirm === 'confirm',
        reason: showActionConfirm.data.reason,
      })
      .then(({ data }) => {
        hideCircularProgress();
        if (data.status === 'SUCCESS') {
          setUpdate(!isUpdate);
          setShowActionFinish(true);
        } else {
          showNotification(`Sorry, Failed to accept reservation: ${data.data.msg}`, 'error');
        }
      });
    setShowActionConfirm(false);
  };

  return (
    <JobTableSectionWrapper>
      <Paper className="main--job-table-paper" elevation={3} square>
        <Tabs
          value={jobTab}
          onChange={(event, newValue) => {
            setJobTab(newValue);
          }}
        >
          <Tab label="Jobs" />
          <Tab label="Reservation" />
          <Tab label="Route View" />
          <Tab label="Declined" />
        </Tabs>
        <TabPanel value={jobTab} index={0}>
          <JobTable
            data={jobTableData}
            editJob={handleEdit}
            confirmReservation={handleConfirm}
            isCallCenter={isCallCenter}
          />
        </TabPanel>
        <TabPanel value={jobTab} index={1}>
          <ReservationTable
            data={reservationTableData}
            info={reservationTableInfo}
            size={reservationTableSize}
            setInfo={setReservationTableInfo}
            editJob={handleEdit}
            cancelJob={ids => handleConfirm(ids, 'cancel')}
            updateTable={() => setUpdate(!isUpdate)}
            isCallCenter={isCallCenter}
          />
        </TabPanel>
        <TabPanel value={jobTab} index={2}>
          <RouteViewTable
            editJob={handleEdit}
            confirmReservation={handleConfirm}
            isUpdate={isUpdate}
          />
        </TabPanel>
        <TabPanel value={jobTab} index={3}>
          <DeclinedTable
            editJob={handleEdit}
            confirmReservation={handleConfirm}
            isUpdate={isUpdate}
          />
        </TabPanel>
      </Paper>

      <NoShowModal />

      <CancelJobModal isCallCenter={isCallCenter} />

      <CancelRejectReasonModal isCallCenter={isCallCenter} handleConfirm={handleConfirmAfterGettingReason} />

      <Dialog open={showActionConfirm !== false} onClose={() => setShowActionConfirm(false)}>
        <DialogTitle id="alert-dialog-title">
          {showActionConfirm.action === 'confirm'
            ? 'Confirm Reservation'
            : showActionConfirm.action === 'decline'
            ? 'Decline Reservation'
            : 'Job Cancel'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {showActionConfirm.action === 'confirm'
              ? 'Do you really want to agree reservation?'
              : showActionConfirm.action === 'decline'
              ? 'Do you really want to decline reservation?'
              : 'Do you really want to cancel jobs?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleActionYes} color="primary">
            Yes
          </Button>
          <Button onClick={() => setShowActionConfirm(false)} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showActionFinish} onClose={() => setShowActionFinish(false)}>
        <DialogTitle id="alert-dialog-title">Pergo Job</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Successfully processed.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowActionFinish(false)} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </JobTableSectionWrapper>
  );
};

export default JobTableSection;

const JobTableSectionWrapper = styled.div`
  margin-top: 10px;

  .main--job-table-paper {
    border: 2px solid var(--tertiary-main-color);
    background: #efefef;
    min-height: 1000px;

    .MuiTabs-root {
      background: var(--tertiary-main-color);
    }

    .MuiTab-textColorInherit {
      .MuiTab-wrapper {
        color: white;
      }
    }

    .MuiTab-textColorInherit.Mui-selected {
      background: #efefef;

      .MuiTab-wrapper {
        color: var(--tertiary-main-color);
      }
    }

    .MuiTabs-indicator {
      background: transparent;
    }

    .MuiBox-root {
      padding: 10px;
    }

    .MuiTable-root {
      border-left: 1px solid #aaa;
      border-top: 1px solid #aaa;

      .MuiTableSortLabel-root {
        padding-left: 18px;
      }

      .MuiTableHead-root {
      }

      .MuiTableRow-root {
        &.MuiTableRow-hover:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }

        &.job-status-cfs {
          background: transparent;
        }

        &.job-status-backlog {
          background: #d68282;
        }

        &.job-status-dispatched {
          background: #e2f1ea;
        }

        &.job-status-declined {
          background: #fbe0ef;
        }

        &.job-status-accepted {
          background: #c7edee;
        }

        &.job-status-pickup {
          background: #deecf7;
        }

        &.job-status-dropoff {
          background: #e6d8e7;
        }

        &.job-status-cancelled {
          background: #e0ccc3;
        }

        &.job-status-pay {
          background: #fdebd3;
        }

        &.job-status-complete {
          background: #e2ffea;
        }

        &.job-status-cancel {
          background: #ffd1db;
        }

        &.job-status-off_duty {
          background: #ffffb2;
        }

        &.job-status-assigned,
        &.reservation-status-assigned {
          background: #ffffb2;
        }

        &.job-status-accepted,
        &.reservation-status-accepted {
          background: #e2f1ea;
        }

        &.job-status-declined,
        &.reservation-status-declined {
          background: #ffd1db;
        }

        &.job-status-no-driver-green {
          background: #008000;
        }

        &.job-status-no-driver-orange {
          background: #ff7f00;
        }

        &.job-status-no-driver-red {
          -webkit-animation: background-blink 4s infinite;
          -moz-animation: background-blink 4s infinite;
          -o-animation: background-blink 4s infinite;
          animation: background-blink 4s infinite;
        }

        &.reservation-route-view-row {
          td {
            background: #008000;
            color: white;
            font-weight: 800;
            text-align: center;
          }
        }
      }

      .MuiTableCell-root {
        padding: 5px 10px;
        font-size: 13px;
        border-right: 1px solid #aaa;
        border-bottom: 1px solid #aaa;
        position: relative;

        .col-redirect-icon {
          position: absolute;
          right: 5px;
          top: 50%;
          transform: translateY(-50%);

          a {
            color: var(--primary);
          }
        }

        &.col-reservation-time {
          .MuiInputBase-input {
            font-size: 13px;
            text-align: center;
            padding: 9px 0 7px;
          }

          &.willcall-time {
            .MuiInputBase-input {
              color: red;
            }
          }
        }
      }

      .MuiTableCell-head {
        font-size: 14px;
        line-height: 18px;
        padding: 11px 5px;
        background: #ccc;
      }

      .MuiCheckbox-root {
        padding: 4px;
      }

      .MuiButton-textPrimary {
        font-size: 12px;
      }

      .job-table--action-icon {
        cursor: pointer;
        min-width: 0;
        width: 25px;
        height: 25px;

        &.color-success {
          background: var(--green);
          color: white;
        }
      }
    }
  }
`;
