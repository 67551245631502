import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import { CallContext } from 'context/callContextContainer';
import { PergoTitle } from '../PergoTitle';

const JobSectionContent = ({
  title,
  children,
  prevButton,
  nextButton,
  middleButton,
  isCallCenter,
  ...otherProps
}) => {
  const { callcenterActiveDb } = useContext(CallContext);

  return (
    <div className="main--job-body">
      <div className="main--job-step-header">
        <PergoTitle className="main--job-step-title">{title}</PergoTitle>
      </div>
      <div className={`main--job-step-body ${otherProps.className || ''}`}>{children}</div>
      <div className="main--job-step-footer">
        {prevButton ? (
          <Button className="main--job-step-btn-prev" variant="outlined" onClick={prevButton.onClick} disabled={isCallCenter && !callcenterActiveDb}>
            {prevButton.label}
          </Button>
        ) : (
          <div />
        )}
        <div className="form-control-inline">
          {middleButton && (
            <Button
              className="main--job-step-btn-next-other"
              variant="outlined"
              onClick={middleButton.onClick}
              disabled={isCallCenter && !callcenterActiveDb}
            >
              {middleButton.label}
            </Button>
          )}
          {nextButton && (
            <Button
              className="main--job-step-btn-next"
              variant="outlined"
              onClick={nextButton.onClick}
              disabled={isCallCenter && !callcenterActiveDb}
            >
              {nextButton.label}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
};

export default JobSectionContent;
