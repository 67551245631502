/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Checkbox,
  Button,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
} from '@material-ui/core';
import Select from 'react-select';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  DateTimePicker,
} from '@material-ui/pickers';

import { format } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewTwoToneIcon from '@material-ui/icons/OpenInNewTwoTone';

import { PergoTextField } from 'components/PergoTextField/PergoTextField';
import { CircularProgressContext } from 'components/CircularProgressOverlay';

import { JobContext } from 'context/jobContextContainer';
import { CallContext } from 'context/callContextContainer';
import { jobsService } from 'services/jobsService';
import { getLoginUser } from 'services/loginService';

const moment = require('moment-timezone');

const headCells = [
  { id: 'job_id', disablePadding: true, label: 'Job #' },
  { id: 'driver', disablePadding: false, label: 'Driver' },
  { id: 'time', disablePadding: false, label: 'Reservation Time' },
  { id: 'passenger', disablePadding: false, label: 'Passenger' },
  { id: 'pickup', disablePadding: false, label: 'Pickup' },
  { id: 'dropoff', disablePadding: false, label: 'Drop Off' },
  { id: 'distance', disablePadding: false, label: 'Distance' },
  { id: 'payment_type', disablePadding: false, label: 'Payment Type' },
  { id: 'fare', disablePadding: false, label: 'Fare' },
  { id: 'cancel', disablePadding: false, label: '' },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, cancelJobRequest } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const handleCancelClick = () => {
    cancelJobRequest();
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" align="center">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell key={headCell.id} align="center" sortDirection={orderBy === headCell.id ? order : false}>
            {headCell.id !== 'cancel' ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              <Button color="primary" onClick={handleCancelClick}>
                Cancel
              </Button>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const ReservationTable = ({ data, info, size, setInfo, editJob, cancelJob, updateTable, isCallCenter }) => {
  const [tableData, setTableData] = useState([]);
  const [selected, setSelected] = React.useState([]);
  const [assignDriverConfirm, setAssignDriverConfirm] = useState(false);
  const { showCircularProgress, hideCircularProgress } = useContext(CircularProgressContext);
  const { driverList } = useContext(JobContext);
  const { siteSetting } = useContext(CallContext);

  const paymentType = ['Cash', 'Card', 'Voucher', 'EHail Mobile', 'Karhoo', 'Stored Card', 'Webapp Credit'];

  const jobTableRowFormat = row => {
    let reservation_time = moment(row.reservation_time).isValid()
      ? moment
          .utc(row.reservation_time)
          .tz(siteSetting().timezone)
          .format(row.willcall_status == '1' ? 'MM/DD/YYYY' : 'MM/DD/YYYY HH:mm')
      : '';
    let distance = parseFloat(row.distance).toFixed(2);
    let payment_type = paymentType[row.tender_type];

    return {
      ...row,
      job_id: row.id,
      time: reservation_time,
      passenger: `${row.passenger_fname} ${row.passenger_lname}`,
      driver_id: row.driver_id == 0 ? -1 : row.driver_id,
      pickup: row.pickup
        ? [row.pickup.location_name, row.pickup.addr1, row.pickup.city, row.pickup.state]
            .filter(str => str?.length > 0)
            .join(', ')
        : row.start_addr,
      dropoff: row.dropoff
        ? [row.dropoff.location_name, row.dropoff.addr1, row.dropoff.city, row.dropoff.state]
            .filter(str => str?.length > 0)
            .join(', ')
        : row.end_addr,
      distance,
      payment_type,
      fare: row.fare,
    };
  };

  useEffect(() => {
    const tblData = data.map(item => jobTableRowFormat(item));
    setTableData(tblData);
  }, [data]);

  const handleRequestSort = (event, property) => {
    const isAsc = info.orderBy === property && info.order === 'asc';
    setInfo({
      ...info,
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    });
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = tableData.map(n => n.job_id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, job_id) => {
    const selectedIndex = selected.indexOf(job_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, job_id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setInfo({
      ...info,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = event => {
    setInfo({
      ...info,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const handleSearchClick = searchValues => {
    setInfo({
      ...info,
      search: [
        { id: 'fname', value: searchValues.fname },
        { id: 'lname', value: searchValues.lname },
        { id: 'phone', value: searchValues.phone },
        { id: 'reservation_date', value: searchValues.reservation_date },
      ],
    });
  };

  const handlePrintClick = () => {
    window.open(
      `${process.env.BACKEND_API_BASE_URL}/jobs.php?method=print_reservation_jobs&order_by=${info.orderBy}&order=${
        info.order
      }&search=${JSON.stringify(info.search)}&token=${getLoginUser().token}`,
      '_blank'
    );
  };

  const handleEditJob = jobId => {
    editJob(jobId);
  };

  const handleCancelClick = (jobInfo = undefined) => {
    if (jobInfo) {
      cancelJob([jobInfo]);
    } else if (selected.length > 0) {
      const jobInfos = tableData.filter(item => selected.includes(item.job_id));
      cancelJob(jobInfos);
    }
  };

  const onClickDriverAssign = async (jobId, driverId, index) => {
    const selDriver = driverList.filter(item => item.id === driverId);
    if (selDriver?.length > 0) {
      showCircularProgress('Loading');
      const res = await jobsService.assign_driver_to_job(jobId, driverId);
      if (res && res?.data?.status === 'SUCCESS') {
        updateTable();
      }
      hideCircularProgress();
    }
  };

  const onChangeReservation = async (jobId, reservation_time, index) => {
    reservation_time = moment
      .tz(moment(reservation_time).format('YYYY-MM-DD HH:mm:ss'), siteSetting().timezone)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');

    showCircularProgress('Loading');
    const res = await jobsService.change_reservation_time(jobId, reservation_time);
    if (res && res?.data?.status === 'SUCCESS') {
      updateTable();
    }
    hideCircularProgress();
  };

  const handleDriverAssign = async () => {
    setAssignDriverConfirm(false);
    const { jobId, driverId } = assignDriverConfirm;
    showCircularProgress('Loading');
    const res = await jobsService.assign_driver_to_job(jobId, driverId);
    if (res && res?.data?.status === 'SUCCESS') {
      updateTable();
    }
    hideCircularProgress();
  };

  const driverIdToOption = driverId => {
    if (driverId < 0) {
      return {
        value: -1,
        label: '',
      };
    }
    const selDriver = driverList.filter(item => item.id == driverId);
    if (selDriver.length > 0) {
      return {
        value: selDriver[0].value,
        label: selDriver[0].label,
      };
    }
    return {
      value: -1,
      label: '',
    };
  };

  const isSelected = jobId => selected.indexOf(jobId) !== -1;

  return (
    <TableContainer>
      <TableSearch>
        <TableSearchGroup
          values={info.search}
          onPrintClick={handlePrintClick}
          onSearchClick={handleSearchClick}
        />

        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={size}
          rowsPerPage={info.rowsPerPage}
          page={info.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableSearch>

      <Table aria-label="job table">
        <EnhancedTableHead
          numSelected={selected.length}
          order={info.order}
          orderBy={info.orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={tableData.length}
          cancelJobRequest={() => handleCancelClick()}
        />

        <TableBody>
          {tableData.map((row, index) => {
            const isItemSelected = isSelected(row.job_id);
            const labelId = `enhanced-table-checkbox-${index}`;
            let status_attr = '';
            if (row.driver_id && parseInt(row.driver_id) > 0) {
              status_attr = 'assigned';

              if (row.status == 1) {
                status_attr = 'accepted';
              }
            } else if (row.status == 3) {
              status_attr = 'declined';
            }

            return (
              <TableRow
                className={`reservation-status-${status_attr}`}
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={`${row.job_id}_${index}`}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox" align="center">
                  <Checkbox
                    checked={isItemSelected}
                    onClick={event => handleClick(event, row.job_id)}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </TableCell>
                <TableCell
                  align="center"
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="none"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleEditJob(row.job_id)}
                >
                  {row.job_id}
                </TableCell>
                <TableCell align="center">
                  {row.willcall_status == '1' ? (
                    <></>
                  ) : isCallCenter ? (
                    <>{parseInt(row.driver_id) > 0 ? `${row.driver_fname} ${row.driver_lname}` : ''}</>
                  ) : (
                    <DriverSelect
                      classNamePrefix="driver-select"
                      value={driverIdToOption(parseInt(row.driver_id))}
                      onChange={selectedOption => onClickDriverAssign(row.job_id, selectedOption.value, index)}
                      options={
                        driverList?.length > 0
                          ? driverList.map(item => ({
                              value: item.id,
                              label: item.label,
                            }))
                          : []
                      }
                    />
                  )}
                </TableCell>
                {/* <TableCell align="center">
                  {row.time}
                  {row.willcall_status == '1' ? <span style={{ color: 'red' }}>&nbsp;(Will Call)</span> : ''}
                </TableCell> */}
                <TableCell
                  align="center"
                  className={`col-reservation-time ${row.willcall_status == '1' ? 'willcall-time' : ''}`}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      format={row.willcall_status == '1' ? 'yyyy/MM/dd ??:??' : 'yyyy/MM/dd HH:mm'}
                      value={row.time}
                      onChange={e => onChangeReservation(row.job_id, e, index)}
                    />
                  </MuiPickersUtilsProvider>
                </TableCell>
                <TableCell align="center">{row.passenger}</TableCell>
                <TableCell align="center">{row.pickup}</TableCell>
                <TableCell align="center">{row.dropoff}</TableCell>
                <TableCell align="center">{row.distance}</TableCell>
                <TableCell align="center">
                  {row.payment_type}
                  {parseFloat(row.booking_deposit_amount) > 0 && row.booking_deposit_status == 0 ? (
                    <>
                      <br />
                      <span style={{ color: 'red' }}>(Balance Due)</span>
                    </>
                  ) : (
                    <></>
                  )}
                  {parseFloat(row.booking_deposit_amount) > 0 && (
                    <div className="col-redirect-icon">
                      <a
                        href={`/invoice?token=${siteSetting()?.settings?.reservation_token}&job_id=${row.job_id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <OpenInNewTwoToneIcon fontSize="small" />
                      </a>
                    </div>
                  )}
                </TableCell>
                <TableCell align="center">{row.fare}</TableCell>
                <TableCell align="center">
                  <Button
                    className="job-table--action-icon m-1"
                    variant="contained"
                    color="secondary"
                    onClick={() => handleCancelClick(row)}
                  >
                    <CloseIcon fontSize="small" />
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={size}
        rowsPerPage={info.rowsPerPage}
        page={info.page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Dialog open={!!assignDriverConfirm} onClose={() => setAssignDriverConfirm(false)}>
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Do you really want to assign ${assignDriverConfirm?.driverLabel} driver to Job #${assignDriverConfirm?.jobId}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDriverAssign} color="primary" autoFocus>
            Yes
          </Button>
          <Button onClick={() => setAssignDriverConfirm(false)} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
};

export default ReservationTable;

const TableSearchGroup = ({ values, onSearchClick, onPrintClick }) => {
  const [searchValues, setSearchValues] = useState({
    fname: values?.[0]?.value || '',
    lname: values?.[1]?.value || '',
    phone: values?.[2]?.value || '',
  });

  const onSearchChange = (id, newValue) => {
    let tmpValue = newValue;
    if (id === 'reservation_date') {
      try {
        tmpValue = format(newValue, 'yyy-MM-dd');
      } catch {
        tmpValue = '';
      }
    }
    setSearchValues({
      ...searchValues,
      [id]: tmpValue,
    });
  };

  const handleSearchClick = () => {
    onSearchClick?.(searchValues);
  };

  return (
    <div className="reservation-table-search--container">
      <PergoTextField id="fname" values={searchValues} handleChange={onSearchChange} label="First Name" />
      <PergoTextField id="lname" values={searchValues} handleChange={onSearchChange} label="Last Name" />
      <PergoTextField id="phone" values={searchValues} handleChange={onSearchChange} label="Phone" />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          inputVariant="outlined"
          format="yyyy/MM/dd"
          ampm
          label="Reservation Date"
          value={
            searchValues?.reservation_date ? moment(searchValues.reservation_date).format('MM/DD/YYYY HH:mm') : null
          }
          onChange={e => onSearchChange('reservation_date', e)}
        />
      </MuiPickersUtilsProvider>
      <Button className="reservation-table-search--button" onClick={handleSearchClick}>
        Search
      </Button>
      <Button className="reservation-table-print--button" onClick={onPrintClick}>
        Print
      </Button>
    </div>
  );
};

const TableSearch = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .reservation-table-search--container {
    padding-top: 5px;

    .MuiTextField-root {
      margin-right: 10px;
    }

    .MuiInputLabel-outlined {
      font-size: 14px;
      transform: translate(14px, 12px) scale(1);
      background: transparent;
      padding: 0 3px;

      &.MuiInputLabel-shrink {
        transform: translate(14px, -5px) scale(0.75);
      }
    }

    .MuiOutlinedInput-input {
      padding: 10px;
      font-size: 14px;
      height: auto;
    }

    .reservation-table-search--button,
    .reservation-table-print--button {
      border-radius: 0;
      background: var(--blue-main-color);
      color: white;
      height: 39px;
    }

    .reservation-table-print--button {
      background: var(--green-main-color);
      margin-left: 5px;
    }
  }
`;

const DriverSelect = styled(Select)`
  min-width: 150px;

  .driver-select__control {
    border: none;
    border-bottom: 1px solid #888;
    border-radius: 0;
    background: transparent;
    text-align: left;
    box-shadow: none;
    font-size: 13px;
    min-height: 0;
  }

  .driver-select__indicator {
    padding: 3px 5px;
  }

  .driver-select__menu {
    text-align: left;
    min-width: 200px;
  }
`;
