import React, { useState, useEffect, useContext } from 'react';
import { navigate } from 'gatsby';
import {
  Button,
  Dialog,
} from '@material-ui/core';

import { MyCardRow } from 'components/Elements/MyCard';
import MyInput from 'components/Elements/MyInput';
import MyButton from 'components/Elements/MyButton';
import MySelect from 'components/Elements/MySelect';
import MyCreditCard from 'components/Elements/MyCreditCard';

import AttachMoneyTwoToneIcon from '@material-ui/icons/AttachMoneyTwoTone';
import ClearTwoToneIcon from '@material-ui/icons/ClearTwoTone';

import { CircularProgressContext } from 'components/CircularProgressOverlay';
import { NotificationContext } from 'components/NotificationOverlay';

import Config from 'config/index';
import { notNull, cleanPhone } from 'utils/text';
import { logout } from 'services/loginService';
import { paymentService } from 'services/paymentService';

import { DialogTitleContainer, DialogContentContainer } from './style';

import 'react-credit-cards/es/styles-compiled.css';

const AddPaymentModal = ({
  passengerDetail,
  addPaymentOpen,
  handleAddPaymentClose,
  handleRefreshDetail,
}) => {
  const [formValues, setFormValues] = useState({
    name: '',
    number: '',
    expiry: '',
    cvc: '',
    focus: '',
    country: 'United States',
    address1: null,
    city: null,
    state: null,
    zip: null,
  });
  const [formErrors, setFormErrors] = useState(null);
  const [cardValid, setCardValid] = useState(false);
  const { showCircularProgress, hideCircularProgress } = useContext(CircularProgressContext);
  const { showNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (!addPaymentOpen) {
      setFormValues({
        name: '',
        number: '',
        expiry: '',
        cvc: '',
        focus: '',
        country: 'United States',
        address1: null,
        city: null,
        state: null,
        zip: null,
      });
    }
  }, [addPaymentOpen]);

  const handleValueChange = (id, value) => {
    setFormValues({
      ...formValues,
      [id]: value,
    });
  };

  const checkValueValidate = (id, value) => {
    const valid = notNull(value) && value.toString().length > 0;
    let error = null;

    if (!valid) {
      error = 'Please input value';
    }

    if (id === 'name' || id === 'number' || id === 'expiry' || id === 'cvc') {
      error = cardValid ? null : 'Please input valid card information';
    }

    return error;
  };

  const checkValidate = () => {
    const keys = Object.keys(formValues);
    const errors = {};
    keys.forEach(item => {
      const error = checkValueValidate(item, notNull(formValues) ? formValues[item] : null);
      errors[item] = error;
    })
    setFormErrors(errors);

    const stepValid = Object.keys(errors).filter(item => errors[item] !== null).length;
    return stepValid === 0;
  };

  const handleAddPayment = () => {
    if (!checkValidate()) {
      return;
    }

    showCircularProgress('Please wait. Saving Payment Method...');
    paymentService.add_payment_to_passenger({
      ...formValues,
      fname: passengerDetail.first_name,
      lname: passengerDetail.last_name,
      email: passengerDetail.email,
      number: formValues.number.replace(/\s/g, ''),
      expMonth: formValues.expiry.split('/')[0],
      expYear: formValues.expiry.split('/')[1],
      phone: cleanPhone(passengerDetail.phone)
    }).then(({ data }) => {
      hideCircularProgress();
      if (data.status === 'SUCCESS') {
        showNotification('Successfully saved', 'success');
        handleAddPaymentClose(true);
        handleRefreshDetail();
      } else {
        showNotification(`Sorry, Failed to create account: ${data.data.message}`, 'error');
        if (data.data.code === 'NOT_LOGGED_IN') {
          logout();
          navigate('/login');
        }
      }
    });
  }

  const handleCardCallback = ({ issuer }, isValid) => {
    handleValueChange('cardType', issuer);
    setCardValid(isValid);
  }

  return (
    <Dialog
      classes={{ paper: 'modal-content' }}
      fullWidth
      maxWidth="sm"
      open={notNull(addPaymentOpen)}
      onClose={() => handleAddPaymentClose()}
      closeable
    >
      <DialogTitleContainer className="bg-secondary">
        <span>
          {addPaymentOpen} Payment Card
        </span>
        <Button
          size="small"
          className="btn-transparent p-2 btn-transition-none"
          onClick={() => handleAddPaymentClose()}
        >
          <span className="btn-wrapper--icon">
            <ClearTwoToneIcon fontSize="small" />
          </span>
        </Button>
      </DialogTitleContainer>
      <DialogContentContainer className="p-0" dividers>
        <div className="dialog-content--wrapper bg-secondary border-0">
          <div className="card-body px-lg-4 py-lg-3">
            <div className="card-body--column w-100">
              <MyCardRow className="mb-3">
                <MyCreditCard
                  cvc={formValues.cvc}
                  expiry={formValues.expiry}
                  focused={formValues.focus}
                  name={formValues.name}
                  number={formValues.number}
                  callback={handleCardCallback}
                />
              </MyCardRow>

              <MyCardRow>
                <MyCardRow direction="column">
                  <MyCardRow>
                    <MyInput
                      label="Card Number *"
                      id="number"
                      values={formValues}
                      onChange={handleValueChange}
                      onFocus={() => handleValueChange('focus', 'number')}
                      error={notNull(formErrors?.number)}
                      helperText={formErrors?.number}
                    />
                  </MyCardRow>
                  <MyCardRow>
                    <MyInput
                      label="Card Holder Name *"
                      id="name"
                      values={formValues}
                      onChange={handleValueChange}
                      onFocus={() => handleValueChange('focus', 'name')}
                      error={notNull(formErrors?.name)}
                      helperText={formErrors?.name}
                    />
                  </MyCardRow>
                  <MyCardRow>
                    <MyInput
                      label="Expiration Date *"
                      id="expiry"
                      values={formValues}
                      onChange={handleValueChange}
                      onFocus={() => handleValueChange('focus', 'expiry')}
                      error={notNull(formErrors?.expiry)}
                      helperText={formErrors?.expiry}
                      maskProps={{
                        mask: [/\d/,/\d/,'/',/\d/,/\d/]
                      }}
                    />
                  </MyCardRow>
                  <MyCardRow>
                    <MyInput
                      label="CVC *"
                      id="cvc"
                      type="number"
                      values={formValues}
                      onChange={handleValueChange}
                      onFocus={() => handleValueChange('focus', 'cvc')}
                      error={notNull(formErrors?.cvc)}
                      helperText={formErrors?.cvc}
                      maxLength="4"
                    />
                  </MyCardRow>
                </MyCardRow>

                <MyCardRow direction="column">
                  <MyCardRow>
                    <MyInput
                      label="Country *"
                      id="country"
                      value="United States"
                    />
                  </MyCardRow>
                  <MyCardRow>
                    <MyInput
                      label="Address 1 *"
                      id="address1"
                      values={formValues}
                      onChange={handleValueChange}
                      error={notNull(formErrors?.address1)}
                      helperText={formErrors?.address1}
                    />
                  </MyCardRow>
                  <MyCardRow>
                    <MyInput
                      label="Address 2"
                      id="address2"
                      values={formValues}
                      onChange={handleValueChange}
                      error={notNull(formErrors?.address2)}
                      helperText={formErrors?.address2}
                    />
                  </MyCardRow>
                  <MyCardRow>
                    <MyInput
                      label="City *"
                      id="city"
                      values={formValues}
                      onChange={handleValueChange}
                      error={notNull(formErrors?.city)}
                      helperText={formErrors?.city}
                    />
                  </MyCardRow>
                  <MyCardRow>
                    <MySelect
                      label="State *"
                      id="state"
                      values={formValues}
                      onChange={handleValueChange}
                      options={Config.STATES_LIST.map(item => ({ value: item, label: item }))}
                      error={notNull(formErrors?.state)}
                      helperText={formErrors?.state}
                    />
                  </MyCardRow>
                  <MyCardRow>
                    <MyInput
                      label="Postal Code *"
                      id="zip"
                      type="number"
                      values={formValues}
                      onChange={handleValueChange}
                      error={notNull(formErrors?.zip)}
                      helperText={formErrors?.zip}
                    />
                  </MyCardRow>
                </MyCardRow>
              </MyCardRow>
              <hr className="MuiDivider-root mt-3 mb-2" />
              <MyCardRow>
                <MyButton
                  type="primary"
                  onClick={handleAddPayment}
                >
                  <span className="btn-wrapper--icon">
                    <AttachMoneyTwoToneIcon fontSize="small" />
                  </span>
                  <span className="btn-wrapper--label">{addPaymentOpen} Payment</span>
                </MyButton>
              </MyCardRow>
            </div>
          </div>
        </div>
      </DialogContentContainer>
    </Dialog>
  );
};

export default AddPaymentModal;
