import React, { useState, useEffect, useContext } from 'react';
import { navigate } from 'gatsby';
import {
  Button,
  Dialog,
} from '@material-ui/core';

import { MyCardRow } from 'components/Elements/MyCard';
import MyButton from 'components/Elements/MyButton';
import MyDescription from 'components/Elements/MyDescription';
import MyInput from 'components/Elements/MyInput';

import AttachMoneyTwoToneIcon from '@material-ui/icons/AttachMoneyTwoTone';
import ClearTwoToneIcon from '@material-ui/icons/ClearTwoTone';
import PaymentTwoToneIcon from '@material-ui/icons/PaymentTwoTone';

import { CircularProgressContext } from 'components/CircularProgressOverlay';
import { NotificationContext } from 'components/NotificationOverlay';

import Config from 'config/index';
import { notNull, cleanPhone, creditCardType } from 'utils/text';
import { logout } from 'services/loginService';
import { paymentService } from 'services/paymentService';

import { DialogTitleContainer, DialogContentContainer } from './style';

import 'react-credit-cards/es/styles-compiled.css';
import { jobsService } from '../../services/jobsService';

const PayNowModal = ({
  jobDetail,
  payNowOpen,
  handlePayNowClose,
  handleAddCard,
  handleRefreshDetail,
}) => {
  if (!payNowOpen) {
    return null;
  }

  const convertCurrency = (val) => (parseFloat(val) / 100).toFixed(2);
  const totalPaid = jobDetail?.payments?.reduce((acc, cur) => acc + cur.amount * 100, 0) || 0;
  const balance = parseFloat(jobDetail.fare) + parseFloat(jobDetail.tip) - parseFloat(totalPaid);

  const [formValues, setFormValues] = useState({ amount: convertCurrency(balance) });
  const [formErrors, setFormErrors] = useState(null);
  const { showCircularProgress, hideCircularProgress } = useContext(CircularProgressContext);
  const { showNotification } = useContext(NotificationContext);

  const handleValueChange = (id, value) => {
    setFormValues({
      ...formValues,
      [id]: value,
    });
  };

  const checkValidate = () => {
    const valid = notNull(formValues.amount) && !Number.isNaN(parseFloat(formValues.amount)) && parseFloat(formValues.amount) >= 0;
    if (!valid) {
      setFormErrors({
        amount: 'Please input valid amount'
      });
      return false;
    }

    if (parseFloat(formValues.amount * 100) > balance) {
      setFormErrors({
        amount: 'Pay amount can\'t be bigger than balance'
      });
      return false;
    }

    setFormErrors({ });
    return true;
  } 

  const handlePayNow = async () => {
    if (!checkValidate()) {
      return;
    }

    showCircularProgress('Processing...');
    const data = await jobsService.job_process_payment(jobDetail.job_id, parseFloat(formValues.amount * 100));
    hideCircularProgress();
    if (data && data?.data?.status === 'SUCCESS') {
      showNotification('Successfully paid', 'success');
      handlePayNowClose();
      handleRefreshDetail();
    } else {
      showNotification(`Sorry, Error occurred: ${data?.data?.data?.code || 'Internal error'}`, 'error');
    }
  }

  return (
    <Dialog
      classes={{ paper: 'modal-content' }}
      fullWidth
      maxWidth="xs"
      open={notNull(payNowOpen)}
      onClose={() => handlePayNowClose()}
      closeable
    >
      <DialogTitleContainer className="bg-secondary">
        <span>
          Job #{jobDetail.job_id}
        </span>
        <Button
          size="small"
          className="btn-transparent p-2 btn-transition-none"
          onClick={() => handlePayNowClose()}
        >
          <span className="btn-wrapper--icon">
            <ClearTwoToneIcon fontSize="small" />
          </span>
        </Button>
      </DialogTitleContainer>
      <DialogContentContainer className="p-0" dividers>
        <div className="dialog-content--wrapper bg-secondary border-0">
          <div className="card-body px-lg-4 py-lg-3">
            <div className="card-body--column w-100">
              <MyCardRow direction="column">
                <MyDescription>
                  <p className="d-flex justify-content-between" style={{ fontSize: 15, paddingRight: 14 }}><b>Fare </b>$ {convertCurrency(jobDetail.fare)}</p>
                  <p className="d-flex justify-content-between" style={{ fontSize: 15, paddingRight: 14 }}><b>Tip </b>$ {convertCurrency(jobDetail.tip)}</p>
                  <p className="d-flex justify-content-between" style={{ fontSize: 15, paddingRight: 14 }}><b>Total </b>$ {convertCurrency(parseFloat(jobDetail.fare) + parseFloat(jobDetail.tip))}</p>
                </MyDescription>
                {jobDetail?.payments && jobDetail?.payments.length > 0 && (
                  <>
                    <hr className="MuiDivider-root w-100 my-0" />
                    <MyDescription>
                      {jobDetail?.payments?.map((payment, index) => {
                        let payDescription = '';
                        if (payment.data_capture) {
                          const dataCapture = JSON.parse(payment.data_capture);
                          payDescription += dataCapture?.account_number ? `****${dataCapture?.account_number?.slice(-4)}` : '';
                        }
                        return (
                          <p key={index} className="d-flex justify-content-between" style={{ fontSize: 15, paddingRight: 14 }}><b>{payDescription} </b><span style={{ color: payment.amount > 0 ? 'red' : 'black', textDecoration: payment.status == -1 ? 'line-through' : 'none' }}>{`${payment.amount > 0 ? '-' : ''}`} $ {convertCurrency(Math.abs(payment.amount) * 100)}</span></p>
                        )
                      })}
                    </MyDescription>
                  </>
                )}
                <hr className="MuiDivider-root w-100 my-0" />
                <MyDescription>
                  <p className="d-flex justify-content-between" style={{ fontSize: 15, paddingRight: 14 }}><b>Balance </b>$ {convertCurrency(balance)}</p>
                </MyDescription>
              </MyCardRow>
              <MyCardRow>
                <MyDescription><b>Pay Amount</b></MyDescription>
                <MyInput
                  label=""
                  id="amount"
                  type="number"
                  values={formValues}
                  onChange={handleValueChange}
                  adornment={{ position: 'start', label: '$' }}
                  inputProps={{
                    style: { textAlign: 'right', fontSize: 15 }
                  }}
                  error={notNull(formErrors?.amount)}
                  helperText={formErrors?.amount}
                />
              </MyCardRow>
              <MyCardRow>
                <MyDescription><b>Pay Card</b></MyDescription>
                <MyButton
                  size="small"
                  type="neutral-primary"
                  onClick={() => handleAddCard(jobDetail.card_number ? 'Update' : 'Add')}
                  style={{ height: 50 }}
                >
                  <span className="btn-wrapper--label" style={{ fontSize: 13 }}>
                    <b className="d-flex align-items-center justify-content-center" style={{ fontSize: 14 }}><PaymentTwoToneIcon fontSize="small" className="mr-2" />{jobDetail.card_number ? `${creditCardType(jobDetail.card_number)}` : 'No Card on File'}</b>
                    {jobDetail.card_number ? `${jobDetail.card_number}` : 'Please add card to Pay'}
                  </span>
                </MyButton>
              </MyCardRow>

              <hr className="MuiDivider-root mt-3 mb-2" />
              <MyCardRow>
                <MyButton
                  type="warning"
                  onClick={handlePayNow}
                  disabled={!jobDetail.card_number || balance <= 0}
                >
                  <span className="btn-wrapper--icon">
                    <AttachMoneyTwoToneIcon fontSize="small" />
                  </span>
                  <span className="btn-wrapper--label">Confirm Pay!</span>
                </MyButton>
              </MyCardRow>
            </div>
          </div>
        </div>
      </DialogContentContainer>
    </Dialog>
  );
};

export default PayNowModal;
