/* eslint-disable import/prefer-default-export */
function checkInside(point, vs) {
  const x = point[0];
  const y = point[1];

  let inside = false;
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    const xi = vs[i][0];
    const yi = vs[i][1];
    const xj = vs[j][0];
    const yj = vs[j][1];

    const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) {
      inside = !inside;
    }
  }

  return inside;
}

export const getZone = (lonlat, zones) => {
  for (const zone of zones.geo) {
    const poly = zone.geo.map(coord => [coord.lng, coord.lat]);
    if (checkInside(lonlat, poly)) {
      return zone.name;
    }
  }
  return false;
};
