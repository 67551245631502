/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Checkbox,
  Button,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
} from '@material-ui/core';
import Select from 'react-select';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  DateTimePicker,
} from '@material-ui/pickers';

import { format } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EyeIcon from '@material-ui/icons/RemoveRedEyeTwoTone';
import OpenInNewTwoToneIcon from '@material-ui/icons/OpenInNewTwoTone';

import { PergoTextField } from 'components/PergoTextField/PergoTextField';
import { CircularProgressContext } from 'components/CircularProgressOverlay';

import { JobContext } from 'context/jobContextContainer';
import { CallContext } from 'context/callContextContainer';
import { jobsService } from 'services/jobsService';
import { getLoginUser } from 'services/loginService';

const moment = require('moment-timezone');

const headCells = [
  { id: 'job_id', disablePadding: true, label: 'Job #' },
  { id: 'driver', disablePadding: false, label: 'Driver' },
  { id: 'time', disablePadding: false, label: 'Reservation Time' },
  { id: 'passenger', disablePadding: false, label: 'Passenger' },
  { id: 'pickup', disablePadding: false, label: 'Pickup' },
  { id: 'dropoff', disablePadding: false, label: 'Drop Off' },
  { id: 'distance', disablePadding: false, label: 'Distance' },
  { id: 'payment_type', disablePadding: false, label: 'Payment Type' },
  { id: 'fare', disablePadding: false, label: 'Fare' },
  { id: 'status', disablePadding: false, label: 'Status' },
  { id: 'cancel', disablePadding: false, label: '' },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell key={headCell.id} align="center" sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const RouteViewTable = ({ editJob, confirmReservation, isUpdate }) => {
  const [tableData, setTableData] = useState([]);
  const [info, setInfo] = useState({ order: 'asc', orderBy: 'time' });
  const [assignDriverConfirm, setAssignDriverConfirm] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const { showCircularProgress, hideCircularProgress } = useContext(CircularProgressContext);
  const { driverList } = useContext(JobContext);
  const { siteSetting } = useContext(CallContext);
  const [reservationDate, setReservationDate] = useState(null);

  const paymentType = ['Cash', 'Card', 'Voucher', 'EHail Mobile', 'Karhoo', 'Stored Card', 'Webapp Credit'];

  const jobTableRowFormat = row => {
    const reservation_time = moment(row.reservation_time).isValid()
      ? moment
          .utc(row.reservation_time)
          .tz(siteSetting().timezone)
          .format(row.willcall_status == '1' ? 'MM/DD/YYYY' : 'MM/DD/YYYY HH:mm')
      : '';
    const distance = parseFloat(row.distance).toFixed(2);
    const payment_type = paymentType[row.tender_type];

    let status = '';
    let status_attr = '';
    row.status = parseInt(row.status);
    row.job_type = parseInt(row.job_type);

    switch (row.status) {
      case 1:
        status = row.job_type === 2 ? 'Reservation/Dispatched' : 'Dispatched';
        status_attr = 'dispatched';
        break;
      case 2:
        status = 'Accepted';
        status_attr = 'accepted';
        break;
      case 3:
        status = 'Declined';
        status_attr = 'declined';
        break;
      case 4:
        status = '@Pickup';
        status_attr = 'accepted';
        break;
      case 5:
        status = 'Picked Up';
        status_attr = 'pickup';
        break;
      case 6:
        status = 'Drop-Off';
        status_attr = 'dropoff';
        break;
      case 7:
        status = 'Pay';
        status_attr = 'pay';
        break;
      case 8:
        status = 'Complete';
        status_attr = 'complete';
        break;
      case 9:
        status = 'No Show';
        status_attr = 'cancel';
        break;
      case 10:
        status = 'Cancelled';
        status_attr = 'cancel';
        break;
      case 11:
        status = 'Backlog';
        status_attr = 'backlog';
        break;
      default:
        status = 'CFS';
        status_attr = 'cfs';
        break;
    }

    if (row.status === 0) {
      switch (row.job_type) {
        case 2:
          status = 'Reservation';
          break;
        case 3:
          status = 'E-Hail';
          break;
        case 4:
          status += ' (E-Hail)';
          break;
        default:
          break;
      }

      if (row.driver_id && parseInt(row.driver_id) > 0) {
        status_attr = 'assigned';
      }
    }

    return {
      ...row,
      job_id: row.id,
      time: reservation_time,
      passenger: `${row.passenger_fname} ${row.passenger_lname}`,
      driver_id: row.driver_id == 0 ? -1 : row.driver_id,
      pickup: row.pickup
        ? [row.pickup.location_name, row.pickup.addr1, row.pickup.city, row.pickup.state]
            .filter(str => str?.length > 0)
            .join(', ')
        : row.start_addr,
      dropoff: row.dropoff
        ? [row.dropoff.location_name, row.dropoff.addr1, row.dropoff.city, row.dropoff.state]
            .filter(str => str?.length > 0)
            .join(', ')
        : row.end_addr,
      distance,
      payment_type,
      fare: row.fare,
      status,
      status_attr,
      job_status: row.status,
    };
  };

  const processRouteViewData = tblData => {
    if (tblData.length === 0) {
      return tblData;
    }

    const result = [];
    let tmpResult = [tblData[0]];
    let count = 1;
    let tmpDriverId = tblData[0].driver_id;

    const sortCancelToEnd = rows => {
      const isCancelled = job => job.job_status == 3 || job.job_status == 10;
      rows.sort((a, b) =>
        (isCancelled(a) && isCancelled(b)) || (!isCancelled(a) && !isCancelled(b))
          ? 0
          : isCancelled(a) && !isCancelled(b)
          ? 1
          : -1
      );
      return rows;
    };

    for (let i = 1; i < tblData.length; i++) {
      if (tblData[i].driver_id == tblData[i - 1].driver_id) {
        count++;
      } else {
        result.push({
          isRouteView: true,
          job_count: count,
          driver_id: tmpDriverId,
        });
        tmpResult = sortCancelToEnd(tmpResult);
        result.push(...tmpResult);
        tmpResult = [];
        count = 1;
      }
      tmpResult.push(tblData[i]);
      tmpDriverId = tblData[i].driver_id;
    }
    if (tmpResult.length > 0) {
      result.push({
        isRouteView: true,
        job_count: count,
        driver_id: tmpDriverId,
      });
      tmpResult = sortCancelToEnd(tmpResult);
      result.push(...tmpResult);
    }
    return result;
  };

  const updateTable = async (reservationDateValue) => {
    // showCircularProgress('Processing');
    const data = await jobsService.get_routeview_jobs({
      order_by: info.orderBy,
      order: info.order,
      reservation_date:reservationDateValue
    },);
    // hideCircularProgress();
    if (data.data.status === 'SUCCESS') {
      let tblData = data.data.data.map(item => jobTableRowFormat(item));
      tblData = processRouteViewData(tblData);
      setTableData(tblData);
    }
  };

  const intervalFunc = (reservation) => {
    const seconds = moment().seconds();
    const milliseconds = moment().milliseconds();
    if (seconds % 20 === 0 && milliseconds > 900) {
      updateTable(reservation);
    }
  }
const setIntervalForJob=(reservation)=>{
  const id = setInterval(() => intervalFunc(reservation), 100);
  setIntervalId(id);
}
  useEffect(() => {
    updateTable(reservationDate);
    if (intervalId > 0) {
      clearInterval(intervalId);
    }
    setIntervalForJob(reservationDate);
    ()=>{
      if (intervalId > 0) {
        clearInterval(intervalId);
      }
      return;
    }

  }, [info, isUpdate]);



  const handleRequestSort = (event, property) => {
    const isAsc = info.orderBy === property && info.order === 'asc';
    setInfo({
      ...info,
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    });
  };

  const handleEditJob = jobId => {
    editJob(jobId);
  };
  
  const handleConfirmClick = (jobInfo, confirm) => {
    if (jobInfo) {
      confirmReservation([ jobInfo ], confirm);
    }
  };

  const onClickDriverAssign = async (jobId, driverId, index) => {
    const selDriver = driverList.filter(item => item.id === driverId);
    if (selDriver?.length > 0) {
      showCircularProgress('Assigning');
      const res = await jobsService.assign_driver_to_job(jobId, driverId);
      if (res && res?.data?.status === 'SUCCESS') {
        updateTable(reservationDate);
      }
      hideCircularProgress();
    }
  };

  const onChangeReservation = async (jobId, reservation_time, index) => {
    reservation_time = moment
      .tz(moment(reservation_time).format('YYYY-MM-DD HH:mm:ss'), siteSetting().timezone)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');

    showCircularProgress('Updating');
    const res = await jobsService.change_reservation_time(jobId, reservation_time);
    hideCircularProgress();
    if (res && res?.data?.status === 'SUCCESS') {
      updateTable(reservationDate);
    }
  };

  const handleDriverAssign = async () => {
    setAssignDriverConfirm(false);
    const { jobId, driverId } = assignDriverConfirm;
    showCircularProgress('Loading');
    const res = await jobsService.assign_driver_to_job(jobId, driverId);
    hideCircularProgress();
    if (res && res?.data?.status === 'SUCCESS') {
      updateTable(reservationDate);
    }
  };

  const driverIdToOption = driverId => {
    if (driverId < 0) {
      return {
        value: -1,
        label: '',
      };
    }
    const selDriver = driverList.filter(item => item.id == driverId);
    if (selDriver.length > 0) {
      return {
        value: selDriver[0].value,
        label: selDriver[0].label,
      };
    }
    return {
      value: -1,
      label: '',
    };
  };
  const resetFilter=async ()=>{
    setReservationDate(null);
   await updateTable(null);
    setIntervalForJob(null);
  }

  return (
    <TableContainer>
      <TableSearch>

      <div className="reservation-table-search--container">
       <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          inputVariant="outlined"
          format="yyyy/MM/dd"
          ampm
          label="Reservation Date"
          value={reservationDate ? moment(reservationDate).format('MM/DD/YYYY HH:mm') : null}
          onChange={e => {
            setReservationDate(format(e, 'yyy-MM-dd'))
          }}
        />
      </MuiPickersUtilsProvider>
      <Button className="reservation-table-search--button" onClick={()=> {    
         clearInterval(intervalId);
         updateTable(reservationDate)}} >
        Search
      </Button>
      <Button className="reservation-table-search--button" onClick={resetFilter} >
        Reset
      </Button>
    </div>
    </TableSearch>
      
      <Table aria-label="job table">
        <EnhancedTableHead
          order={info.order}
          orderBy={info.orderBy}
          onRequestSort={handleRequestSort}
        />

        <TableBody>
          {tableData.map((row, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;

            if (row.isRouteView) {
              return (
                <TableRow className="reservation-route-view-row">
                  <TableCell colSpan={11}>
                    {row.driver_id > 0 ? driverIdToOption(row.driver_id).label : 'Unassigned'} / Job Count :{' '}
                    {row.job_count}
                  </TableCell>
                </TableRow>
              );
            }

            return (
              <TableRow
                className={`job-status-${row.status_attr}`}
                hover
                role="checkbox"
                tabIndex={-1}
                key={`${row.job_id}_${index}`}
              >
                <TableCell
                  align="center"
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="none"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleEditJob(row.job_id)}
                >
                  {row.job_id}
                </TableCell>
                <TableCell align="center">
                  {row.willcall_status == '1' ? (
                    <></>
                  ) : row.job_status >= 2 ? (
                    row.driver_id > 0 ? driverIdToOption(parseInt(row.driver_id)).label : ''
                  ) : (
                    <DriverSelect
                      classNamePrefix="driver-select"
                      value={driverIdToOption(parseInt(row.driver_id))}
                      onChange={selectedOption => onClickDriverAssign(row.job_id, selectedOption.value, index)}
                      options={
                        driverList?.length > 0
                          ? driverList.map(item => ({
                              value: item.id,
                              label: item.label,
                            }))
                          : []
                      }
                    />
                  )}
                </TableCell>
                {/* <TableCell align="center">
                  {row.time}
                  {row.willcall_status == '1' ? <span style={{ color: 'red' }}>&nbsp;(Will Call)</span> : ''}
                </TableCell> */}
                <TableCell
                  align="center"
                  className={`col-reservation-time ${row.willcall_status == '1' ? 'willcall-time' : ''}`}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      format={row.willcall_status == '1' ? 'yyyy/MM/dd ??:??' : 'yyyy/MM/dd HH:mm'}
                      value={row.time}
                      onChange={e => onChangeReservation(row.job_id, e, index)}
                    />
                  </MuiPickersUtilsProvider>
                </TableCell>
                <TableCell align="center">{row.passenger}</TableCell>
                <TableCell align="center">{row.pickup}</TableCell>
                <TableCell align="center">{row.dropoff}</TableCell>
                <TableCell align="center">{row.distance}</TableCell>
                <TableCell align="center">
                  {row.payment_type}
                  {parseFloat(row.booking_deposit_amount) > 0 && row.booking_deposit_status == 0 ? (
                    <>
                      <br />
                      <span style={{ color: 'red' }}>(Balance Due)</span>
                    </>
                  ) : (
                    <></>
                  )}
                  {parseFloat(row.booking_deposit_amount) > 0 && (
                    <div className="col-redirect-icon">
                      <a
                        href={`/invoice?token=${siteSetting()?.settings?.reservation_token}&job_id=${row.job_id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <OpenInNewTwoToneIcon fontSize="small" />
                      </a>
                    </div>
                  )}
                </TableCell>
                <TableCell align="center">{row.fare}</TableCell>
                {row.status === 'Reservation' && (row.reservation_confirmed == 0 || row.reservation_confirmed == 3) ? (
                  <TableCell align="center" className="text-warning">
                    Confirmed
                    <br />
                    Reservation?
                  </TableCell>
                ) : (
                  <TableCell align="center">
                    {row.status}
                    {row.driver_id <= 0 && row.status < 2 ? (
                      <>
                        <br />
                        No Driver's Assigned
                      </>
                    ) : (
                      ''
                    )}
                    {row.status === 'No Show' && (
                      <EyeIcon
                        color="primary"
                        style={{ fontSize: 20, marginLeft: 5, marginBottom: 3, cursor: 'pointer' }}
                      />
                    )}
                  </TableCell>
                )}
                <TableCell align="center">
                  {row.job_status === 3 || row.job_status >= 8 ? (
                    ''
                  ) : (
                    <>
                      {row.status === 'Reservation' &&
                        (row.reservation_confirmed == 0 || row.reservation_confirmed == 3) && (
                          <Button
                            className="job-table--action-icon color-success m-1"
                            variant="contained"
                            onClick={() => handleConfirmClick(row, 'confirm')}
                          >
                            <CheckIcon fontSize="small" />
                          </Button>
                        )}
                      <Button
                        className="job-table--action-icon m-1"
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                          handleConfirmClick(
                            row,
                            row.status === 'Reservation' &&
                              (row.reservation_confirmed == 0 || row.reservation_confirmed == 3)
                              ? 'decline'
                              : 'cancel'
                          )
                        }
                      >
                        <CloseIcon fontSize="small" />
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Dialog open={!!assignDriverConfirm} onClose={() => setAssignDriverConfirm(false)}>
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Do you really want to assign ${assignDriverConfirm?.driverLabel} driver to Job #${assignDriverConfirm?.jobId}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDriverAssign} color="primary" autoFocus>
            Yes
          </Button>
          <Button onClick={() => setAssignDriverConfirm(false)} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
};

export default RouteViewTable;

const DriverSelect = styled(Select)`
  min-width: 150px;

  .driver-select__control {
    border: none;
    border-bottom: 1px solid #888;
    border-radius: 0;
    background: transparent;
    text-align: left;
    box-shadow: none;
    font-size: 13px;
    min-height: 0;
  }

  .driver-select__indicator {
    padding: 3px 5px;
  }

  .driver-select__menu {
    text-align: left;
    min-width: 200px;
  }
   
`;
const TableSearch = styled.div`
 display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
.reservation-table-search--container {
    padding-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    .reservation-table-search--button,
    .reservation-table-print--button {
    margin-left:15px;
      border-radius: 0;
      background: var(--blue-main-color);
      color: white;
      height: 39px;
    }
}`;