import React, { useEffect, useState } from 'react';
import {
  Button,
  DialogContent,
  Snackbar
} from '@material-ui/core';
import styled from 'styled-components';
import PhoneTwoToneIcon from '@material-ui/icons/PhoneTwoTone';

const OutcallModal = ({ callInfo, handleClose }) => {
  const [isUpdate, setUpdate] = useState(false);

  const onAccept = () => {
    // console.log('Out Call Accepted:');
    setUpdate(!isUpdate);
  }

  const onDisconnect = () => {
    // console.log('Out Call Disconnect:');
    setUpdate(!isUpdate);
    handleClose();
  }

  const onCancelled = () => {
    // console.log('Out Call Cancelled:');
    setUpdate(!isUpdate);
  }

  useEffect(() => {
    if (callInfo?.outCall) {
      // console.log({ outCall: callInfo?.outCall })
      callInfo.outCall.on('accept', onAccept);
      callInfo.outCall.on('disconnect', onDisconnect);
      callInfo.outCall.on('cancel', onCancelled);
    }
  }, [callInfo]);

  const handleHangup = () => {
    callInfo?.outCall.disconnect();
    handleClose();
  }

  if (!callInfo) {
    return <></>;
  }

  return (
    <Snackbar
      open={callInfo}
      autoHideDuration={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
    >
      <OutcallModalContainer>
        <div className="outcall--header">
          <h3>{callInfo?.info?.name}</h3>
          <p>{callInfo?.info?.phone}</p>
        </div>
        <div className="outcall--footer">
          <Button onClick={handleHangup} color="primary">
            <PhoneTwoToneIcon fontSize='big' />
          </Button>
        </div>
      </OutcallModalContainer>
    </Snackbar>
  )
};

export default OutcallModal;

const OutcallModalContainer = styled(DialogContent)`
  padding: 30px !important;
  width: 300px;
  max-width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--neutral-success);
  border: 2px solid var(--primary);
  box-shadow: 4px 4px 4px #00000099;
  border-radius: 5px;

  .outcall--header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px 0;

    p {
      margin: 0;
      font-size: 24px;
    }
  }

  .outcall--footer {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      background-color: red;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      min-width: 0px;

      svg {
        color: white;
      }
    }
  }

  @media (max-width: 576px) {
    height: 100%;
    gap: 20px 0;
    padding: 20px !important;
    flex-direction: row;

    .outcall--header {
      h3 {
        font-size: 24px;
      }
      p {
        font-size: 20px;
      }
    }

    .outcall--footer {
      button {
        width: 50px;
        height: 50px;
      }
    }
  }
`