/* eslint-disable arrow-body-style */
import React, { useContext, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import ReactMapboxGl, { Marker, Layer, Feature } from 'react-mapbox-gl';
import { CallContext } from 'context/callContextContainer';
import { PergoTitle } from '../PergoTitle';
import AsyncMap from './AsyncMap';
import { MapContext } from '../../context/mapContext/mapContextContainer';
import RedIcon from '../../images/marker_red.png';
import BlueIcon from '../../images/marker_blue.png';
import GreenIcon from '../../images/marker_green.png';
import TmpMapBack from '../../images/tmp-map.png';

const Map = typeof window !== 'undefined' &&
  ReactMapboxGl({
    accessToken: process.env.MAPBOX_ACCESS_TOKEN,
  });

const MapSection = ({ mapType }) => {
  const [directions, setDirections] = useState(null);
  const { mapData, setMapData } = useContext(MapContext);
  const [fitBounds, setFitBounds] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);
  const { siteSetting } = useContext(CallContext);

  async function getDirections() {
    const Location1Str = `${mapData.pickup_lng},${mapData.pickup_lat}`;
    const Location2Str = `${mapData.dropoff_lng},${mapData.dropoff_lat}`;
    const apiUrl = `https://api.mapbox.com/directions/v5/mapbox/driving/${Location1Str};${Location2Str}?access_token=${process.env.MAPBOX_ACCESS_TOKEN}&alternatives=false&geometries=geojson&steps=true`;
    const fetchResult = await fetch(apiUrl);
    const Result = await fetchResult.json();

    let lat;
    let lon;
    let deadhead_distance = 0;
    if (!siteSetting() || siteSetting().lat == '0' || siteSetting().lon == '0') {
      lat = 0;
      lon = 0;
    } else {
      lat = siteSetting().lat / 1000000.0;
      lon = siteSetting().lon / 1000000.0;
    }

    if (lat != 0 && lon != 0) {
      const LocationBaseStr = `${lon},${lat}`;
      const apiUrl1 = `https://api.mapbox.com/directions/v5/mapbox/driving/${Location1Str};${LocationBaseStr}?access_token=${process.env.MAPBOX_ACCESS_TOKEN}&alternatives=false&geometries=geojson&steps=true`;
      const fetchResult1 = await fetch(apiUrl1);
      const Result1 = await fetchResult1.json();

      if (Result1.routes && Result1.routes[0]) {
        deadhead_distance = parseFloat(Number(Result1.routes[0].distance / 1609.34).toFixed(2));
      }
    }

    if (Result.routes && Result.routes[0]) {
      const { distance, duration } = Result.routes[0];
      setMapData({
        ...mapData,
        distance: parseFloat(Number(distance / 1609.34).toFixed(2)),
        duration: parseFloat(Number(duration / 60).toFixed(2)),
        ...(deadhead_distance > 0 && { deadhead_distance })
      });
      
      setDirections([ ...Result.routes[0].geometry.coordinates ]);
    }
  }
/*
  useEffect(() => {
    let lat = 0;
    let lon = 0;
    if (!siteSetting() || siteSetting().lat == '0' || siteSetting().lon == '0') {
      lat = 40.907147;
      lon = -73.872336;
    } else {
      lat = siteSetting().lat / 1000000.0;
      lon = siteSetting().lon / 1000000.0;
    }
    
    setMapCenter([parseFloat(lon), parseFloat(lat)]);
  }, []);
*/
  useEffect(() => {
    if (
      mapType === 'GOOGLE' ||
      (mapData.pickup_lat === 0 && mapData.pickup_lng === 0) ||
      (mapData.dropoff_lat === 0 && mapData.dropoff_lng === 0)
    ) {
      setDirections([]);
      return;
    }

    getDirections();
  }, [mapData.pickup_lat, mapData.pickup_lng, mapData.dropoff_lat, mapData.dropoff_lng]);

  useEffect(() => {
    if (mapType === 'GOOGLE') {
      return;
    }

    if (mapData.pickup_lat + mapData.pickup_lng + mapData.dropoff_lat + mapData.dropoff_lng == 0) {
      return;
    }

    if (mapData.pickup_lat + mapData.pickup_lng !== 0 && mapData.dropoff_lat + mapData.dropoff_lng === 0) {
      setMapCenter([parseFloat(mapData.pickup_lng), parseFloat(mapData.pickup_lat)]);
      setFitBounds(null);
    } else if (mapData.pickup_lat + mapData.pickup_lng === 0 && mapData.dropoff_lat + mapData.dropoff_lng !== 0) {
      setMapCenter([parseFloat(mapData.dropoff_lng), parseFloat(mapData.dropoff_lat)]);
      setFitBounds(null);
    } else if (mapData.driver_lat === 0 && mapData.driver_lng === 0) {
      setFitBounds([
        [parseFloat(mapData.pickup_lng), parseFloat(mapData.pickup_lat)],
        [parseFloat(mapData.dropoff_lng), parseFloat(mapData.dropoff_lat)],
      ]);
    } else {
      setFitBounds([
        [
          Math.min(
            parseFloat(mapData.pickup_lng),
            parseFloat(mapData.dropoff_lng),
            parseFloat(mapData.driver_lng)
          ),
          Math.min(
            parseFloat(mapData.pickup_lat),
            parseFloat(mapData.dropoff_lat),
            parseFloat(mapData.driver_lat)
          ),
        ],
        [
          Math.max(
            parseFloat(mapData.pickup_lng),
            parseFloat(mapData.dropoff_lng),
            parseFloat(mapData.driver_lng)
          ),
          Math.max(
            parseFloat(mapData.pickup_lat),
            parseFloat(mapData.dropoff_lat),
            parseFloat(mapData.driver_lat)
          ),
        ],
      ]);
    }
  }, [
    mapData.pickup_lat,
    mapData.pickup_lng,
    mapData.dropoff_lat,
    mapData.dropoff_lng,
    mapData.driver_lat,
    mapData.driver_lng,
  ]);

  const mapContent = useMemo(() => {
    return (
      <div className="main--map-body" style={{ backgroundImage: `url(${TmpMapBack})` }}>
        {mapType === 'GOOGLE' && (
          <AsyncMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${siteSetting().settings.google_maps_api_key}&v=3.exp&&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '100%' }} />}
            mapElement={<div style={{ height: '100%' }} />}
          />
        )}
        {mapType === 'MAPBOX' &&
          (mapData.pickup_lat !== 0 || mapData.pickup_lng !== 0 || mapData.dropoff_lat !== 0 || mapData.dropoff_lng !== 0) && (mapCenter || fitBounds) && (
            <Map
              style="mapbox://styles/mapbox/streets-v9"
              fitBoundsOptions={{
                padding: { top: 80, bottom: 80, left: 80, right: 80 },
              }}
              containerStyle={{
                height: '100%',
                width: '100%',
              }}
              { ...(fitBounds && { fitBounds }) }
              { ...(mapCenter && { center: mapCenter }) }
            >
              {!(mapData.pickup_lat === 0 && mapData.pickup_lng === 0) && (
                <Marker coordinates={[mapData.pickup_lng, mapData.pickup_lat]} anchor="bottom">
                  <img src={RedIcon} alt='map-pickup' />
                </Marker>
              )}
              {!(mapData.dropoff_lat === 0 && mapData.dropoff_lng === 0) && (
                <Marker coordinates={[mapData.dropoff_lng, mapData.dropoff_lat]} anchor="bottom">
                  <img src={BlueIcon} alt='map-dropoff' />
                </Marker>
              )}
              {!(mapData.driver_lat === 0 && mapData.driver_lng === 0) && (
                <Marker coordinates={[mapData.driver_lng, mapData.driver_lat]} anchor="bottom">
                  <img src={GreenIcon} alt='map-vehicle' />
                </Marker>
              )}
              {directions?.length > 0 && (
                <Layer
                  type="line"
                  layout={{ 'line-cap': 'round', 'line-join': 'round' }}
                  paint={{ 'line-color': '#4790E5', 'line-width': 12 }}
                >
                  <Feature coordinates={directions} />
                </Layer>
              )}
            </Map>
          )}
        <div className="map_legend">
          <div className="map_icon_container">
            <img
              alt=""
              className="map_icon"
              src="https://maps.google.com/mapfiles/ms/icons/red.png"
            />
            <p>Pickup</p>
          </div>
          <div className="map_icon_container">
            <img
              alt=""
              className="map_icon"
              src="https://maps.google.com/mapfiles/ms/icons/blue.png"
            />
            <p>Dropoff</p>
          </div>
          <div className="map_icon_container">
            <img
              alt=""
              className="map_icon"
              src="https://maps.google.com/mapfiles/ms/icons/green.png"
            />
            <p>Vehicle</p>
          </div>
        </div>
      </div>
    )
  }, [
    mapData.pickup_lat,
    mapData.pickup_lng,
    mapData.dropoff_lat,
    mapData.dropoff_lng,
    mapData.driver_lat,
    mapData.driver_lng,
    directions,
    fitBounds,
    mapCenter,
    mapType,
  ])

  return (
    <MapSectionWrapper>
      <Paper className="main--map-section" elevation={3} square>
        <PergoTitle className="main--map-title">Job Map</PergoTitle>
        {mapContent}
      </Paper>
    </MapSectionWrapper>
  );
};

export default React.memo(MapSection);

const MapSectionWrapper = styled.div`
  flex: auto;
  display: flex;

  .main--map-section {
    flex: auto;
    margin-right: 10px;
    border: 2px solid var(--green-main-color);
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .main--map-title {
      background: var(--green-main-color);
      font-size: 16px;
      padding: 15px 10px;
    }

    .main--map-body {
      height: 100%;
      position: relative;
      background-position: center;
      background-size: cover;

      .map_legend {
        width: 100px;
        height: 120px;
        background-color: white;
        position: absolute;
        bottom: 20px;
        right: 20px;
        border: 1px solid black;
        display: flex;
        flex-direction: column;

        .map_icon_container {
          display: flex;
          align-items: center;
          flex: 1;

          .map_icon {
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 8px;
          }

          p {
            font-size: 14px;
            font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }

  @media (max-width: 1299px) {
    flex-direction: column;

    .main--map-section {
      margin-right: 0;
    }

    .main--driver-section {
      max-width: 100%;
      max-height: 160px;
    }
  }
  @media (max-width: 830px) {
    .main--map-section {
      height: 400px;
    }

    .main--driver-section {
      .main--driver-body {
      }
    }
  }
`;
