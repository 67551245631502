import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { PergoTextField } from 'components/PergoTextField/PergoTextField';
import { CircularProgressContext } from 'components/CircularProgressOverlay';
import { MapContext } from 'context/mapContext/mapContextContainer';
import { CallContext } from 'context/callContextContainer';
import { driversService } from 'services/driversService';

import JobSectionContent from './JobSectionContent';

const Step3 = ({ globalValues, onClickNext, onClickPrev, isCurrentStep, isCallCenter }) => {
  const [isUpdate, setUpdate] = useState(false);
  const [values, setValues] = useState({});
  const [selDriver, setSelDriver] = useState(-1);
  const { mapData } = useContext(MapContext);
  const { siteSetting, callcenterActiveDb } = useContext(CallContext);

  const [formValid, setFormValid] = useState({
    driver_id: 0,
  });

  const [formErrors, setFormErrors] = useState({
    driver_id: '',
  });

  const [drivers, setDrivers] = useState([]);
  const { showCircularProgress, hideCircularProgress } = useContext(CircularProgressContext);

  useEffect(() => {
    async function initializeStep() {
      setValues({
        ...values,
        driver_id: globalValues.driver_id,
        vehicle_id: globalValues.vehicle_id,
        payments: globalValues.payments,
      });

      showCircularProgress('Loading');
      const data = await driversService.get_drivers_by_method({
        job_info: JSON.stringify({
          start_lat: mapData.pickup_lat * 1000000,
          start_lon: mapData.pickup_lng * 1000000,
          vehicle_type: globalValues.vehicle_type,
          driver_id: 0,
          job_type: 1,
        }),
        ...(isCallCenter && { dbname: callcenterActiveDb }),
      });

      hideCircularProgress();
      if (data && data.data.status === 'SUCCESS' && data.data.data.num_rows >= 1) {
        setDrivers(data.data.data.data);

        const tmp = data.data.data.data;
        if (tmp?.length > 0 && tmp[0]?.drivers?.length > 0) {
          onHandleChange('driver_id', tmp[0].drivers[0]);
        }
      }
    }

    if (isCurrentStep) {
      initializeStep();
    }
  }, [isCurrentStep]);

  const onHandleChange = (id, newValue) => {
    const tmpValues = { ...values };

    if (id === 'driver_id') {
      tmpValues.driver_id = newValue === -1 ? -1 : newValue.driver_id || newValue.driverId;
      tmpValues.vehicle_id = newValue === -1 ? -1 : newValue.vehicle_id || newValue.vehicleId;
      setSelDriver(newValue);
    } else {
      tmpValues[id] = newValue;
    }

    setValues(tmpValues);
  };

  const checkFieldValidate = (id, value) => {
    const fieldValidation = formValid;
    const fieldValidationErrors = formErrors;

    fieldValidation[id] = 1;
    if (id === 'driver_id') {
      fieldValidation[id] = value > 0 ? 1 : -1;
      fieldValidationErrors[id] = fieldValidation[id] === 1 ? '' : 'Please select driver';
    }

    setFormValid(fieldValidation);
    setFormErrors(fieldValidationErrors);
    setUpdate(!isUpdate);

    return fieldValidation[id];
  };

  const checkStepValidate = () => {
    let stepValid = 0;
    let stepFields = [];

    stepFields = ['driver_id'];

    for (var c in stepFields) {
      const element = stepFields[c];
      stepValid += checkFieldValidate(element, values[element]);
    }
    const formValidCount = stepValid === stepFields.length;
    return formValidCount;
  };

  const onNextStep = () => {
    onClickNext(values);
  };

  return (
    <StepContent
      title="Dispatch"
      prevButton={{
        onClick: onClickPrev,
        label: 'Back',
      }}
      middleButton={{
        onClick: onNextStep,
        label: 'Create Dispatch',
      }}
    >
      <PergoTextField label="Distance (Miles)" value={`${globalValues.distance} miles`} readonly />
      <div className="form-control-section mt-4">
        {drivers?.length > 0 ? (
          drivers.map((item, index) => (
            <FormControl variant="outlined" {...(formValid && { error: formValid.driver_id === -1 })} key={index}>
              <InputLabel>{`Select Drivers (${item.driver_method.desc})`}</InputLabel>
              <Select value={selDriver} onChange={e => onHandleChange('driver_id', e.target.value)}>
                {item.drivers &&
                  item.drivers.map((item1, index1) => (
                    <MenuItem value={item1} key={index1}>
                      {item1.desc}
                    </MenuItem>
                  ))}
                {siteSetting()?.settings?.backlog == 1 && <MenuItem value={0}>Backlog</MenuItem>}
                <MenuItem value={-1}>Dispatch to CFS Status</MenuItem>
              </Select>
            </FormControl>
          ))
        ) : (
          <FormControl variant="outlined" {...(formValid && { error: formValid.driver_id === -1 })}>
            <InputLabel>Select Drivers</InputLabel>
            <Select value={selDriver} onChange={e => onHandleChange('driver_id', e.target.value)}>
              {siteSetting()?.settings?.backlog == 1 && <MenuItem value={0}>Backlog</MenuItem>}
              <MenuItem value={-1}>Dispatch to CFS Status</MenuItem>
            </Select>
          </FormControl>
        )}
      </div>
    </StepContent>
  );
};

export default Step3;

const StepContent = styled(JobSectionContent)``;
