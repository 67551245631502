/* eslint-disable no-use-before-define */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { Device } from '@twilio/voice-sdk';

import { Paper, Grid, Switch, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ConfirmModal from 'components/ConfirmModal';

import PhoneTwoToneIcon from '@material-ui/icons/PhoneTwoTone';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';

import { JobContext } from 'context/jobContextContainer';
import { CallContext } from 'context/callContextContainer';
import { twilioService } from 'services/twilioService';
import { siteSetting } from 'services/loginService';
import { callcenterService } from 'services/callcenterService';
import { cleanPhone, phoneFormat, clearCurrency } from 'utils/text';
import OutcallModal from './OutcallModal';
import { PergoDescription } from '../PergoTitle';

const CallSection = ({ isCallCenter, callBase }) => {
  const [twilioOn, setTwilioOn] = React.useState(true);
  const [twilioData, setTwilioData] = useState(null);
  const [isIdle, setIdle] = useState(true);
  const [takingCall, setTakingCall] = useState(false);
  const [callQueue, setCallQueue] = useState(0);
  const [isUpdate, setUpdate] = useState(false);
  const { editJobId, setIncomingNumber, outgoingCall, exitJobEditing, setExitJobEditing } = useContext(JobContext);
  const {
    isCenterOnCall,
    isCenerTakingCall,
    setCenterTakingCall,
    callcenterActiveDb,
    handlePickCall,
    handleHangupCall,
    handleSetActiveDb,
  } = useContext(CallContext);
  const [outgoingCallConfirm, setOutgoingCallConfirm] = useState(false);
  const [showOutCallModal, setShowOutCallModal] = useState(false);
  const [showCancelJobModal, setShowCancelJobModal] = useState(false);
  const isActive = isCallCenter || siteSetting().balance > 10.0;

  const handleStateChange = event => {
    setTwilioOn(event.target.checked);
  };

  const handleGoToReload = () => {
    navigate('/manage/settings/stripe/bills/');
  };

  async function getCallQueue() {
    const data = isCallCenter
      ? await callcenterService.get_call_queue({ dbname: callBase?.dbname })
      : await twilioService.get_call_queue();
    if (data.data.status === 'SUCCESS') {
      setCallQueue(data.data.data?.currentSize || 0);
    }
  }

  useEffect(() => {
    async function takeCall() {
      setCenterTakingCall(true);
      // console.log({ twilioOn, isIdle, callQueue })
      if (twilioOn && isIdle && !takingCall && callQueue > 0) {
        console.log('Take Call');
        setTakingCall(true);
        if (isCallCenter) {
          await callcenterService.take_voice_call({ dbname: callBase?.dbname });
        } else {
          await twilioService.take_voice_call();
        }
        setTakingCall(false);
        /*
        if (data.status === 'SUCCESS' && data.data) {
          setIdle(false);
        }
        */
      }
      setTimeout(() => setCenterTakingCall(false), 50);
    }

    // console.log({ isCenterOnCall, isCenerTakingCall })
    if (!isCallCenter || (!isCenterOnCall && !isCenerTakingCall && editJobId === 0)) {
      takeCall();
    }
  }, [callQueue, isIdle, twilioOn, isCenterOnCall, editJobId]);

  useEffect(() => {
    const interval = setInterval(() => {
      getCallQueue();
    }, 1000 * 5);

    return () => clearInterval(interval);
  }, []);

  async function getTwilioVoiceToken() {
    const data = isCallCenter
      ? await callcenterService.get_voice_token({ dbname: callBase?.dbname })
      : await twilioService.get_voice_token();
    if (data && data.data.status === 'SUCCESS') {
      const device = new Device(data.data.data.token, {
        codecPreferences: ['opus', 'pcmu'],
        fakeLocalDTMF: true,
        sounds: {
          incoming: 'sound/incoming.mp3',
        },
      });

      console.log('Device is created');
      device.on('registered', handleRegistration);
      device.register();

      device.on('ready', handleReady);
      device.on('incoming', handleIncoming);
      device.on('error', handleError);
      setTwilioData({ device });
    }
  }

  useEffect(() => {
    getTwilioVoiceToken();
  }, []);

  useEffect(() => {
    if (outgoingCall && twilioData.device) {
      setOutgoingCallConfirm(true);
    }
  }, [outgoingCall]);

  useEffect(() => {
    setUpdate(!isUpdate);
  }, [callcenterActiveDb]);

  const handleClickPanel = () => {
    console.log({ editJobId });
    if (isCallCenter && twilioOn) {
      if (editJobId > 0) {
        setShowCancelJobModal(true);
      } else {
        handleChangeActiveDb();
      }
    }
  };

  const handleChangeActiveDb = () => {
    if (isCallCenter && twilioOn) {
      handleSetActiveDb(callBase.dbname);
      setExitJobEditing(!exitJobEditing);
      setTimeout(() => setUpdate(!isUpdate), 100);
    }
    if (showCancelJobModal) {
      setShowCancelJobModal(false);
    }
  };

  const handleRegistration = () => {
    // console.log('The device is ready to receive incoming calls.')
  };

  const handleReady = () => {
    // console.log('Twilio device ready:')
  };

  const handleIncoming = call => {
    console.log('Incoming call:');
    // console.log({call})

    if (isCallCenter) {
      handlePickCall(callBase.dbname);
    }

    call.on('reject', () => onReject());
    call.on('disconnect', () => onDisconnect());
    call.on('cancel', () => onCancelled());
    setTwilioData(prevData => ({ ...prevData, call }));
    setTakingCall(false);

    setUpdate(!isUpdate);
  };

  const handleError = (twilioError, call) => {
    console.log('An error has occurred: ', twilioError);
    setUpdate(!isUpdate);
  };

  const onReject = () => {
    console.log('Call Rejected:');
    setTwilioData({
      call: null,
    });
    setIncomingNumber(null);
    setIdle(true);
    if (isCallCenter) {
      handleHangupCall(callBase.dbname);
    }
    setUpdate(!isUpdate);
  };

  const onDisconnect = () => {
    console.log('Call Disconnect:');
    setTwilioData({
      call: null,
    });
    setIncomingNumber(null);
    setIdle(true);
    if (isCallCenter) {
      handleHangupCall(callBase.dbname);
    }
    setUpdate(!isUpdate);
  };

  const onCancelled = () => {
    console.log('Call Cancelled:');
    setTwilioData({
      call: null,
    });
    setIncomingNumber(null);
    setIdle(true);
    if (isCallCenter) {
      handleHangupCall(callBase.dbname);
    }
    setUpdate(!isUpdate);
  };

  const handleAccept = e => {
    e.stopPropagation();
    if (twilioData?.call) {
      twilioData.call.accept();
      setIdle(false);
      if (twilioData.call.parameters?.From) {
        setIncomingNumber(cleanPhone(twilioData.call.parameters?.From));
      }
    }
    setUpdate(!isUpdate);
  };

  const handleReject = e => {
    e.stopPropagation();
    if (twilioData?.call) {
      if (twilioData.call.status() === 'pending') {
        twilioData.call.reject();
      } else {
        twilioData.call.disconnect();
      }
    }
    setIncomingNumber(null);
    setUpdate(!isUpdate);
  };

  const handleMakeOutgoingCall = async () => {
    setOutgoingCallConfirm(false);

    if (twilioData.device) {
      const outCall = await twilioData.device.connect({
        params: {
          To: `+1${cleanPhone(outgoingCall.phone)}`,
        },
      });
      if (outCall) {
        setTwilioData(prevData => ({ ...prevData, outCall }));
        setShowOutCallModal({
          outCall,
          info: outgoingCall,
        });
      }
    }
  };

  const twilioOnOffSwitch = () => (
    <div className="call-section--status">
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>
          <FormControlLabel
            control={<PurpleSwitch size="small" checked={twilioOn} onChange={handleStateChange} value="checkedA" />}
            label={twilioOn ? 'ON' : 'OFF'}
            onClick={e => e.stopPropagation()}
          />
        </Grid>
      </Grid>
    </div>
  );

  const twilioSettings = callBase?.twilio_settings ? JSON.parse(callBase?.twilio_settings) : {};
  const twilioCallStatus = twilioData?.call?.status();
  const panelStatusClass =
    `${isActive && twilioOn ? 'call-section--on' : 'call-section--off'} ` +
    `${isCallCenter && callcenterActiveDb === callBase?.dbname ? 'call-section--focused' : ''} ` +
    `${twilioCallStatus === 'pending' || twilioCallStatus === 'connecting' ? 'call-section--calling' : ''} `;

  return (
    <CallSectionWrapper>
      <Paper className={`call-section--section ${panelStatusClass}`} elevation={3} square onClick={handleClickPanel}>
        {isCallCenter && (
          <div className="call-section--header">
            <PergoDescription className="call-section--title">{callBase?.site?.base_name || ''}</PergoDescription>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0 10px', width: '200px' }}>
              <PergoDescription className="call-section--title">
                {phoneFormat(cleanPhone(twilioSettings.twilio_phone_number)) || ''}
              </PergoDescription>
              {twilioOnOffSwitch()}
            </div>
          </div>
        )}
        <div className="call-section--body">
          <PergoDescription className="call-section--title" fontSize={isCallCenter ? '14px' : '20px'}>
            Call Queue: {callQueue}
          </PergoDescription>
          {twilioData?.call && (
            <div className="call-section--call-control">
              <p className={`${twilioData?.call?.status() === 'pending' ? 'heartbeat' : ''}`}>
                From: {phoneFormat(cleanPhone(twilioData?.call?.parameters?.From)) || ''}
              </p>
              <div>
                {twilioData?.call?.status() === 'pending' && (
                  <Button
                    size="small"
                    className="btn-success mr-2 px-1 py-2 btn-transition-none"
                    onClick={handleAccept}
                  >
                    <span className="btn-wrapper--icon">
                      <PhoneTwoToneIcon fontSize="small" />
                    </span>
                    <span className="btn-wrapper--label">Accept</span>
                  </Button>
                )}
                <Button size="small" className="btn-danger px-1 py-2 btn-transition-none" onClick={handleReject}>
                  <span className="btn-wrapper--icon">
                    <CloseTwoToneIcon fontSize="small" />
                  </span>
                  <span className="btn-wrapper--label">
                    {twilioData?.call?.status() === 'pending' ? 'Reject' : 'Disconnect'}
                  </span>
                </Button>
              </div>
            </div>
          )}

          {!isActive ? (
            <div className="call-section--inactive">
              <p>Insufficient Funds: {clearCurrency(siteSetting().balance)}</p>
              <Button size="small" className="btn-warning px-4 py-2 btn-transition-none" onClick={handleGoToReload}>
                <span className="btn-wrapper--label">Go To Reload</span>
              </Button>
            </div>
          ) : !isCallCenter ? (
            twilioOnOffSwitch()
          ) : (
            <></>
          )}
        </div>
      </Paper>
      <ConfirmModal
        title="Calling Passenger"
        body={`Do you really want to call ${outgoingCall?.phone}?`}
        open={outgoingCallConfirm}
        handleYes={() => handleMakeOutgoingCall()}
        handleNo={() => setOutgoingCallConfirm(false)}
      />
      {isCallCenter && (
        <ConfirmModal
          title="Cancel Job Edit"
          body="Do you really want to cancel job editing without saving?"
          open={showCancelJobModal}
          handleYes={() => handleChangeActiveDb()}
          handleNo={() => setShowCancelJobModal(false)}
        />
      )}
      <OutcallModal callInfo={showOutCallModal} handleClose={() => setShowOutCallModal(false)} />
    </CallSectionWrapper>
  );
};

export default CallSection;

const CallSectionWrapper = styled.div`
  width: 100%;

  .call-section--section {
    padding: 15px;

    &.call-section--on {
      background-color: #ff8424;
    }

    &.call-section--off {
      background-color: #9fadb4;
    }

    &.call-section--focused {
      background-color: #00aa24;
    }

    &.call-section--calling {
      background-color: #00aa24;
    }

    .call-section--header,
    .call-section--body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .call-section--header {
      align-items: baseline;
      margin-bottom: 10px;
      p {
        font-size: 18px;
      }
      & > p {
        max-width: calc(100% - 200px);
      }
    }

    .call-section--body {
      height: 40px;
    }

    .call-section--description {
      text-align: center;
    }

    .call-section--status {
      font-family: var(--font-family);
      color: white;
      line-height: 38px;
      .MuiFormControlLabel-label {
        width: 30px;
        text-align: right;
      }
    }

    .MuiFormControlLabel-root {
      margin-right: 0;
    }

    .call-section--title {
      font-weight: 800;
      font-size: 18px;
    }

    .call-section--call-control {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      p {
        line-height: 38px;
        font-size: 18px;
        font-weight: 800;
        padding: 0 14px 0 0;
        margin: 0;
        color: #fff;
      }
    }

    .call-section--inactive {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      p {
        line-height: 38px;
        font-size: 18px;
        font-weight: 800;
        padding: 0 14px 0 0;
        margin: 0 10px 0 0;
        color: #fff;
      }
    }
  }
`;

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#fff',
    },
    '&$checked + $track': {
      backgroundColor: '#fff',
    },
  },
  checked: {},
  track: {},
})(Switch);
