/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  Button,
  MenuItem,
  MenuList,
  Divider,
  Drawer,
  Menu
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import { navigate, Link } from 'gatsby';
import { isLoggedIn, getLoginUser, logout } from 'services/loginService';
import { isCallcenterLoggedIn, getCallcenterUser, logoutCallcenter } from 'services/callcenterService';
import { PergoTitle } from '../PergoTitle';

import './PergoHeader.less';

const Header = ({ isCallCenter }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery('(max-width: 768px)');

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    if (isLoggedIn()) {
      logout();
      navigate('/login');
    }

    if (isCallcenterLoggedIn()) {
      logoutCallcenter();
      navigate('/callcenter/login');
    }
  };

  const menu = (
    <>
      {isMobile && <PergoTitle className="menu-header--title">Pergo</PergoTitle>}
      <MenuList id="menu-list-grow">
        {!getLoginUser()?.role?.startsWith('dispatcher') && 
        <>
          <a href='/manage' target="_blank">
            <MenuItem onClick={handleMenuClose}>
              <BuildOutlinedIcon fontSize="small" />Management Panel
            </MenuItem>
          </a>
          <Divider light />
          <Divider light />
        </>}
        <a href={`https://map.pergosolutionsnz.com?auth=${getLoginUser().token}`} target="_blank">
          <MenuItem onClick={handleMenuClose}>
            <ExploreOutlinedIcon fontSize="small" />Vehicle Map
          </MenuItem>
        </a>
        <Divider light />
        {false && isMobile && (
          <>
            <MenuItem onClick={handleMenuClose}>
              <SettingsOutlinedIcon fontSize="small" />Settings
            </MenuItem>
            <Divider light />
            <MenuItem onClick={handleMenuClose}>
              <ContactSupportOutlinedIcon fontSize="small" />Support
            </MenuItem>
            <Divider light />
          </>
        )}
        <a href='#'>
          <MenuItem onClick={handleLogout}>
            <ExitToAppOutlinedIcon fontSize="small" />Log Out
          </MenuItem>
        </a>
      </MenuList>
    </>
  );

  const menuCallCenter = (
    <>
      {isMobile && <PergoTitle className="menu-header--title">{isCallCenter ? 'Pergo Call Center' : 'Pergo'}</PergoTitle>}
      <MenuList id="menu-list-grow">
        <a href='#'>
          <MenuItem onClick={handleLogout}>
            <ExitToAppOutlinedIcon fontSize="small" />Log Out
          </MenuItem>
        </a>
      </MenuList>
    </>
  );

  return (
    <HeaderContainer>
      <div className="header-wrapper">
        <PergoTitle className="header--title">{isCallCenter ? (isCallcenterLoggedIn() ? `CALL CENTER / ${getCallcenterUser().center_name}` : 'CALL CENTER') : (isLoggedIn() ? `PERGO / ${getLoginUser().setting?.base_name}` : 'PERGO')}</PergoTitle>

        {isLoggedIn() && (
          <div className="header--button-container">
            {false && !isMobile && (
              <>
                <Button className="header--menu-button">
                  <SettingsOutlinedIcon />
                </Button>

                <Button className="header--menu-button">
                  <ContactSupportOutlinedIcon />
                </Button>
              </>
            )}

            <Button
              className="header--menu-button"
              onClick={handleMenuClick}
            >
              <MenuIcon />
            </Button>
            {!isMobile ? (
              <Menu
                anchorEl={anchorEl}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={!!anchorEl}
                classes={{ list: 'p-0' }}
                onClose={handleMenuClose}
              >
                {menu}
              </Menu>
            ) : (
              <Drawer anchor="right" open={anchorEl} onClose={handleMenuClose}>
                {menu}
              </Drawer>
            )}
          </div>
        )}

        {isCallcenterLoggedIn() && (
          <div className="header--button-container">
            <Button
              className="header--menu-button"
              onClick={handleMenuClick}
            >
              <MenuIcon />
            </Button>
            {!isMobile ? (
              <Menu
                anchorEl={anchorEl}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={!!anchorEl}
                classes={{ list: 'p-0' }}
                onClose={handleMenuClose}
              >
                {menuCallCenter}
              </Menu>
            ) : (
              <Drawer anchor="right" open={anchorEl} onClose={handleMenuClose}>
                {menuCallCenter}
              </Drawer>
            )}
          </div>
        )}
      </div>
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled.div`
  font-family: 'Roboto';

  .header-wrapper {
    background-color: var(--header-color);
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;

    .header--title {
      font-size: 30px;
      color: white;
    }

    .header--menu-button {
      color: white;
    }
  }

  @media (max-width: 768px) {
    .header-wrapper {
      padding: 15px;

      .header--button-container {
        .header--menu-button {
          padding: 6px 0;

          .MuiButton-label {
            justify-content: flex-end;
          }
        }
      }
    }
  }
`;
