/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import 'date-fns';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EyeIcon from '@material-ui/icons/RemoveRedEyeTwoTone';
import Button from '@material-ui/core/Button';

import { PergoTextField } from 'components/PergoTextField/PergoTextField';
import { CircularProgressContext } from 'components/CircularProgressOverlay';

import { JobContext } from 'context/jobContextContainer';
import { CallContext } from 'context/callContextContainer';
import { jobsService } from 'services/jobsService';

const moment = require('moment-timezone');

const JobTable = ({ data, editJob, confirmReservation, isCallCenter }) => {
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [assignDriverConfirm, setAssignDriverConfirm] = useState(false);
  const [searchValues, setSearchValues] = useState({
    fname: '',
    lname: '',
    phone: '',
  });
  const [showBDUnpaid, setShowBDUnpaid] = useState(false);
  const { activeDriverList, setNoShowDialogInfo } = useContext(JobContext);
  const { siteSetting } = useContext(CallContext);
  const { showCircularProgress, hideCircularProgress } = useContext(CircularProgressContext);

  const paymentType = ['Cash', 'Card', 'Voucher', 'EHail Mobile', 'Karhoo', 'Stored Card', 'Webapp Credit'];

  const jobTableRowFormat = row => {
    if (!siteSetting()) {
      return null;
    }

    const call_time = moment(row.call_time).isValid()
      ? moment
          .utc(row.call_time)
          ?.tz(siteSetting().timezone)
          ?.format('MM/DD/YYYY HH:mm')
      : '';
    const pick_up = row.pickup
      ? [row.pickup.location_name, row.pickup.addr1, row.pickup.city, row.pickup.state]
          .filter(str => str?.length > 0)
          .join(', ')
      : row.scheduled_start_addr || row.start_addr;
    const drop_off = row.dropoff
      ? [row.dropoff.location_name, row.dropoff.addr1, row.dropoff.city, row.dropoff.state]
          .filter(str => str?.length > 0)
          .join(', ')
      : row.scheduled_end_addr || row.end_addr;
    let { dispatch_time } = row;
    let status = '';
    let status_attr = '';
    row.status = parseInt(row.status);
    row.job_type = parseInt(row.job_type);

    switch (row.status) {
      case 1:
        status = row.job_type === 2 ? 'Reservation/Dispatched' : 'Dispatched';
        status_attr = 'dispatched';
        break;
      case 2:
        status = 'Accepted';
        status_attr = 'accepted';
        break;
      case 3:
        status = 'Declined';
        status_attr = 'declined';
        break;
      case 4:
        status = '@Pickup';
        status_attr = 'accepted';
        break;
      case 5:
        status = 'Picked Up';
        status_attr = 'pickup';
        break;
      case 6:
        status = 'Drop-Off';
        status_attr = 'dropoff';
        break;
      case 7:
        status = 'Pay';
        status_attr = 'pay';
        break;
      case 8:
        status = 'Complete';
        status_attr = 'complete';
        break;
      case 9:
        status = 'No Show';
        status_attr = 'cancel';
        break;
      case 10:
        status = 'Cancelled';
        status_attr = 'cancel';
        break;
      case 11:
        status = 'Backlog';
        status_attr = 'backlog';
        break;
      default:
        status = 'CFS';
        status_attr = 'cfs';
        break;
    }

    if (row.status === 0) {
      switch (row.job_type) {
        case 2:
          dispatch_time = row.reservation_time;
          status = 'Reservation';
          if (row.driver_id <= 0 && row.reservation_confirmed != 0 && row.reservation_confirmed != 3) {
            status_attr = 'no-driver';
            const diffTime = moment.utc(dispatch_time).diff(moment(), 'minutes');
            if (diffTime >= 20) {
              status_attr += '-green';
            } else if (diffTime >= 10) {
              status_attr += '-orange';
            } else {
              status_attr += '-red';
            }
          }
          break;
        case 3:
          status = 'E-Hail';
          break;
        case 4:
          status += ' (E-Hail)';
          break;
        default:
          break;
      }
    }

    dispatch_time = moment(dispatch_time).isValid()
      ? moment
          .utc(dispatch_time)
          ?.tz(siteSetting().timezone)
          ?.format(row.willcall_status == '1' ? 'MM/DD/YYYY' : 'MM/DD/YYYY HH:mm')
      : '';
    const distance = parseFloat(row.distance).toFixed(2);
    const payment_type = paymentType[row.tender_type];
    const pickup_time = moment(row.pickup_time).isValid()
      ? moment
          .utc(row.pickup_time)
          .tz(siteSetting().timezone)
          .format('HH:mm')
      : '';
    const dropoff_time = moment(row.dropoff_time).isValid()
      ? moment
          .utc(row.dropoff_time)
          .tz(siteSetting().timezone)
          .format('HH:mm')
      : '';

    return {
      ...row,
      call_time,
      pick_up,
      drop_off,
      dispatch_time,
      distance,
      payment_type,
      pickup_time,
      dropoff_time,
      status,
      status_attr,
      reservation_confirmed: row.reservation_confirmed,
    };
  };

  useEffect(() => {
    setTableData([...data]);
  }, [data]);

  const handleEditJob = job_id => {
    editJob(job_id);
  };

  const handleConfirmClick = (jobInfo, confirm) => {
    if (jobInfo) {
      confirmReservation([jobInfo], confirm);
    }
  };

  const onClickDriverAssign = (jobInfo, driverId, index) => {
    if (jobInfo.driver_id == driverId) {
      return;
    }

    if (parseFloat(jobInfo.booking_deposit_amount) > 0 && jobInfo.booking_deposit_status == 0) {
      setShowBDUnpaid(true);
      return;
    }

    const selDriver = activeDriverList.filter(item => item.id === driverId);
    if (selDriver?.length > 0) {
      setAssignDriverConfirm({
        jobId: jobInfo.id,
        driverId,
        driverLabel: selDriver[0].name,
        vehicleId: selDriver[0].vehicle_id,
      });
    }
  };

  const handleDriverAssign = async () => {
    setAssignDriverConfirm(false);
    const { jobId, driverId, vehicleId } = assignDriverConfirm;
    showCircularProgress('Loading');
    const res = await jobsService.dispatch_driver_to_job(jobId, driverId, vehicleId);
    if (res && res?.data?.status === 'SUCCESS') {
      // updateTable();
    }
    hideCircularProgress();
  };

  const handleNoShowClick = item => {
    if (item?.noshow_information) {
      const noShowInfo = JSON.parse(item.noshow_information);
      setNoShowDialogInfo({
        id: item.id,
        noShowInfo,
        pickupInfo: {
          addr: item.start_addr,
          lat: item.start_lat,
          lon: item.start_lon,
          time: item.reservation_time,
        },
      });
    }
  };

  const onSearchChange = (id, newValue) => {
    setSearchValues({
      ...searchValues,
      [id]: newValue,
    });
  };

  const closeBDUnpaid = () => {
    setShowBDUnpaid(false);
  };

  useEffect(() => {
    const filtered = tableData
      .filter(item => {
        const pname = item.passenger_name?.split(' ');
        if (searchValues?.fname?.length && !pname?.[0]?.toLowerCase().includes(searchValues.fname.toLowerCase())) {
          return false;
        }
        if (searchValues?.lname?.length && !pname?.[1]?.toLowerCase().includes(searchValues.lname.toLowerCase())) {
          return false;
        }
        if (
          searchValues?.phone?.length &&
          !item.passenger_phone?.toLowerCase().includes(searchValues.phone.toLowerCase())
        ) {
          return false;
        }
        return true;
      })
      .map(item => jobTableRowFormat(item))
      .filter(item => item !== null);
    setFilteredTableData(filtered);
  }, [tableData, searchValues?.fname, searchValues?.lname, searchValues?.phone]);

  const dispatchDriverList = (list, item) => {
    const tmpList = [...(list || [])];
    if (item.driver_id > 0 && !tmpList?.some(d => d.id == item.driver_id)) {
      tmpList.push({
        id: item.driver_id,
        name: item.driver_name,
      });
    }
    return tmpList;
  };

  return (
    <TableContainer>
      <TableSearch>
        <TableSearchGroup values={searchValues} onValueChange={onSearchChange} />
      </TableSearch>
      <Table aria-label="job table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Job #</TableCell>
            <TableCell align="center">Driver</TableCell>
            <TableCell align="center">Call Created Time</TableCell>
            <TableCell align="center">Passenger</TableCell>
            <TableCell align="center">Pickup</TableCell>
            <TableCell align="center">Drop Off</TableCell>
            <TableCell align="center">Dispatch Time</TableCell>
            <TableCell align="center">Car #</TableCell>
            <TableCell align="center">Distance</TableCell>
            <TableCell align="center">Payment Type</TableCell>
            <TableCell align="center">Fare</TableCell>
            <TableCell align="center">Pickup Time</TableCell>
            <TableCell align="center">Drop Off Time</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Cancel</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredTableData.map((item, index) => (
            <TableRow hover key={`${item.id}_${index}`} className={`job-status-${item.status_attr}`}>
              <TableCell align="center" style={{ cursor: 'pointer' }} onClick={() => handleEditJob(item.id)}>
                {item.id}
              </TableCell>
              <TableCell align="center">
                {item.willcall_status == '1' ? (
                  <></>
                ) : isCallCenter || item.status >= 10 ? (
                  item.driver_name
                ) : (
                  <Select
                    value={parseInt(item.driver_id) || -1}
                    onChange={e => onClickDriverAssign(item, e.target.value, index)}
                    style={{ width: '100%', fontSize: '13px', textAlign: 'left' }}
                  >
                    <MenuItem value="-1">&nbsp;</MenuItem>
                    {activeDriverList?.length > 0 &&
                      dispatchDriverList(activeDriverList, item).map((d, index1) => (
                        <MenuItem value={d.id} key={index1}>
                          {d.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              </TableCell>
              <TableCell align="center">{item.call_time}</TableCell>
              <TableCell align="center">{item.passenger_name}</TableCell>
              <TableCell align="center">{item.pick_up}</TableCell>
              <TableCell align="center">{item.drop_off}</TableCell>
              <TableCell align="center">
                {item.dispatch_time}
                {item.willcall_status == '1' ? <span style={{ color: 'red' }}>&nbsp;(Will Call)</span> : ''}
              </TableCell>
              <TableCell align="center">{item.vehicle_no}</TableCell>
              <TableCell align="center">{item.distance}</TableCell>
              <TableCell align="center">
                {item.payment_type}
                {parseFloat(item.booking_deposit_amount) > 0 && item.booking_deposit_status == 0 ? (
                  <>
                    <br />
                    <span style={{ color: 'red' }}>(Balance Due)</span>
                  </>
                ) : (
                  <></>
                )}
              </TableCell>
              <TableCell align="center">{item.fare}</TableCell>
              <TableCell align="center">{item.pickup_time}</TableCell>
              <TableCell align="center">{item.dropoff_time}</TableCell>
              {item.status === 'Reservation' && (item.reservation_confirmed == 0 || item.reservation_confirmed == 3) ? (
                <TableCell align="center" className="text-warning">
                  Confirmed
                  <br />
                  Reservation?
                </TableCell>
              ) : (
                <TableCell align="center">
                  {item.status}
                  {item.driver_id <= 0 && item.status < 2 ? (
                    <>
                      <br />
                      No Driver's Assigned
                    </>
                  ) : (
                    ''
                  )}
                  {item.status === 'No Show' && (
                    <EyeIcon
                      color="primary"
                      style={{ fontSize: 20, marginLeft: 5, marginBottom: 3, cursor: 'pointer' }}
                      onClick={() => handleNoShowClick(tableData[index])}
                    />
                  )}
                </TableCell>
              )}
              <TableCell align="center">
                {item.status === 'Reservation' && (item.reservation_confirmed == 0 || item.reservation_confirmed == 3) && (
                  <Button
                    className="job-table--action-icon color-success m-1"
                    variant="contained"
                    onClick={() => handleConfirmClick(item, 'confirm')}
                  >
                    <CheckIcon fontSize="small" />
                  </Button>
                )}

                <Button
                  className="job-table--action-icon m-1"
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    handleConfirmClick(
                      item,
                      item.status === 'Reservation' &&
                        (item.reservation_confirmed == 0 || item.reservation_confirmed == 3)
                        ? 'decline'
                        : 'cancel'
                    )
                  }
                >
                  <CloseIcon fontSize="small" />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Dialog open={!!assignDriverConfirm} onClose={() => setAssignDriverConfirm(false)}>
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Do you really want to assign ${assignDriverConfirm?.driverLabel} driver to Job #${assignDriverConfirm?.jobId}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDriverAssign} color="primary" autoFocus>
            Yes
          </Button>
          <Button onClick={() => setAssignDriverConfirm(false)} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showBDUnpaid} onClose={closeBDUnpaid}>
        <DialogTitle id="alert-dialog-title">Sorry</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Driver cannot be assigned since the booking deposit has not been paid.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeBDUnpaid} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
};

export default JobTable;

// eslint-disable-next-line arrow-body-style
const TableSearchGroup = ({ values, onValueChange }) => {
  return (
    <div className="reservation-table-search--container">
      <PergoTextField id="fname" values={values} handleChange={onValueChange} label="First Name" />
      <PergoTextField id="lname" values={values} handleChange={onValueChange} label="Last Name" />
      <PergoTextField id="phone" values={values} handleChange={onValueChange} label="Phone" />
    </div>
  );
};

const TableSearch = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .reservation-table-search--container {
    padding-top: 5px;

    .MuiTextField-root {
      margin-right: 10px;
    }

    .MuiInputLabel-outlined {
      font-size: 14px;
      transform: translate(14px, 12px) scale(1);
      background: transparent;
      padding: 0 3px;

      &.MuiInputLabel-shrink {
        transform: translate(14px, -5px) scale(0.75);
      }
    }

    .MuiOutlinedInput-input {
      padding: 10px;
      font-size: 14px;
      height: auto;
    }
  }
`;
