import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { SnackbarProvider } from 'notistack';
import styled from 'styled-components';

import Header from '../components/PergoHeader/PergoHeader';

const MainLayout = ({ children, hideHeader, isCallCenter }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <SnackbarProvider>
        <Helmet>
          <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
        </Helmet>
        <AppContainer>
          {!hideHeader && <Header isCallCenter={isCallCenter} />}
          <div className="body-container">
            <main>{children}</main>
          </div>
        </AppContainer>
      </SnackbarProvider>
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;

const AppContainer = styled.div``;
