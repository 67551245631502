/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { getLoginUser } from './loginService';
import { isCallcenterLoggedIn, getCallcenterUser } from './callcenterService';

const baseUrl = `${process.env.BACKEND_API_BASE_URL}/drivers.php`;

const getToken = () => (isCallcenterLoggedIn() ? getCallcenterUser() : getLoginUser()).token;

export const driversService = {
  get_drivers: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'get_drivers',
        token: getToken(),
        ...data, // { dbname }
      },
    }),
  get_drivers_by_method: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'get_drivers_by_method',
        token: getToken(),
        ...data, // { job_info, dbname }
      },
    }),
  get_drivers_table: async () =>
    axios.get(baseUrl, {
      params: {
        method: 'get_drivers_table',
        token: getLoginUser().token,
      },
    }),
  logoff_driver: async driver_id =>
    axios.get(baseUrl, {
      params: {
        method: 'logoff_driver',
        driver_id,
        token: getLoginUser().token,
      },
    }),
  admin_get_drivers_table: async (filter, order, page, page_size) =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_get_drivers_table',
        ...filter,
        order,
        page,
        page_size,
        token: getLoginUser().token,
      },
    }),
  admin_get_driver_detail: async id =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_get_driver_detail',
        id,
        token: getLoginUser().token,
      },
    }),
  admin_edit: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_edit',
        ...data,
        token: getLoginUser().token,
      },
    }),
  admin_del: async id =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_del',
        id,
        token: getLoginUser().token,
      },
    }),
  admin_export_drivers_table: async filter =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_export_drivers_table',
        ...filter,
        token: getLoginUser().token,
      },
      responseType: 'blob',
    }),
  admin_payout_save: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_payout_save',
        ...data,
        token: getLoginUser().token,
      },
    }),
  admin_get_driver_session_reports: async id =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_get_driver_session_reports',
        id,
        token: getLoginUser().token,
      },
    }),
  admin_get_driver_expense: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_get_driver_expense',
        ...data,
        token: getLoginUser().token,
      },
    }),
  admin_set_expense_status: async (data) =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_set_expense_status',
        ...data,
        token: getLoginUser().token,
      },
    }),
  admin_edit_expense: async formData => {
    formData.append('token', getLoginUser().token);
    formData.append('method', 'admin_edit_expense');
    return axios.post(baseUrl, formData);
  },
  admin_del_expense: async id =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_del_expense',
        id,
        token: getLoginUser().token,
      },
    }),
  admin_export_expense: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_export_expense',
        ...data,
        token: getLoginUser().token,
      },
    }),
  admin_download_driver_session_report: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_download_driver_session_report',
        ...data,
        token: getLoginUser().token,
      },
    }),
};
