/* eslint-disable arrow-body-style */
import React, { useState, useContext, useEffect } from 'react';
import {
  Dialog,
  IconButton,
} from '@material-ui/core';
import MyButton from 'components/Elements/MyButton';
import { PergoTextField } from 'components/PergoTextField/PergoTextField';
import MyCheckbox from 'components/Elements/MyCheckbox';
import MyRadioGroup, { MyRadio } from 'components/Elements/MyRadio';

import CloseIcon from '@material-ui/icons/Close';

import { JobContext } from 'context/jobContextContainer';
import { CallContext } from 'context/callContextContainer';
import { CircularProgressContext } from 'components/CircularProgressOverlay';

import { notNull } from 'utils/text';
import { jobsService } from 'services/jobsService';

import { DialogTitleContainer, CancelJobContentContainer, DialogActionsContainer } from './style';

const CancelJobModal = ({ isCallCenter }) => {
  const { cancelJobInfo, setCancelJobInfo } = useContext(JobContext);
  const [ cancelFeeInfo, setCancelFeeInfo ] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [formErrors, setFormErrors] = useState(null);
  const { showCircularProgress, hideCircularProgress } = useContext(CircularProgressContext);
  const { siteSetting, callcenterActiveDb } = useContext(CallContext);

  const checkCancelFee = async () => {
    if (cancelJobInfo.id.length > 1) {
      setCancelFeeInfo({
        enable_reservation_cancel: false,
        enable_job_cancel: false,
      });
      return;
    }

    showCircularProgress('Please wait');
    const jobId = cancelJobInfo.id[0];
    const fareParams = notNull(siteSetting().fare_params) ? JSON.parse(siteSetting().fare_params) : null;
    const data = await jobsService.get_job({
      job_id: jobId,
      ...(isCallCenter && { dbname: callcenterActiveDb }),
    });
    hideCircularProgress();
    if (data.data.status !== 'SUCCESS') {
      return;
    }
    const jobInfo = data.data.data.data;
    const payments = jobInfo.payments?.map(payment => ({ ...payment, data_capture: payment?.data_capture ? JSON.parse(payment.data_capture) : null }))?.filter(payment => payment?.data_capture?.charge_type === 'booking_deposit');
    if (payments?.length > 0) {
      setCancelFeeInfo({
        enable_cancel_fee: true,
        jobInfo,
        bookingDeposit: payments[0],
      });

      setFormValues({
        keep_booking_fee: (jobInfo.status == 0 && fareParams.use_booking_fee_as_cancellation) || (jobInfo.status > 0 && jobInfo.status >= fareParams.job_cancel_fee_status) ? '1' : '0',
      });
    } else if ((jobInfo.status == 0 && fareParams.enable_reservation_cancel) || (jobInfo.status >= fareParams.job_cancel_fee_status && fareParams.enable_job_cancel)) {
      setCancelFeeInfo({
        enable_cancel_fee: true,
        jobInfo,
        use_booking_fee_as_cancellation: fareParams.use_booking_fee_as_cancellation,
        upfront_charge_percent: fareParams.upfront_charge_percent,
        job_cancel_fee_status: fareParams.job_cancel_fee_status,
        job_cancel_fee_percentage: fareParams.job_cancel_fee_percentage,
      });

      const percent = jobInfo.status == 0 ? fareParams.upfront_charge_percent : fareParams.job_cancel_fee_percentage;

      setFormValues({
        keep_booking_fee: fareParams.use_booking_fee_as_cancellation ? '1' : '0',
        charge_cancellation_fee: 1,
        cancellation_fee: parseInt(parseInt(jobInfo.fare * 100) * (percent || 0) / 100),
      });
    } else {
      setCancelFeeInfo({
        enable_cancel_fee: false,
      });
    }
  }

  useEffect(() => {
    if (cancelJobInfo?.id) {
      checkCancelFee();
    }
  }, [cancelJobInfo?.id]);

  const handleEditClose = () => {
    setCancelJobInfo(null);
    setCancelFeeInfo(null);
  }

  const checkValueValidate = (id, value) => {
    let valid = notNull(value);
    let error = null;

    if (id === 'cancellation_fee') {
      valid = !formValues.charge_cancellation_fee || parseFloat(value) >= 0;
    }
    error = valid ? null : 'Please enter valid value';

    return error;
  };

  const checkValidate = () => {
    const keys = Object.keys(formValues);
    const errors = {};
    keys.forEach(id => {
      const error = checkValueValidate(id, notNull(formValues) ? formValues[id] : null);
      errors[id] = error;
    })
    setFormErrors(errors);

    const stepValid = Object.keys(errors).filter(item => errors[item] !== null).length;
    return stepValid === 0;
  };

  const handleActionYes = () => {
    if (formValues && !checkValidate()) {
      return true;
    }

    showCircularProgress('Processing');
    jobsService.cancel_job({
      job_ids: JSON.stringify(cancelJobInfo.id),
      reason: cancelJobInfo.reason,
      ...formValues,
      ...(isCallCenter && { dbname: callcenterActiveDb }),
    }).then(({ data }) => {
      hideCircularProgress();
      if (data.status === 'SUCCESS') {
        handleEditClose();
      }
    });
  }

  const handleActionNo = () => {
    handleEditClose();
  }

  const handleValueChange = (id, value) => {
    if (id === 'cancellation_fee') {
      setFormValues({
        ...formValues,
        [id]: parseFloat(value.slice(2).replace(/\D/g, '')),
      });
    } else {
      setFormValues({
        ...formValues,
        [id]: value,
      });
    }
  };

  if (!cancelJobInfo || !cancelFeeInfo) {
    return <></>
  }

  return (
    <Dialog
      classes={{ paper: 'modal-content' }}
      fullWidth
      maxWidth="sm"
      open={!!cancelJobInfo}
      onClose={handleEditClose}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitleContainer className="bg-secondary">
        <span>{`${cancelFeeInfo?.jobInfo?.status == 0 ? 'Reservatoin' : 'Job'} Cancellation${cancelFeeInfo?.jobInfo?.id ? ` : #${cancelFeeInfo?.jobInfo?.id}` : ''}`}</span>
        <IconButton
          aria-label="close"
          onClick={handleEditClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitleContainer>
      <CancelJobContentContainer className="p-0" dividers>
        <div className="dialog-content--wrapper bg-secondary border-0">
          <p>{`Do you really want to cancel ${cancelFeeInfo?.jobInfo?.status == 0 ? 'reservatoin' : 'job'}?`}</p>
          {cancelFeeInfo?.enable_cancel_fee && (
            <div className="dialog-content--information-section">
              {cancelFeeInfo?.bookingDeposit ? (
                <div>
                  <p>The passenger paid <b>{`$${cancelFeeInfo?.bookingDeposit?.amount}`}</b> for booking deposit. Do you want to keep it for cancellation fee?</p>
                  <MyRadioGroup
                    id="keep_booking_fee"
                    values={formValues}
                    onChange={handleValueChange}
                    column
                  >
                    <MyRadio label="Yes, I want to keep it." value="1" />
                    <MyRadio label="No, I want to refund it." value="0" />
                </MyRadioGroup>
                </div>
              ) : cancelFeeInfo?.jobInfo?.passenger?.token ? (
                <div>
                  <MyCheckbox
                    label="Do you want to charge cancellation fee?"
                    id="charge_cancellation_fee"
                    values={formValues}
                    onChange={handleValueChange}
                  />
                  <PergoTextField
                    label="Cancellation Fee"
                    id="cancellation_fee"
                    type="currency"
                    values={formValues}
                    formValid={{ cancellation_fee: formErrors?.cancellation_fee ? -1 : 0 }}
                    formErrors={formErrors}
                    handleChange={handleValueChange}
                    disabled={!formValues?.charge_cancellation_fee}
                  />
                </div>
              ) : (
                <div>
                  <p style={{ color: '#999', fontSize: 14 }}>This passenger doesn't have card on file. So you can't charge cancellation fee.</p>
                </div>
              )}
            </div>
          )}
        </div>
      </CancelJobContentContainer>
      <DialogActionsContainer>
        <div />
        <MyButton onClick={handleActionYes} color="primary">
          Yes
        </MyButton>
        <MyButton onClick={handleActionNo} color="secondary" autoFocus>
          No
        </MyButton>
      </DialogActionsContainer>
    </Dialog>
  );
};

export default CancelJobModal;
